import { SubmitHandler, useForm, useWatch, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PlusIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

import { DATE_FORMAT } from "@constants/date-formats";
import { GENERIC_NAME } from "@constants/validations";
import { SpecialAvailabilityDay } from "@features/salon/models/specialHours";
import { timerangeIsValid } from "@utils/datetime";
import { format, isSameDay } from "date-fns";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { DateField } from "@components/ui/DateField";
import { Switch } from "@components/ui/Switch";
import { TextField } from "@components/ui/TextField";
import { TimeField } from "@components/ui/TimeField";

import { useToasts } from "@providers/ToastsProvider";

import { useSalonOpeningHoursStore } from "@features/salon/hooks";

export type SpecialAvailabilityProps = {
  entity?: SpecialAvailabilityDay;
};

type SpecialAvailabilityFormProps = SpecialAvailabilityProps & {
  onClose: () => void;
};

type SpecialAvailabilityFormDialogProps = {
  open: boolean;
  props?: SpecialAvailabilityProps;
  onClose: () => void;
};

export const SpecialAvailabilityFormDialog = ({
  props,
  open,
  onClose,
}: SpecialAvailabilityFormDialogProps) => {
  const { t } = useTranslation();

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("openingHours.setIrregular")}>
      <SpecialAvailabilityForm entity={props?.entity} onClose={onClose} />
    </DefaultDialog>
  );
};

const SpecialAvailabilityForm = ({ entity, onClose }: SpecialAvailabilityFormProps) => {
  const { special, loading, addSpecial, updateSpecial } = useSalonOpeningHoursStore();
  const { showToast } = useToasts();

  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<SpecialAvailabilityDay>({
    defaultValues: entity || {
      is_open: false,
      ranges: [{ from: "", to: "" }],
    },
    mode: "all",
  });

  const isOpen = useWatch({ name: "is_open", control });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "ranges",
  });

  const handleFieldAppend = () => {
    append({ from: "", to: "" });
  };

  const handleFieldRemove = (index: number) => {
    remove(index);
  };

  const onSubmit: SubmitHandler<SpecialAvailabilityDay> = async (data) => {
    if (
      special.filter(({ date }) => isSameDay(date, data.date)).length >
      (entity && isSameDay(entity.date, data.date) ? 1 : 0)
    ) {
      return showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("openingHours.existingIrregularDateError", {
          date: format(data.date, DATE_FORMAT.DAY_OF_WEEK_MONTH_YEAR),
        }),
      });
    }
    const { errors } = entity ? await updateSpecial(data) : await addSpecial(data);

    if (!errors) {
      onClose();
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        {...register("name", GENERIC_NAME)}
        label={t("openingHours.event.name")}
        placeholder={t("openingHours.event.placeholder")}
        showLabel
        errorMessage={
          errors.name &&
          t(`validation.${errors.name.type}`, {
            name: t("openingHours.event.name"),
            ...GENERIC_NAME,
          })
        }
      />
      <div>
        <DateField
          control={control}
          name="date"
          required
          label={t("openingHours.event.date")}
          portalId="date"
        />
      </div>
      <div className="flex gap-2">
        <div className="w-1/4">
          <p className="text-xs text-gray-500">{t("openingHours.open")}</p>
          <div className="-ml-2 mt-2">
            <Switch control={control} name="is_open" showLabel />
          </div>
        </div>
        <div>
          <p className="text-xs text-gray-500">{t("openingHours.event.openFromTo")}</p>
          {isOpen ? (
            <div>
              {fields.map((field, index) => (
                <div key={field.id} className="mt-1 flex w-full items-center gap-2">
                  <TimeField
                    name={`ranges.${index}.from`}
                    control={control}
                    rules={{
                      required: isOpen,
                      deps: [`ranges.${index}.to`],
                      validate: (value) =>
                        !isOpen || timerangeIsValid(value, getValues(`ranges.${index}.to`)),
                    }}
                    disabled={!isOpen}
                    placeholder="08:00"
                    isError={!!(errors.ranges || [])[index]?.from}
                    className="!py-1"
                  />
                  <span className="text-gray-400">{" - "}</span>
                  <TimeField
                    name={`ranges.${index}.to`}
                    control={control}
                    rules={{
                      required: isOpen,
                      deps: [`ranges.${index}.from`],
                      validate: (value) =>
                        !isOpen || timerangeIsValid(getValues(`ranges.${index}.from`), value),
                    }}
                    disabled={!isOpen}
                    placeholder="16:00"
                    isError={!!(errors.ranges || [])[index]?.to}
                    className="!py-1"
                  />
                  <div className="flex h-6 w-6 gap-2">
                    {isOpen && fields.length !== 1 && (
                      <Button
                        variant="neutral"
                        className="flex h-6 w-6 items-center justify-center text-red-500"
                        onClick={() => handleFieldRemove(index)}>
                        <TrashIcon className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
              <div className="mt-2 flex">
                <Button
                  variant="primary-inline"
                  startIcon={<PlusIcon />}
                  onClick={() => handleFieldAppend()}
                  className="text-sm">
                  {t("openingHours.add")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="whitespace-nowrap py-1 text-sm text-gray-500 sm:py-3">
              {t("openingHours.event.isOpen.closed")}
            </div>
          )}
        </div>
      </div>

      <div>
        <Button
          startIcon={<PlusCircleIcon />}
          fullWidth
          type="submit"
          disabled={loading || !isValid || !isDirty}>
          {entity ? (
            <span>{t("openingHours.updateIrregular")}</span>
          ) : (
            <span>{t("openingHours.addIrregular")}</span>
          )}
        </Button>
      </div>
    </form>
  );
};

import { useTranslation } from "react-i18next";

import { Subheader } from "@components/layout/subheader/Subheader";
import { Button } from "@components/ui/Button";

import { useToasts } from "@providers/ToastsProvider";

import { usePaymentsStore } from "@features/payments/hooks";
import { useSessionStore } from "@features/session/hooks";

export const SubscriptionsSubheader = () => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { session } = useSessionStore();
  const { loading, createBillingPortalSession } = usePaymentsStore();

  const handleManageClick = async () => {
    if (!session?.stripeCustomerId) return;

    const { data, errors } = await createBillingPortalSession(session.stripeCustomerId);

    if (errors) {
      showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });
    } else if (data?.createBillingPortalSession?.url) {
      window.location.href = data.createBillingPortalSession.url;
    }
  };

  return (
    <div className="-ml-4 -mr-4 mb-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mb-8 sm:mt-4 sm:px-12 md:mx-auto">
      <Subheader
        title={t("subscriptions.title")}
        leftSlot={
          session?.stripeCustomerId ? (
            <Button
              size="small"
              disabled={loading.loadingBillingPortalSession}
              onClick={handleManageClick}>
              {t("subscriptions.manage")}
            </Button>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ArrowsRightLeftIcon,
  BanknotesIcon,
  CreditCardIcon,
  GiftTopIcon,
} from "@heroicons/react/24/outline";

import { useSalonPaymentMethodsStore } from "@/features/salon/hooks/use-salon-payment-methods-store";
import { BlikIcon } from "@/icons/BlikIcon";
import { SalonPaymentMethods } from "@/types";
import { cn } from "@/utils/utils";

type PaymentMethodProps = {
  paymentMethodUuid: string;
  showLabel?: boolean;
  className?: string;
};

type SalonPaymentIcons = {
  name: SalonPaymentMethods;
  icon: (className?: string) => React.ReactNode;
};

const SALON_PAYMENT_METHODS: SalonPaymentIcons[] = [
  {
    name: SalonPaymentMethods.Cash,
    icon: (className?: string) => (
      <BanknotesIcon className={cn("mr-2 h-4 w-4 shrink-0", className)} />
    ),
  },
  {
    name: SalonPaymentMethods.CreditCard,
    icon: (className?: string) => (
      <CreditCardIcon className={cn("mr-2 h-4 w-4 shrink-0", className)} />
    ),
  },
  {
    name: SalonPaymentMethods.BankTransfer,
    icon: (className?: string) => (
      <ArrowsRightLeftIcon className={cn("mr-2 h-4 w-4 shrink-0", className)} />
    ),
  },
  {
    name: SalonPaymentMethods.Blik,
    icon: (className?: string) => <BlikIcon className={cn("mr-2 h-4 shrink-0", className)} />,
  },
  {
    name: SalonPaymentMethods.Voucher,
    icon: (className?: string) => (
      <GiftTopIcon className={cn("mr-2 h-4 w-4 shrink-0", className)} />
    ),
  },
];

export const PaymentMethodsMapped = () => {
  const { t } = useTranslation();
  const { salonPaymentMethods } = useSalonPaymentMethodsStore();

  return useMemo(() => {
    return salonPaymentMethods.map((paymentMethod) => {
      return {
        value: paymentMethod.uuid,
        label: t(`paymentMethods.${paymentMethod.name}`),
        props: {
          icon: SALON_PAYMENT_METHODS.find((item) => item.name === paymentMethod.name)?.icon,
        },
      };
    });
  }, [salonPaymentMethods, t]);
};

export const PaymentMethod = ({
  paymentMethodUuid,
  showLabel = true,
  className = "",
}: PaymentMethodProps) => {
  const paymentMethodsMapped = PaymentMethodsMapped();

  const selectedPaymentMethod = paymentMethodsMapped.find(
    (method) => method.value === paymentMethodUuid,
  );

  return (
    <div className="flex items-center truncate">
      {selectedPaymentMethod?.props.icon && selectedPaymentMethod?.props.icon(className)}
      {showLabel && <span className="text-sm">{selectedPaymentMethod?.label}</span>}
    </div>
  );
};

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { SalonProfileForm } from "@features/salon/components/SalonProfileForm";

import { Subheader } from "@/components/layout/subheader/Subheader";
import { Spinner } from "@/components/ui/Spinner";
import { useSalonStore } from "@/features/salon/hooks";
import { PhoneType } from "@/types";

export const SalonProfileEditView = () => {
  const { t } = useTranslation();
  const { salon, loading } = useSalonStore();

  const defaultValues = useMemo(
    () =>
      salon && {
        ...salon,
        phoneType: salon.phoneType || PhoneType.Mobile,
      },
    [salon],
  );

  return (
    <Layout title={t("salonProfile.edit")} className="bg-gray-100">
      <div className="-ml-4 -mr-4 mb-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mb-8 sm:mt-4 sm:px-12 md:mx-auto">
        <Subheader title={t("salonProfile.edit")} />
      </div>
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto">
        {!loading && defaultValues ? (
          <SalonProfileForm defaultValues={defaultValues} />
        ) : (
          <Spinner />
        )}
      </div>
    </Layout>
  );
};

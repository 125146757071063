import { ReactElement, JSXElementConstructor, cloneElement, ReactNode } from "react";

import clsx from "clsx";

import { Colors, BADGE_COLORS } from "@/constants/colors";

type Sizes = "sm" | "md";

type BadgeProps = {
  children: ReactNode;
  color?: Colors | string;
  startIcon?: ReactElement<HTMLElement, string | JSXElementConstructor<HTMLElement>>;
  size?: Sizes;
  uppercase?: boolean;
  bordered?: boolean;
  className?: string;
};

const SIZE_CLASSNAMES: Record<Sizes, string> = {
  sm: "px-2.5 py-0.5 text-xs",
  md: "px-3 py-0.5 text-sm",
};

export const Badge = ({
  children,
  color,
  startIcon: startIcon,
  size = "sm",
  uppercase = false,
  bordered = false,
  className,
}: BadgeProps) => {
  return (
    <div
      className={clsx(
        color ? BADGE_COLORS[color].background : "bg-gray-50",
        color ? BADGE_COLORS[color].text : "text-gray-700",
        color ? BADGE_COLORS[color].border : "border-gray-200",
        "inline-flex items-center rounded-full font-medium",
        SIZE_CLASSNAMES[size],
        className,
        uppercase && "uppercase",
        bordered && "border",
      )}>
      <span className="flex items-center gap-1">
        {startIcon &&
          cloneElement(startIcon, {
            className: clsx(color ? BADGE_COLORS[color].text : "text-gray-900", "h-3 w-3"),
          })}
        {children}
      </span>
    </div>
  );
};

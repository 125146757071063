import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PlusIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

import { useSessionStore, useCountersStore } from "@features/session/hooks";

import { Button } from "@/components/ui/Button";
import { Popover } from "@/components/ui/Popover";
import { sendEvent } from "@/utils/google-analytics";

import { useCalendarContext } from "../providers/CalendarProvider";

export const CalendarFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isFreePlan, permissions } = useSessionStore();
  const { remainingAppointments, loadingRemainingAppointments } = useCountersStore();
  const { addNewAppointment } = useCalendarContext();

  const isAbleToAddAppointment = useMemo(
    () => !isFreePlan || remainingAppointments > 0,
    [isFreePlan, remainingAppointments],
  );

  return (
    <div className="fixed bottom-3 right-3 rounded-full p-1 drop-shadow-[0_-4px_4px_rgb(0,0,0,0.07)]">
      <Popover
        disabled={
          loadingRemainingAppointments || isAbleToAddAppointment || permissions.add_appointment
        }
        render={({ close }) => (
          <div className="max-w-sm">
            <h5>{t("appointments.reachedLimit")}</h5>
            <Button
              variant="neutral"
              fullWidth
              className="mt-4 inline-flex w-full items-center justify-center rounded-sm border border-transparent bg-green-500 px-3 py-2 text-sm uppercase leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              endIcon={<ArrowRightIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />}
              onClick={() => {
                sendEvent("upgrade_cta", "payments", "calendar_footer");
                close();
                navigate("/subscriptions");
              }}>
              {t("header.salonMenu.removeLimits")}
            </Button>
          </div>
        )}>
        <Button
          className="rounded-xl p-2.5"
          fullWidth
          disabled={
            loadingRemainingAppointments ||
            (!permissions.add_appointment && !permissions.add_slot_block)
          }
          onClick={isAbleToAddAppointment ? () => addNewAppointment() : undefined}>
          <PlusIcon className="h-6 w-6" />
        </Button>
      </Popover>
    </div>
  );
};

import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ConfirmationDialog } from "@components/dialogs/ConfirmationDialog";

import { useConfirmationDialog } from "@hooks/use-confirmation-dialog";
import { useDialog, bindDialogState } from "@hooks/use-dialog";

import { useHeadCategoriesStore, useHeadCategoryStore } from "@features/services/hooks";

import { HeadCategory } from "@/types";

import { ServiceEntitiesHeader } from "../ServiceEntitiesHeader";
import { HeadCategoryFormDialog, HeadCategoryFormProps } from "./HeadCategoryFormDialog";
import { HeadCategoryListWrapper } from "./HeadCategoryListWrapper";

export const HeadCategoryList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteHeadCategory } = useHeadCategoryStore();
  const { updateHeadCategoriesPositions, headCategories } = useHeadCategoriesStore();
  const dialogState = useDialog<HeadCategoryFormProps>();
  const confirmationDialogState = useConfirmationDialog();

  const [isDraggable, setIsDraggable] = useState(false);
  const [tempHeadCategories, setTempHeadCategories] = useState<HeadCategory[]>([]);

  const handleHeadCategoryAdd = () => dialogState.open();

  const handleHeadCategoryEdit = ({ uuid }: HeadCategory) =>
    dialogState.open({ headCategoryUuid: uuid, onRemove: handleHeadCategoryRemove });

  const handleHeadCategoryRemove: (headCategory: HeadCategory) => Promise<boolean> = ({
    uuid,
    name,
  }: HeadCategory) =>
    new Promise((resolve) =>
      confirmationDialogState.open({
        title: t("services.headCategories.delete"),
        message: t("services.headCategories.deleteWarning", { name }),
        onConfirm: async () => {
          await deleteHeadCategory({ categoryUuid: uuid });
          navigate("/services");
          resolve(true);
        },
        onDeny: () => resolve(false),
      }),
    );

  const handleHeadCategorySelect = ({ uuid }: HeadCategory) => {
    navigate(`/services/${uuid}`);
  };

  const handleSaveOrder = useCallback(() => {
    if (tempHeadCategories.length > 0) {
      const data = {
        sortedHeadCategoryUuids: tempHeadCategories.map((headCategory) => headCategory.uuid),
      };

      updateHeadCategoriesPositions({ data: data });
    }

    setIsDraggable(false);
  }, [tempHeadCategories, updateHeadCategoriesPositions]);

  const handleOrder = useCallback(() => {
    setIsDraggable((prev) => !prev);

    if (isDraggable) {
      setTempHeadCategories(headCategories as HeadCategory[]);
    }

    if (!isDraggable) {
      setTempHeadCategories([]);
    }
  }, []);

  return (
    <div className="overflow-auto">
      <ServiceEntitiesHeader
        title={t("services.headCategories.title")}
        handleAdd={handleHeadCategoryAdd}
        handleOrder={handleOrder}
        isDraggable={isDraggable}
        handleSaveOrder={handleSaveOrder}
        isDnDVisible={headCategories.length > 0}
      />
      <HeadCategoryListWrapper
        isDraggable={isDraggable}
        noResultText={t("services.treatments.noResults")}
        renderHeadCategory={(headCategory: HeadCategory) => <span>{headCategory.name}</span>}
        onEdit={handleHeadCategoryEdit}
        onRemove={handleHeadCategoryRemove}
        onSelect={handleHeadCategorySelect}
        setTempHeadCategories={setTempHeadCategories}
        headCategories={
          isDraggable
            ? (tempHeadCategories.length > 0 && tempHeadCategories) || headCategories
            : headCategories
        }
      />
      <HeadCategoryFormDialog {...bindDialogState(dialogState)} />
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </div>
  );
};

import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import { useSalonStore } from "@features/salon/hooks";

import { SalonProfileFormProps } from "@/features/salon/components/SalonProfileForm";
import { UpdateSalonInputDeserialized } from "@/features/salon/models/salonProfile";
import { uploadImageToS3, removeImageFromS3, createAwsS3Url } from "@/utils/s3-utils";

type Input = Omit<UpdateSalonInputDeserialized, "logoImageUrl" | "backgroundImageUrl"> & {
  logoImage: FileList;
  backgroundImage: FileList;
};

export const useSalonProfileForm = ({ defaultValues }: SalonProfileFormProps) => {
  const navigate = useNavigate();
  const { salon, updateSalon } = useSalonStore();

  const form = useForm<Input>({
    mode: "all",
    defaultValues,
    shouldUnregister: true,
  });

  const isPublic = useWatch({ name: "isPublic", control: form.control });

  const onSubmit: SubmitHandler<Input> = async (formData) => {
    const images = [
      {
        name: "logoImageUrl",
        file: formData.logoImage[0],
        filename: uuidv4(),
        defaultUrl: salon?.logoImageUrl,
      },
      {
        name: "backgroundImageUrl",
        file: formData.backgroundImage[0],
        filename: uuidv4(),
        defaultUrl: salon?.backgroundImageUrl,
      },
    ];

    for (const file of images) {
      if (file.file && typeof file.file !== "string") {
        await uploadImageToS3(file.file, file.filename);

        if (file.defaultUrl) {
          const oldFilename = file.defaultUrl.split("/").at(-1);

          if (oldFilename) {
            await removeImageFromS3(oldFilename);
          }
        }
      }
    }

    const urls = images.reduce(
      (prev, image) => ({
        ...prev,
        [image.name]: image.file ? createAwsS3Url(image.filename) : image.defaultUrl,
      }),
      {},
    );

    const selfBookingSlotDuration = formData.selfBookingSlotDuration
      ? Number(formData.selfBookingSlotDuration)
      : 30;

    const input = {
      ...formData,
      ...urls,
      selfBookingSlotDuration: selfBookingSlotDuration,
      logoImage: undefined,
      backgroundImage: undefined,
    };

    const { errors } = await updateSalon(input);

    if (!errors) {
      navigate("/salon");
    }
  };

  return {
    onSubmit,
    isPublic,
    form,
  };
};

import React, { useCallback, useLayoutEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import clsx from "clsx";

import { Header } from "@components/layout/header/Header";
import { Meta, MetaProps } from "@components/Meta";

import { ErrorFallback } from "@/components/layout/ErrorFallback";

import { Sidebar } from "./sidebar/Sidebar";

type LayoutProps = MetaProps & {
  children: React.ReactNode;
  title?: string;
  className?: string;
  restrainHeight?: boolean;
};

export const Layout = ({ children, title, className, restrainHeight }: LayoutProps) => {
  const savedSidebarIsShrinked = localStorage.getItem("sidebarIsShrinked");
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
  const [sidebarIsShrinked, setSidebarIsShrinked] = useState<boolean>(
    savedSidebarIsShrinked === "true",
  );

  const headerRef = useCallback((element: HTMLDivElement | null) => {
    setHeaderElement(element);
  }, []);

  useLayoutEffect(() => {
    const headerObserver = new ResizeObserver((entries) => {
      entries[0] &&
        document.body.style.setProperty("--main-offset", `${headerElement?.offsetHeight || 0}px`);
    });

    if (headerElement) headerObserver.observe(headerElement);

    return () => {
      if (headerElement) headerObserver.unobserve(headerElement);
    };
  }, [headerElement]);

  const handleSidebarShrink = useCallback(
    (isShrinked: boolean) => {
      setSidebarIsShrinked(isShrinked);
      localStorage.setItem("sidebarIsShrinked", JSON.stringify(isShrinked));
    },
    [setSidebarIsShrinked],
  );

  return (
    <div className={className}>
      <Meta title={title} />
      <Sidebar isShrinked={sidebarIsShrinked} isOpen={sidebarIsOpen} setIsOpen={setSidebarIsOpen} />
      <div
        className={clsx(
          sidebarIsShrinked ? "md:pl-16" : "md:pl-48 xl:pl-56",
          "w-full transition-all duration-300 ease-in-out",
        )}>
        <Header
          ref={headerRef}
          toggleSidebar={setSidebarIsOpen}
          setIsShrinked={handleSidebarShrink}
          isShrinked={sidebarIsShrinked}
        />
        <Sentry.ErrorBoundary
          fallback={<ErrorFallback />}
          beforeCapture={(scope) => {
            scope.setTag("location", "layout");
          }}>
          <main
            className={clsx(
              "relative min-h-[calc(var(--screenH)_-_var(--main-offset))] max-w-full flex-1 overflow-hidden px-2 py-4 xs:px-4",
              {
                "h-[calc(var(--screenH)_-_var(--main-offset))]": restrainHeight,
              },
            )}>
            {children}
          </main>
        </Sentry.ErrorBoundary>
      </div>
    </div>
  );
};

/* eslint-disable import/no-duplicates */

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CalendarViews, slotsAssignedToDaysByEmployee } from "@features/calendar/models";
import clsx from "clsx";

import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import { useSalonOpeningHoursStore } from "@/features/salon/hooks";
import { generateWeekWithDaysOfYear } from "@/features/salon/utils";

import type { UnknownCalendarSlot } from "../../models/index";
import { CalendarWeekDaysHeader } from "./CalendarWeekDaysHeader";
import { CalendarWeekEmployeesColumn } from "./CalendarWeekEmployeesColumn";
import { CalendarWeekSlot } from "./CalendarWeekSlot";

type CalendarDayContentProps = {
  slotGroupsMap: slotsAssignedToDaysByEmployee[];
};

export const CalendarWeekContent = ({ slotGroupsMap }: CalendarDayContentProps) => {
  const { t } = useTranslation();
  const { regular, special } = useSalonOpeningHoursStore();
  const { selectedDate, setFilters, goToDay } = useCalendarContext();

  const employees = useMemo(() => slotGroupsMap.map((group) => group.employee), [slotGroupsMap]);
  const WEEK_DAYS = generateWeekWithDaysOfYear({ regular, special, selectedDate });

  const handleDaySelect = (firstSlot: UnknownCalendarSlot, employeeUuid: string) => {
    setFilters({ employeeUuid: employeeUuid });
    goToDay(new Date(firstSlot.timeRange.from), CalendarViews.EmployeeDay);
  };

  return (
    <div className="h-full pb-20 sm:pb-0 lg:flex lg:flex-col">
      <div className="max-h-full overflow-auto overscroll-none rounded-md border border-gray-200 lg:flex lg:flex-auto lg:flex-col">
        <div className="flex flex-auto">
          <CalendarWeekEmployeesColumn employees={employees} />
          <div className="flex max-h-full flex-auto flex-col">
            <CalendarWeekDaysHeader />
            <div className="-mt-10 flex h-full flex-auto flex-col pt-10">
              {slotGroupsMap.map((group) => (
                <div
                  key={group.employee.accountUuid}
                  className="grid h-full min-h-[320px] w-full gap-px border-b border-b-gray-200 bg-gray-200 last:border-none"
                  style={{ gridTemplateColumns: "repeat(7, minmax(220px, 1fr))" }}>
                  {WEEK_DAYS.map(({ isOpen, dayOfYear }) => {
                    const dayBackground = isOpen ? "bg-white" : "bg-gray-100";
                    const gradientStart = isOpen ? "from-white" : "from-gray-100";
                    const slots = group.slotsAssignedToDays[dayOfYear];

                    return (
                      <div
                        key={dayOfYear}
                        className={clsx(
                          dayBackground,
                          "relative min-w-[220px] overflow-hidden px-1",
                        )}>
                        {slots && (
                          <div>
                            <div
                              className={clsx(
                                gradientStart,
                                "absolute bottom-0 left-0 right-0 z-10 flex h-16 items-center justify-center bg-gradient-to-t from-50% to-transparent to-90%",
                              )}>
                              <button
                                type="button"
                                className="pt-6 text-xs text-gray-500 transition-colors duration-300 hover:text-gray-700"
                                onClick={() =>
                                  handleDaySelect(slots[0], group.employee.accountUuid)
                                }>
                                {t("appointments.seeAll") + ` (${slots.length})`}
                              </button>
                            </div>
                            <div>
                              {slots.map((slot) => (
                                <ol className="mt-1 space-y-1" key={slot.uuid}>
                                  <CalendarWeekSlot key={slot.uuid} slot={slot} />
                                </ol>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useTranslation } from "react-i18next";

import { Button } from "@components/ui/Button";

import { AppointmentsLimitBar } from "@features/calendar/components/AppointmentsLimitBar";

import { useSessionStore } from "@features/session/hooks";

export const PricingCTA = () => {
  const { t } = useTranslation();
  const { salonPlan, isFreePlan } = useSessionStore();

  return (
    <div className="w-full bg-white text-center">
      <div className="border-y px-2 pt-2 md:my-4">
        <div className="px-2 text-sm font-medium uppercase text-gray-500">
          {t(`header.salonMenu.${salonPlan?.toLowerCase()}Plan`)}
        </div>

        <div className="py-1 md:py-4">
          {isFreePlan ? (
            <AppointmentsLimitBar />
          ) : (
            <Button variant="primary-inline" to="/subscriptions">
              {t("generic.change")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

import { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Tab } from "@headlessui/react";

import { Layout } from "@components/layout/Layout";
import { Meta } from "@components/Meta";

import { AccountEdit } from "@features/account/components/AccountEdit";
import { LanguageSwitcher } from "@features/settings/components/LanguageSwitcher";

import { useSessionStore } from "@features/session/hooks";

import { FormSection } from "@/components/layout/FormSection";
import { Subheader } from "@/components/layout/subheader/Subheader";
import { ConsentSettings } from "@/features/settings/components/ConsentSettings";
import { SaleSettings } from "@/features/settings/components/SaleSettings";
import { SmsSettings } from "@/features/settings/components/SmsSettings";
import { Role } from "@/types";

const defaultTabs = ["general", "account"];
const permissionsTabs = ["sms"];
const ownerTabs = ["sale"];

export const SettingsView = () => {
  const { t } = useTranslation();
  const { role, permissions } = useSessionStore();
  const [tabs, setTabs] = useState(defaultTabs);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
    setSearchParams({ tab: tabs[index] });
  };

  useEffect(() => {
    const permittedTabs = [
      ...defaultTabs,
      ...(permissions.manage_sms ? permissionsTabs : []),
      ...(role === Role.Owner ? ownerTabs : []),
    ];
    setTabs(permittedTabs);

    const defaultTab = searchParams.get("tab");
    if (defaultTab && permittedTabs.includes(defaultTab)) {
      setSelectedIndex(permittedTabs.indexOf(defaultTab));
    }
  }, [role, searchParams]);

  return (
    <Layout title={t("settings.title")} className="bg-gray-100">
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <div className="mb-2 sm:mb-8">
          <Subheader title={t("settings.title")} />
        </div>

        <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
          <Tab.List className="box-border flex flex-wrap justify-start space-x-4 border-b border-gray-200 pt-4 sm:space-x-8 md:justify-start md:pt-0">
            {tabs.map((tab) => (
              <Tab as={Fragment} key={tab}>
                <div className="w-fit cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium outline-none transition-colors focus-visible:outline-none ui-selected:border-violet-500 ui-selected:text-gray-900 ui-not-selected:border-transparent ui-not-selected:text-gray-500 ui-not-selected:hover:border-gray-300 ui-not-selected:hover:text-gray-700">
                  <span className="text-xs sm:text-sm">{t(`settings.tabs.${tab}`)}</span>
                </div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="@container">
            <Tab.Panel>
              <Meta title={`${t("settings.title")} - ${t("settings.tabs.general")}`} />
              <div className="grid gap-2 divide-y-[1px]">
                <FormSection
                  headingText={t("settings.language")}
                  descriptionText={t("settings.languageDescription")}>
                  <LanguageSwitcher />
                </FormSection>

                <FormSection
                  headingText={t("cookies.cookies")}
                  descriptionText={t("cookies.description")}>
                  <ConsentSettings />
                </FormSection>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <Meta title={`${t("settings.title")} - ${t("settings.tabs.account")}`} />
              <AccountEdit />
            </Tab.Panel>
            {permissions.manage_sms && (
              <Tab.Panel>
                <Meta title={`${t("settings.title")} - ${t("settings.tabs.sms")}`} />
                <SmsSettings />
              </Tab.Panel>
            )}
            {role === Role.Owner && (
              <Tab.Panel>
                <Meta title={`${t("settings.title")} - ${t("settings.tabs.sale")}`} />
                <SaleSettings />
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Layout>
  );
};

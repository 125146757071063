import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { ClientList } from "@features/clients/components/ClientList";
import { ClientListSubheader } from "@features/clients/components/ClientListSubheader";

import { ClientsProvider } from "@/features/clients/providers/ClientsProvider";

export const ClientsView = () => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("sidebar.navigation.appointments.children.clients")}
      className="rounded-2xl bg-gray-100">
      <ClientsProvider>
        <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
          <ClientListSubheader />
          <ClientList />
        </div>
      </ClientsProvider>
    </Layout>
  );
};

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import { EllipsisHorizontalIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { Button } from "@/components/ui/Button";

type EmployeeWorkScheduleItemMenuProps = {
  totalRanges: number;
  onAddRange?: () => void;
  onRemoveRange?: () => void;
};

export const EmployeeWorkScheduleItemMenu = ({
  onAddRange,
  onRemoveRange,
  totalRanges,
}: EmployeeWorkScheduleItemMenuProps) => {
  const { t } = useTranslation();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex w-full items-center justify-center rounded-md" as="div">
        <Button variant="neutral" className="w-fit px-3 py-3">
          <EllipsisHorizontalIcon className="h-4 w-4" />
        </Button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items
          as="ul"
          className="absolute right-1 z-10 -mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-gray-300 focus:outline-none">
          <Menu.Item as="li">
            {({ active }) => (
              <Button
                startIcon={
                  <PlusIcon
                    className={clsx("text-gray-500", {
                      "text-gray-700": active,
                    })}
                  />
                }
                variant="neutral"
                size="small"
                className={clsx(
                  "flex w-full whitespace-nowrap rounded-t-md px-4 py-2.5 text-xs text-gray-600",
                  {
                    "bg-gray-50 text-gray-700": active,
                  },
                )}
                onClick={onAddRange}>
                {t("employees.employeeProfile.workSchedule.addHours")}
              </Button>
            )}
          </Menu.Item>
          <Menu.Item as="li">
            {({ active }) => (
              <Button
                startIcon={
                  <TrashIcon
                    className={clsx({
                      "text-red-500": !active && totalRanges > 1,
                      "text-red-700": active && totalRanges > 1,
                      "text-gray-400": totalRanges <= 1,
                    })}
                  />
                }
                variant="neutral"
                size="small"
                className={clsx("flex w-full whitespace-nowrap rounded-b-md px-4 py-2.5 text-xs", {
                  "text-red-600": !active && totalRanges > 1,
                  "bg-red-50 text-red-700": active && totalRanges > 1,
                  "cursor-not-allowed text-gray-400": totalRanges <= 1,
                })}
                onClick={onRemoveRange}
                disabled={totalRanges <= 1}>
                {t("employees.employeeProfile.workSchedule.removeHours")}
              </Button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

import { FINALIZED_COLOR } from "@features/calendar/constants";
import clsx from "clsx";

import { SERVICE_CATEGORY_COLORS } from "@/constants/colors";
import { Category } from "@/types";

type CategoryEntityProps = {
  entity: Category;
};

export const CategoryEntity = ({ entity }: CategoryEntityProps) => {
  const color = SERVICE_CATEGORY_COLORS[entity.color] || FINALIZED_COLOR;

  return (
    <div className="flex items-center gap-3">
      <div className={clsx("h-6 w-6 rounded-full border", color.primary, color.border)}></div>
      {entity.name}
    </div>
  );
};

import { forwardRef, ForwardedRef } from "react";
import { Control, Controller } from "react-hook-form";

import { Switch as HeadlessSwitch } from "@headlessui/react";

import clsx from "clsx";

type SwitchProps = {
  label?: string;
  name: string;
  control: Control<any>;
  className?: string;
  errorMessage?: string;
  showLabel?: boolean;
  disabled?: boolean;
  onChange?: (name: string, data: boolean) => void;
};

export const Switch = forwardRef(
  (
    { label, name, control, className, errorMessage, showLabel, onChange, disabled }: SwitchProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <HeadlessSwitch.Group>
        <div className={clsx("flex items-center", className)} ref={ref}>
          <HeadlessSwitch.Label
            className={
              showLabel
                ? `cursor-inherit pr-2 text-sm  ${disabled ? "text-gray-400" : "text-gray-900"}`
                : "sr-only"
            }>
            {label}
          </HeadlessSwitch.Label>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange: formOnChange, value = false } }) => (
              <HeadlessSwitch
                checked={value}
                value={value}
                disabled={disabled}
                onChange={(data) => {
                  formOnChange(data);
                  if (onChange) {
                    onChange(name, data);
                  }
                }}
                className={clsx(
                  disabled ? "bg-gray-200" : value ? "bg-violet-500" : "bg-gray-400",
                  "relative inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-violet-600 focus:ring-offset-2",
                )}>
                <span
                  className={`${
                    value ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform cursor-pointer rounded-full bg-white transition-transform`}
                />
              </HeadlessSwitch>
            )}
          />
        </div>
        {errorMessage && <span className="block text-xs text-red-600">{errorMessage}</span>}
      </HeadlessSwitch.Group>
    );
  },
);

import { useState, forwardRef, ForwardedRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PhotoIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";

export const CoverImageField = forwardRef(
  ({ loadedCoverUrl, errorMessage, ...delegated }: any, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { setValue } = useForm();

    const handleUpload = async () => {
      const fileInput = document.getElementById("image-upload") as HTMLInputElement;
      fileInput.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setValue("backgroundImageUrl", event.target?.result);
        };
        reader.readAsDataURL(file);
        setSelectedFile(file);
      }
    };

    return (
      <div className="col-span-full">
        <label htmlFor="cover-photo" className="block text-xs text-gray-500">
          {t("salonProfile.coverImage")}
        </label>
        <input
          id="image-upload"
          name="image-upload"
          type="file"
          className="sr-only"
          {...delegated}
          onChange={(e) => {
            delegated.onChange(e);
            handleFileChange(e);
          }}
          ref={ref}
        />
        {(selectedFile && !errorMessage) || loadedCoverUrl ? (
          <div className="mt-1 flex items-center">
            <div className="relative w-full bg-gray-100">
              <img
                crossOrigin="anonymous"
                id="cover-photo-preview"
                alt=""
                className="aspect-[3/1] min-h-[200px] rounded-lg object-cover"
                src={
                  selectedFile && !errorMessage ? URL.createObjectURL(selectedFile) : loadedCoverUrl
                }
              />
              <Button size="small" onClick={handleUpload} className="absolute bottom-2 right-4">
                {t("generic.change")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-2 flex aspect-[3/1] items-center justify-center rounded-md border border-dashed border-gray-300 bg-white px-6 py-10 shadow-sm">
            <div className="text-center">
              <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
              <div className="mt-4 flex justify-center text-sm leading-6 text-gray-500">
                <label
                  htmlFor="image-upload"
                  className="relative cursor-pointer rounded-md font-semibold text-violet-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-violet-600 focus-within:ring-offset-2 hover:text-violet-500">
                  <span>{t("salonProfile.addImage")}</span>
                </label>
              </div>
              <p className="text-xs leading-5 text-gray-500">
                {t("salonProfile.coverImageFormat")}
              </p>
              <p className="text-xs leading-5 text-gray-500">{t("salonProfile.coverImageRatio")}</p>
            </div>
          </div>
        )}
        <span className="block text-xs text-red-600">{errorMessage && errorMessage}</span>
      </div>
    );
  },
);

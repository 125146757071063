import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { CalendarContent } from "@features/calendar/components/CalendarContent";
import { CalendarFilters } from "@features/calendar/components/CalendarFilters";
import { CalendarFooter } from "@features/calendar/components/CalendarFooter";
import { CalendarSubheader } from "@features/calendar/components/CalendarSubheader";

import { useSessionStore } from "@/features/session/hooks";

export const AppointementCalendarView = () => {
  const { t } = useTranslation();
  const { isXl } = useBreakpoint("xl");
  const { isSm } = useBreakpoint("sm");
  const { permissions } = useSessionStore();

  return (
    <Layout
      title={t("sidebar.navigation.appointments.children.calendar")}
      restrainHeight
      className="bg-gray-50">
      <div className="grid h-full grid-cols-1 grid-rows-[1fr,_minmax(0,_100%)] gap-x-6 gap-y-2 sm:gap-y-4 xl:grid-cols-[auto,_minmax(0,_100%)]">
        {isXl && <CalendarFilters />}
        <CalendarSubheader />
        <CalendarContent />
        {isSm || (!permissions.add_appointment && !permissions.add_slot_block) || (
          <CalendarFooter />
        )}
      </div>
    </Layout>
  );
};

import { Fragment, ReactNode } from "react";

import { Listbox } from "@headlessui/react";

import { Float } from "@headlessui-float/react";

import { Option, getOptionByValue } from "@/utils/select-utils";

import { SelectButton } from "./SelectButton";
import { SelectOptions } from "./SelectOptions";

type SelectProps = {
  label?: string;
  name: string;
  value: string | string[];
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  selectBy?: "value" | undefined;
  selected?: string | string[];
  multiple?: boolean;
  onChange?: (data: string | string[]) => void;
  onClear?: () => void;
  renderCustomSelectButton?: () => ReactNode;
  renderCustomSelectOptions?: () => ReactNode;
};

export const Select = ({
  label,
  name,
  value,
  options,
  disabled,
  placeholder,
  selectBy = undefined,
  multiple = false,
  onChange,
  onClear,
  renderCustomSelectButton,
  renderCustomSelectOptions,
}: SelectProps) => {
  return (
    <div className="relative w-full">
      <Listbox
        value={value}
        by={selectBy}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}>
        <Listbox.Label className={label ? "mb-1 block text-xs text-gray-500" : "sr-only"}>
          {label}
        </Listbox.Label>
        <Float
          as="div"
          className="relative w-full"
          placement="bottom"
          offset={4}
          flip={10}
          zIndex={10}
          floatingAs={Fragment}>
          <div>
            {renderCustomSelectButton ? (
              renderCustomSelectButton()
            ) : (
              <SelectButton
                name={name}
                selected={getOptionByValue(value, options) as Option | Option[]}
                multiple={multiple}
                placeholder={placeholder}
                disabled={disabled}
                onClear={onClear}
              />
            )}
          </div>
          <div className="w-full">
            {renderCustomSelectOptions ? (
              renderCustomSelectOptions()
            ) : (
              <SelectOptions
                options={options}
                selected={value}
                multiple={multiple}
                selectBy={selectBy}
              />
            )}
          </div>
        </Float>
      </Listbox>
    </div>
  );
};

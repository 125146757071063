import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PencilIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { useSessionStore } from "@/features/session/hooks";
import { Employee } from "@/types";

type EmployeeProfileProps = {
  selectedEmployee: Employee;
};

export const EmployeeProfile = ({ selectedEmployee }: EmployeeProfileProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { permissions } = useSessionStore();

  return (
    <article className="flex min-h-[600px] w-full flex-col rounded-lg bg-white @container">
      <div className="mx-auto w-full max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex w-full flex-wrap items-center pb-1 @[480px]:flex-nowrap @[480px]:items-center @[480px]:justify-between">
          <h1 className="mt-1 text-2xl font-bold text-gray-900 @[480px]:w-max">
            {selectedEmployee.fullName}
          </h1>
          <div className="mt-2 flex w-full flex-row justify-stretch space-x-3 self-end @[480px]:ml-4 @[480px]:mt-0 @[480px]:w-auto">
            <Button
              size="small"
              variant="secondary"
              disabled={!permissions.edit_employee}
              className="mt-3 gap-x-1.5 @[480px]:w-fit"
              onClick={() => navigate(`/employees/${selectedEmployee.accountUuid}/edit`)}>
              <PencilIcon
                className="-ml-0.5 h-4 w-4 text-gray-500 transition-colors hover:text-gray-600"
                aria-hidden="true"
              />
              {t("generic.edit")}
            </Button>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-6 w-full max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 @[480px]:grid-cols-2">
          <div className="@[480px]:col-span-1">
            <div className="mb-2 text-sm font-medium text-gray-500">
              {t("generic.contactDetails")}
            </div>
            {selectedEmployee.phone && (
              <div className="mt-1 text-sm text-gray-900">{selectedEmployee.phone}</div>
            )}
            <div className="mt-1 text-sm text-gray-900">{selectedEmployee.email}</div>
          </div>
        </div>
      </div>
    </article>
  );
};

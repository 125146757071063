import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DndContext, closestCorners, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToWindowEdges,
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { useHeadCategoriesStore } from "@features/services/hooks";

import { HeadCategoryListItem } from "@/features/services/components/headCategories/HeadCategoryListItem";
import { HeadCategory } from "@/types";

type HeadCategoryListWrapperProps = {
  noResultText: string;
  renderHeadCategory: (headCategory: HeadCategory) => ReactNode;
  onEdit?: (headCategory: HeadCategory) => void;
  onRemove?: (headCategory: HeadCategory) => void;
  onSelect?: (headCategory: HeadCategory) => void;
  isDraggable?: boolean;
  categoryUuid?: string;
  setTempHeadCategories: (headCategories: HeadCategory[]) => void;
  headCategories: HeadCategory[];
};

export const HeadCategoryListWrapper = ({
  noResultText,
  renderHeadCategory,
  onEdit,
  onRemove,
  onSelect,
  isDraggable = false,
  setTempHeadCategories,
  headCategories,
}: HeadCategoryListWrapperProps) => {
  const { t } = useTranslation();
  const { loading, loadingFetchMore, hasMore, fetchMore, count } = useHeadCategoriesStore();

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const activeIndex = headCategories.findIndex(
          (headCategory: HeadCategory) => headCategory.uuid === active.id,
        );
        const overIndex = headCategories.findIndex(
          (headCategory: HeadCategory) => headCategory.uuid === over.id,
        );
        const newEntities = arrayMove(headCategories, activeIndex, overIndex);

        setTempHeadCategories(newEntities);
      }
    },
    [headCategories, setTempHeadCategories],
  );

  const handleFetchMore = useCallback(() => {
    fetchMore()?.then((data) => {
      const fetchedHeadCategories = data.data.fetchHeadCategories.edges.map(
        (edge: { node: HeadCategory }) => edge.node,
      );
      const newEntities = [...headCategories, ...fetchedHeadCategories];
      setTempHeadCategories(newEntities);
    });
  }, [fetchMore, headCategories, setTempHeadCategories]);

  if (loading) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  if (headCategories.length === 0) {
    return (
      <span className="flex w-full items-center justify-center py-10 text-gray-500">
        {noResultText}
      </span>
    );
  }

  return (
    <DndContext
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement]}>
      <ul className="divide-y">
        <SortableContext
          items={headCategories.map((headCategory: HeadCategory) => headCategory.uuid)}
          strategy={verticalListSortingStrategy}>
          {headCategories.map((headCategory: HeadCategory) => (
            <HeadCategoryListItem
              key={headCategory.uuid}
              headCategory={headCategory as HeadCategory}
              renderHeadCategory={renderHeadCategory as (headCategory: HeadCategory) => ReactNode}
              onEdit={onEdit as (headCategory: HeadCategory) => void}
              onRemove={onRemove as (headCategory: HeadCategory) => void}
              onSelect={onSelect as (headCategory: HeadCategory) => void}
              isDraggable={isDraggable}
            />
          ))}
          {hasMore && headCategories.length < count && !loadingFetchMore && (
            <li className="py-4">
              <Button onClick={handleFetchMore} size="small" className="mx-auto">
                {t("generic.loadMore")}
              </Button>
            </li>
          )}
          {loadingFetchMore && (
            <li className="flex justify-center py-4">
              <Spinner className="h-8 w-8" />
            </li>
          )}
        </SortableContext>
      </ul>
    </DndContext>
  );
};

import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";
import { DateField } from "@components/ui/DateField";
import { Switch } from "@components/ui/Switch";
import { TextAreaField } from "@components/ui/TextAreaField";
import { Tooltip } from "@components/ui/Tooltip";

import { useCountersStore, useSessionStore } from "@features/session/hooks";

import { ClientSelect } from "@/features/clients/components/ClientSelect";
import { useAwsStore } from "@/hooks/use-aws-store";
import {
  UpdateBulkAppointmentInput,
  UpdateBulkAppointmentTreatmentInput,
  AppointmentFragment,
  Appointment,
  AppointmentStatus,
  Client,
} from "@/types";
import { removeImageFromS3, uploadImageToS3 } from "@/utils/s3-utils";

import { useAppointmentForm } from "../../hooks/use-appointment-form";
import { isoTimeRangeToTime } from "../../utils/time";
import { AppointmentClientName } from "./AppointmentClientName";
import { AppointmentFormTreatmetsList } from "./AppointmentFormTreatmetsList";
import { ImagesUploader } from "./ImagesUploader";

export type AppointmentFormInput = Omit<UpdateBulkAppointmentInput, "treatments"> & {
  treatments: Array<
    UpdateBulkAppointmentTreatmentInput & {
      treatmentName?: string;
      employeeName?: AppointmentFragment["treatments"][number]["employeeName"];
    }
  >;
  date: Date;
};

export type AppointmentEditFormProps = {
  appointment: Appointment;
  client?: Client;
  onSubmit: SubmitHandler<AppointmentFormInput>;
  onBackClick: () => void;
  onClose: () => void;
};

export const AppointmentEditForm = ({
  appointment,
  client,
  onSubmit,
  onBackClick,
  onClose,
}: AppointmentEditFormProps) => {
  const { t } = useTranslation();
  const { smsLimit } = useCountersStore();
  const { isSalonPlan } = useSessionStore();

  const {
    addImages,
    removeImage,
    images,
    loadServerImages,
    imagesErrors,
    setImagesErrors,
    imagesLoading,
    setImagesLoading,
    getServerImagesSize,
    setServerImageToRemove,
    currentImagesUrls,
  } = useAwsStore();

  const defaultValues = {
    ...appointment,
    clientUuid: appointment.clientUuid || undefined,
    treatments: appointment.treatments.map((treatment) => ({
      ...treatment,
      timeRange: isoTimeRangeToTime(treatment.timeRange),
    })),
    date: new Date(appointment.treatments[0].timeRange.from),
    sendNotification: false,
    imagesUrls: appointment.imagesUrls?.map((image) => image.imageUrl) || [],
  };

  const {
    form,
    selectedClient,
    treatmentsArray,
    confirmationSmsContent,
    equipmentAvailability,
    currentEntityIndex,
    handleAddTreatment,
    handleClientSelect,
    handleClientClear,
    handleRemoveTreatment,
    handleTreatmentSelect,
    setSelectedClient,
    handleEmployeeSelect,
    handleTimerangeChange,
    handleFromTimeChange,
    setCurrentEntityIndex,
    selectedTreatments,
  } = useAppointmentForm({
    defaultValues,
    appointment,
  });

  const {
    register,
    formState: { errors, isValid, isDirty, dirtyFields },
    control,
    setValue,
    handleSubmit,
  } = form;

  const appointmentFormProps = {
    treatmentsArray,
    equipmentAvailability,
    currentEntityIndex,
    handleAddTreatment,
    handleRemoveTreatment,
    handleTreatmentSelect,
    handleEmployeeSelect,
    setCurrentEntityIndex,
    handleTimerangeChange,
    handleFromTimeChange,
    selectedTreatments,
  };

  const confirmationSmsLabel = useMemo(() => {
    if (appointment?.status === AppointmentStatus.Requested) {
      return t("appointments.smsInfo.updateRequesteAppointment");
    }
    return t("appointments.smsInfo.updateAppointment");
  }, [appointment?.status]);

  const imagesToRemove = useMemo(() => {
    return images.filter((image) => image.toRemove);
  }, [images]);

  const imagesToUpload = useMemo(() => {
    return images.filter((image) => !image.isServerImage);
  }, [images]);

  const shouldDisableNotification = (() => {
    if (smsLimit === 0 || !selectedClient?.phone || !isDirty) {
      return true;
    }

    const nonNotificationTriggeringFields = ["note", "imagesUrls", "sendNotification"];

    const dirtyFieldNames = Object.keys(dirtyFields);

    const hasOtherDirtyFields = dirtyFieldNames.some(
      (fieldName) => !nonNotificationTriggeringFields.includes(fieldName),
    );

    return !hasOtherDirtyFields;
  })();

  const handleSubmitClick = async (form: AppointmentFormInput) => {
    setImagesLoading(true);

    try {
      if (imagesToUpload.length > 0) {
        await Promise.all(
          imagesToUpload.map((image) => {
            return uploadImageToS3(image.file, image.name);
          }),
        );
      }
    } catch (error) {
      console.error("Error while uploading images", error);
    } finally {
      setImagesLoading(false);
    }

    try {
      if (imagesToRemove.length > 0) {
        await Promise.all(imagesToRemove.map((image) => removeImageFromS3(image.name)));
      }
    } catch (error) {
      console.error("Error while removing images", error);
    } finally {
      setImagesLoading(false);
    }

    onSubmit(form);
    setImagesLoading(false);
  };

  useEffect(() => {
    const serverImages = appointment.imagesUrls?.map((image) => image.imageUrl) || [];

    if (serverImages.length > 0) {
      loadServerImages(serverImages);
    }
  }, [appointment.imagesUrls]);

  useEffect(() => {
    if (client) {
      setSelectedClient(client);
    }
  }, [client]);

  useEffect(() => {
    if (shouldDisableNotification) {
      form.setValue("sendNotification", false);
    }
  }, [shouldDisableNotification]);

  useEffect(() => {
    setValue("imagesUrls", currentImagesUrls, { shouldDirty: true });
  }, [currentImagesUrls.length]);

  return (
    <FormProvider {...form}>
      <form className="mt-2 space-y-4" onSubmit={handleSubmit(handleSubmitClick)} noValidate>
        <div className="space-y-4">
          <div>
            {client?.uuid && appointment?.clientName ? (
              <AppointmentClientName client={client} onClose={onClose} />
            ) : (
              <ClientSelect
                control={control}
                name="clientUuid"
                onChange={handleClientSelect}
                onClear={() => handleClientClear("clientUuid")}
              />
            )}
            {selectedClient?.note && (
              <div className="mt-2 flex items-center justify-self-end rounded-md bg-gray-100 px-3 py-2">
                <InformationCircleIcon className="mr-1 mt-0.5 h-3 w-3 shrink-0 self-start" />
                <p className="line-clamp-2 text-xs font-normal">{selectedClient?.note}</p>
              </div>
            )}
          </div>
          <DateField
            control={control}
            name="date"
            className="grow"
            label={t("generic.date")}
            portalId="date"
            error={errors.date}
          />
          <AppointmentFormTreatmetsList appointmentFormProps={appointmentFormProps} />
          <TextAreaField
            {...register("note", { maxLength: 500 })}
            label={t("appointments.form.note")}
            placeholder={t("generic.note")}
            errorMessage={
              errors.note &&
              t(`validation.${errors.note.type}`, {
                name: t("generic.note"),
                maxLength: 500,
              })
            }
          />
          {isSalonPlan && (
            <ImagesUploader
              images={images}
              imagesErrors={imagesErrors}
              addImages={addImages}
              removeImage={removeImage}
              setImagesErrors={setImagesErrors}
              handleRemoveFromServer={setServerImageToRemove}
              getServerImagesSize={getServerImagesSize}
            />
          )}
          <div className="inline-block">
            <Tooltip content={confirmationSmsContent}>
              <Switch
                control={control}
                name="sendNotification"
                label={confirmationSmsLabel}
                showLabel
                disabled={shouldDisableNotification}
              />
            </Tooltip>
          </div>
          <div className="flex gap-4 pt-4">
            <Button variant="secondary-outline" fullWidth onClick={onBackClick}>
              {t("generic.back")}
            </Button>
            <Button
              fullWidth
              disabled={!isValid || !isDirty || imagesLoading}
              loading={imagesLoading}
              type="submit">
              {imagesLoading ? t("appointments.weAreSavingImages") : t("generic.save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

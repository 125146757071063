import React, { Fragment, forwardRef, ForwardedRef } from "react";

import { getDayHours } from "@utils/datetime";

export const CalendarScaleBar = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const scaleDivisions = getDayHours();
  const scaleSubdivisionsCount = scaleDivisions.length * 2; // 1 row = 30 min

  return (
    <div
      ref={ref}
      className="relative col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-200"
      style={{ gridTemplateRows: `repeat(${scaleSubdivisionsCount}, minmax(3.5rem, 1fr))` }}>
      <div className="row-end-1 h-7"></div>
      {scaleDivisions.map((division) => (
        <Fragment key={division}>
          <div>
            <div className="sticky left-0 z-30 -ml-14 -mt-2.5 w-10 pr-1.5 text-right text-xs leading-5 text-gray-400">
              {division}
            </div>
          </div>
          <div></div>
        </Fragment>
      ))}
    </div>
  );
});

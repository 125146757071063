import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import { Dialog, Transition } from "@headlessui/react";

import {
  ChevronLeftIcon,
  XMarkIcon,
  PlusCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { useDialog, bindDialogState } from "@hooks/use-dialog";

import {
  ClientFormDialog,
  ClientFormDialogStateProps,
} from "@/features/clients/components/ClientFormDialog";
import { ClientProfile } from "@/features/clients/components/ClientProfile";
import { ClientsDirectory } from "@/features/clients/components/ClientsDirectory";
import { ClientSearch } from "@/features/clients/components/ClientSearch";
import { useClientsContext } from "@/features/clients/providers/ClientsProvider";
import { Client } from "@/types";

import { ClientsDirectoryActionsMenu } from "./ClientsDirectoryActionsMenu";

export const ClientList = () => {
  const { t } = useTranslation();
  const { ref, height } = useResizeDetector();
  const { clientsStore, isTagsSelected } = useClientsContext();
  const [asideHeight, setAsideHeight] = useState(height);
  const [open, setOpen] = useState(false);
  const dialog = useDialog<ClientFormDialogStateProps>();

  useEffect(() => {
    setAsideHeight(height);
  }, [height]);

  const { clientsDirectory, loading, hasMore, loadingFetchMore, searchQuery, fetchMore } =
    clientsStore;

  const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);

  useEffect(() => {
    if (selectedClient) {
      return;
    }

    const firstClientInDirectory = Object.values(clientsDirectory)?.[0]?.[0];
    if (firstClientInDirectory) {
      setSelectedClient(firstClientInDirectory);
    }
  }, [clientsDirectory, selectedClient]);

  const handleClientSelect = (client: Client) => {
    setSelectedClient(client);
    setOpen(true);
  };

  const isClientsDirectoryEmpty = Object.keys(clientsDirectory).length === 0;

  if (isClientsDirectoryEmpty && !searchQuery && !isTagsSelected && !selectedClient) {
    {
      return loading ? (
        <div className="flex justify-center p-10">
          <Spinner className="h-8 w-8" />
        </div>
      ) : (
        <div className="flex h-96 min-h-full flex-col justify-center text-center">
          <UserGroupIcon className="mx-auto h-10 w-10 text-gray-400" aria-hidden="true" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">{t("clients.noClients")}</h3>
          <p className="mt-1 text-sm text-gray-500">{t("clients.addFirstClient")}</p>
          <div className="mt-6">
            <Button
              startIcon={<PlusCircleIcon />}
              size="small"
              className="mx-auto"
              onClick={() => dialog.open()}>
              {t("clients.addNew")}
            </Button>
          </div>
          <ClientFormDialog {...bindDialogState(dialog)} title={t("clients.addNew")} />
        </div>
      );
    }
  }

  if (loading || !selectedClient) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  return (
    <div className="@container">
      <div className="md:min-h-auto relative z-0 -mx-4 mt-6 flex min-h-[60vh] flex-1 overflow-hidden rounded-lg bg-white px-4 pt-1 @[670px]:m-0 @[670px]:p-0 sm:mt-0 sm:pt-0">
        <main
          ref={ref}
          className="custom-client-profile-height relative z-0 order-last hidden h-full overflow-y-auto border-l border-gray-200 px-4 focus:outline-none @[670px]:flex @[670px]:flex-1">
          <ClientProfile client={selectedClient} />
        </main>

        <aside
          style={{ maxHeight: asideHeight ? `${asideHeight}px` : "100%" }}
          className="order-first flex w-full flex-col @[670px]:w-72 lg:w-80 xl:w-96">
          <div className="mb-1 @[670px]:mx-4">
            <ClientSearch />
            <ClientsDirectoryActionsMenu />
          </div>
          {isClientsDirectoryEmpty && !searchQuery && !isTagsSelected ? (
            <div className="flex justify-center border-t p-10">
              <Spinner className="h-8 w-8" />
            </div>
          ) : isClientsDirectoryEmpty && searchQuery && selectedClient ? (
            <div className="flex border-t px-4 pt-4">
              <p className="text-sm text-gray-500">
                {t("clients.clientSearchNoResultsInfo", {
                  searchQuery,
                })}
              </p>
            </div>
          ) : (
            <div className="h-full @[670px]:pb-28">
              <ClientsDirectory
                clientsDirectory={clientsDirectory}
                handleClientSelect={handleClientSelect}
                selectedClient={selectedClient}
                hasMore={hasMore}
                loadingFetchMore={loadingFetchMore}
                fetchMore={fetchMore}
              />
            </div>
          )}
        </aside>

        {!asideHeight && (
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="m-0 flex min-h-full items-center justify-center text-center sm:mx-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden bg-white text-left shadow-xl transition-all xs:rounded-lg xs:px-4 sm:my-8 sm:p-6 sm:pb-4 sm:pt-5">
                      <nav
                        className="-ml-2 mt-4 flex items-start px-4 sm:hidden"
                        aria-label="Breadcrumb">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="inline-flex items-center space-x-2 px-2.5 py-1.5 text-xs font-medium text-gray-500 transition-colors hover:text-gray-700">
                          <ChevronLeftIcon
                            className="-ml-2 h-4 w-4 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="underline">{t("clients.clientsDirectory")}</span>
                        </button>
                      </nav>
                      <nav
                        className="absolute right-5 top-4 z-10 hidden sm:flex"
                        aria-label="Breadcrumb">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="rounded-full border border-gray-300 p-1 text-gray-400 shadow-sm transition-colors hover:border-gray-400 hover:bg-gray-50 hover:text-gray-500">
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </nav>
                      <div className="px-4 xs:px-0 sm:-mx-2">
                        <ClientProfile client={selectedClient} />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </div>
    </div>
  );
};

import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import { BanknotesIcon, ClockIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { TREATMENTS_FETCH_LIMIT } from "@features/services/hooks/use-treatments-search-store";

import { Button } from "@/components/ui/Button";
import { SelectSearch } from "@/components/ui/Select/SelectSearch";
import { Spinner } from "@/components/ui/Spinner";
import { formatPrice } from "@/features/payments/utils";
import { formatTreatmentDuration } from "@/features/services/utils";
import { Option, GroupedOption, getGroupedOptions } from "@/utils/select-utils";

type AppointmentTreatmentSelectOptionsProps = {
  options: Option[];
  hasMore: boolean;
  loadingFetchMore: boolean;
  allTreatmentsCount: number;
  fetchMore: (limit?: number) => void;
  onSearch?: (query: string) => void;
  disableSearch?: boolean;
  selected: string;
  name: string;
};

export const AppointmentTreatmentSelectOptions = ({
  options,
  selected,
  allTreatmentsCount,
  hasMore,
  loadingFetchMore,
  fetchMore,
  onSearch,
  disableSearch,
  name,
}: AppointmentTreatmentSelectOptionsProps) => {
  const { t, i18n } = useTranslation();

  const treatmentsLeftCount = allTreatmentsCount - options.length;

  const isOptionSelected = (option: Option) => {
    if (Array.isArray(selected)) {
      return selected.includes(option.value);
    }

    return option.value === selected;
  };

  const groupedOptions = useMemo(() => getGroupedOptions(options), [options]);

  return (
    <Listbox.Options className="mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-gray-300 focus:outline-none">
      {!disableSearch && onSearch && (
        <SelectSearch
          name={name}
          placeholder={t("actions.searchTreatment")}
          onSearch={onSearch}
          noResult={options.length === 0}
        />
      )}
      <div className="mt-1">
        {groupedOptions.map((group: GroupedOption) => {
          return (
            <div key={group.groupValue}>
              <div className={`bg-gray-100 px-4 py-1`}>
                <span className={`text-[11px] font-semibold uppercase text-gray-900`}>
                  {group.groupLabel}
                </span>
              </div>
              {group.options.map((option: Option) => (
                <Listbox.Option key={option.value} value={option.value} as={Fragment}>
                  {({ active }) => (
                    <li
                      className={clsx(
                        active && `bg-gray-50 text-gray-900`,
                        !active && isOptionSelected(option) && `bg-gray-100 text-gray-900`,
                        `relative flex cursor-pointer select-none gap-4 px-4 py-3 text-gray-600`,
                      )}>
                      <div>
                        <p>{option.label}</p>
                        <div className="inline-flex items-center gap-2">
                          <div className="mt-1 flex w-auto items-center text-xs text-gray-500">
                            <BanknotesIcon className="mr-1 h-3.5 w-3.5" aria-hidden="true" />
                            {formatPrice({ price: option.props.treatmentPrice, currency: "PLN" })}
                          </div>
                          <div className="mt-1 flex w-auto items-center text-xs text-gray-500">
                            <ClockIcon className="mr-0.5 h-3.5 w-3.5" aria-hidden="true" />
                            {formatTreatmentDuration(option.props.treatmentDuration, i18n.language)}
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </div>
          );
        })}
      </div>
      {hasMore && !loadingFetchMore && (
        <div className="flex flex-wrap justify-start gap-2 bg-gray-100 px-3 py-4">
          <Button onClick={() => fetchMore()} variant="primary" size="tiny" className="grow">
            {t("actions.loadMore", {
              count:
                TREATMENTS_FETCH_LIMIT > treatmentsLeftCount
                  ? treatmentsLeftCount
                  : TREATMENTS_FETCH_LIMIT,
            })}
          </Button>
          <Button
            onClick={() => fetchMore(treatmentsLeftCount)}
            variant="primary-outline"
            size="tiny"
            className="grow">
            {t("actions.loadAll", {
              count: treatmentsLeftCount,
            })}
          </Button>
        </div>
      )}
      {loadingFetchMore && (
        <div className="flex justify-center py-4">
          <Spinner className="h-8 w-8" />
        </div>
      )}
    </Listbox.Options>
  );
};

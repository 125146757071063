import { useTranslation } from "react-i18next";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import { useClientsContext } from "@/features/clients/providers/ClientsProvider";

export const ClientSearch = () => {
  const { t } = useTranslation();

  const { setQuery } = useClientsContext();

  return (
    <div className="mb-1 mt-6">
      <form className="flex gap-x-4" action="#" onSubmit={(e) => e.preventDefault()}>
        <div className="min-w-0 flex-1">
          <label htmlFor="search" className="sr-only">
            {t("generic.search")}
          </label>
          <div className="relative flex items-center gap-2 rounded-md">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="search"
              name="search"
              id="search"
              onChange={(e) => setQuery(e.currentTarget.value)}
              className="block w-full rounded-md border border-gray-300 py-1.5 pl-10 text-sm placeholder:text-gray-400 focus:border-gray-600 focus:ring-0 sm:leading-6"
              placeholder={t("generic.search")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

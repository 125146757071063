import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";

import { GENERIC_NAME } from "@constants/validations";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";
import { TextField } from "@components/ui/TextField";

import { useHeadCategoryStore } from "@features/services/hooks/use-head-category-store";

import { AddHeadCategoryInput, HeadCategory } from "@/types";

export type HeadCategoryFormProps = {
  headCategoryUuid: string;
  onRemove?: (headCategory: HeadCategory) => Promise<boolean>;
};

type HeadCategoryForm = {
  headCategory?: HeadCategory | null;
  onClose: () => void;
  onRemove?: (headCategory: HeadCategory) => Promise<boolean>;
};

type HeadCategoryFormDialogProps = {
  props?: HeadCategoryFormProps;
  onClose: () => void;
  open: boolean;
};

export const HeadCategoryFormDialog = ({ open, props, onClose }: HeadCategoryFormDialogProps) => {
  const { t } = useTranslation();
  const { headCategory, loading } = useHeadCategoryStore(props?.headCategoryUuid);

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={
        props?.headCategoryUuid ? (
          <span>{t("services.headCategories.edit")}</span>
        ) : (
          <span>{t("services.headCategories.addNew")}</span>
        )
      }>
      {loading ? (
        <div className="flex justify-center p-10">
          <Spinner className="h-8 w-8" />
        </div>
      ) : (
        <HeadCategoryForm
          onClose={onClose}
          onRemove={props?.onRemove}
          headCategory={headCategory}
        />
      )}
    </DefaultDialog>
  );
};

const HeadCategoryForm = ({ headCategory, onClose, onRemove }: HeadCategoryForm) => {
  const { t } = useTranslation();

  const { loadingAdd, loadingUpdate, addHeadCategory, updateHeadCategory } = useHeadCategoryStore();

  const {
    register,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useForm<AddHeadCategoryInput>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      ...headCategory,
    },
  });

  const handleHeadCategoryForm = async (formData: AddHeadCategoryInput) => {
    const { errors } = headCategory
      ? await updateHeadCategory(formData, headCategory.uuid)
      : await addHeadCategory(formData);

    if (!errors) {
      onClose();
    }
  };

  const handleHeadCategoryDelete = async () => {
    if (!onRemove || !headCategory) return;

    const result = await onRemove(headCategory);
    result && onClose();
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(handleHeadCategoryForm)} noValidate>
      <p className="text-gray-500">{t("services.headCategories.addNewDescription")}</p>
      <TextField
        {...register("name", GENERIC_NAME)}
        label={t("services.headCategories.groupName")}
        startIcon={<ChatBubbleBottomCenterTextIcon />}
        placeholder={t("services.headCategories.groupNamePlaceholder")}
      />
      <div className="mt-4 flex flex-col items-center gap-4 md:flex-row">
        {headCategory && (
          <Button variant="danger" fullWidth onClick={handleHeadCategoryDelete}>
            {t("generic.delete")}
          </Button>
        )}
        <Button
          variant="primary"
          fullWidth
          type="submit"
          disabled={!isValid || !isDirty || loadingAdd || loadingUpdate}>
          {headCategory ? (
            <span>{t("services.headCategories.edit")}</span>
          ) : (
            <span>{t("services.headCategories.addNew")}</span>
          )}
        </Button>
      </div>
    </form>
  );
};

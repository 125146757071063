import { ServerError, useApolloClient } from "@apollo/client";

import { useAuth0 } from "@auth0/auth0-react";

import { Role, SubscriptionPlan } from "@/types";

import { useCurrentSessionQuery } from "../queries/CurrentSession.generated";

export const useSessionStore = () => {
  const { isAuthenticated, logout } = useAuth0();

  const client = useApolloClient();

  const {
    data: sessionData,
    loading,
    refetch,
    error,
  } = useCurrentSessionQuery({
    skip: !isAuthenticated ?? true,
    errorPolicy: "all",
  });

  const isEmailVerified = !(
    error?.networkError?.name === "ServerError" &&
    // @ts-expect-error - bad library types
    (error.networkError as ServerError)?.result?.message === "email_not_verified"
  );

  const parsePermissions = (permissions: string) => {
    try {
      return JSON.parse(permissions);
    } catch (e) {
      return {};
    }
  };

  const currentSession = sessionData?.currentSession;

  return {
    loading,
    trialUsed: currentSession?.trialUsed,
    permissions: parsePermissions(currentSession?.permissions ?? "{}"),
    session: currentSession,
    salonPlan: currentSession?.salonPlan,
    isFreePlan: currentSession?.salonPlan === SubscriptionPlan.Free,
    isSalonPlan: currentSession?.salonPlan === SubscriptionPlan.Salon,
    role: currentSession?.role,
    isOwner: currentSession?.role === Role.Owner,
    isEmployee: currentSession?.role === Role.Employee,
    isOnboarded: currentSession?.isOnboarded ?? false,
    isEmailVerified,
    refetch,
    logout() {
      logout();
      client.cache.reset();
    },
  };
};

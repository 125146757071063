/* eslint-disable import/no-duplicates */
import { DATE_FORMAT } from "@constants/date-formats";
import { setTimeToDate } from "@utils/datetime";
import {
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  format,
  formatISO,
  parseISO,
  isSameDay,
  startOfDay,
  endOfDay,
  getDayOfYear,
} from "date-fns";
import { enUS } from "date-fns/locale";

import { EmployeeSchedule } from "@/features/employees/models";
import { Timerange } from "@/types";

import {
  DecodedRegularAvailabilityDay,
  DecodedSpecialAvailabilityDay,
  RegularAvailability,
  SpecialAvailability,
} from "../models/specialHours";

export const weekdays = eachDayOfInterval({
  start: startOfWeek(new Date(), { weekStartsOn: 1 }),
  end: endOfWeek(new Date(), { weekStartsOn: 1 }),
}).map((date) => format(date, DATE_FORMAT.DAY_OF_WEEK, { locale: enUS }));

export const encodeRegularAvailability = (
  regular: DecodedRegularAvailabilityDay[],
): RegularAvailability =>
  weekdays.reduce((availability: RegularAvailability, weekday: string) => {
    const dayRegulars = regular.filter((entity) => entity.weekday === weekday);

    availability[weekday] = {
      is_open: dayRegulars[0]?.is_open ?? false,
      ranges: dayRegulars
        .map(({ from, to }) => ({ from, to }))
        .sort(
          (a, b) =>
            setTimeToDate(new Date(), a.from).getTime() -
            setTimeToDate(new Date(), b.from).getTime(),
        ),
    };

    return availability;
  }, {});

export const decodeRegularAvailability = (
  data: RegularAvailability,
): DecodedRegularAvailabilityDay[] =>
  weekdays.reduce((regular: DecodedRegularAvailabilityDay[], weekday: string) => {
    const day = data[weekday];

    return [
      ...regular,
      ...day?.ranges.map((range) => ({
        weekday,
        is_open: day.is_open,
        ...range,
      })),
    ];
  }, []);

export const encodeSpecialAvailability = (
  data: DecodedSpecialAvailabilityDay[],
): SpecialAvailability =>
  data
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .reduce((special: SpecialAvailability, entity) => {
      const index = special.findIndex(({ date }) => isSameDay(date, parseISO(entity.date)));

      if (~index) {
        special[index].ranges = [
          ...special[index].ranges,
          ...(entity.ranges || []),
          ...(entity.from && entity.to ? [{ from: entity.from, to: entity.to }] : []),
        ];
      } else {
        special.push({
          name: entity.name,
          date: parseISO(entity.date),
          is_open: entity.is_open,
          ranges: [
            ...(entity.ranges || []),
            ...(entity.from && entity.to ? [{ from: entity.from, to: entity.to }] : []),
          ],
        });
      }
      return special;
    }, []);

const compareTimes = (timeA: string, timeB: string): number => {
  const [hourA, minuteA] = timeA.split(":").map(Number);
  const [hourB, minuteB] = timeB.split(":").map(Number);

  if (hourA !== hourB) return hourA - hourB;
  return minuteA - minuteB;
};

const maxTime = (timeA: string, timeB: string): string => {
  return compareTimes(timeA, timeB) > 0 ? timeA : timeB;
};

const minTime = (timeA: string, timeB: string): string => {
  return compareTimes(timeA, timeB) < 0 ? timeA : timeB;
};

const mergeSalonAndEmployeeRanges = (
  salonRanges: Timerange[],
  employeeRanges: Timerange[],
): Timerange[] => {
  const mergedRanges: Timerange[] = [];

  salonRanges.forEach((salonRange) => {
    employeeRanges.forEach((employeeRange) => {
      // Check if the employee range is within the salon range
      if (
        compareTimes(employeeRange.from, salonRange.to) <= 0 &&
        compareTimes(employeeRange.to, salonRange.from) >= 0
      ) {
        mergedRanges.push({
          from: maxTime(employeeRange.from, salonRange.from),
          to: minTime(employeeRange.to, salonRange.to),
        });
      }
    });
  });

  return mergedRanges;
};

export const getEmployeeUnavailabilityRanges = ({
  regular,
  special,
  workSchedule,
  timeRange,
}: {
  regular: RegularAvailability;
  special: SpecialAvailability;
  workSchedule: EmployeeSchedule;
  timeRange: Timerange;
}): Timerange[][] => {
  return eachDayOfInterval({ start: timeRange.from, end: timeRange.to }).map((date) => {
    const salonUnavailabilityRanges: Timerange[] = [];

    const dayOfWeek = format(date, DATE_FORMAT.DAY_OF_WEEK, { locale: enUS });
    const specialDay = special.find((specialDay) => isSameDay(specialDay.date, date));

    const salonHours = specialDay || regular[dayOfWeek];

    // If employee has no schedule, use salon schedule. It can be empty for users that already exist in the system.
    const employeeHours =
      Object.values(workSchedule)[0].ranges.length === 0
        ? regular[dayOfWeek]
        : workSchedule[dayOfWeek];

    if (!salonHours || !employeeHours) return salonUnavailabilityRanges;

    if (salonHours.is_open && employeeHours.is_open) {
      const mergedRanges = mergeSalonAndEmployeeRanges(salonHours.ranges, employeeHours.ranges);

      mergedRanges.forEach((range, index) => {
        if (index === 0) {
          salonUnavailabilityRanges.push({
            from: formatISO(startOfDay(date)),
            to: formatISO(setTimeToDate(date, range.from)),
          });
        } else {
          salonUnavailabilityRanges.push({
            from: formatISO(setTimeToDate(date, mergedRanges[index - 1].to)),
            to: formatISO(setTimeToDate(date, range.from)),
          });
        }
        if (index === mergedRanges.length - 1) {
          salonUnavailabilityRanges.push({
            from: formatISO(setTimeToDate(date, range.to)),
            to: formatISO(endOfDay(date)),
          });
        }
      });
    } else {
      salonUnavailabilityRanges.push({
        from: formatISO(startOfDay(date)),
        to: formatISO(endOfDay(date)),
      });
    }

    return salonUnavailabilityRanges;
  });
};

export const generateWeekWithDaysOfYear = ({
  regular,
  special,
  selectedDate,
}: {
  regular: RegularAvailability;
  special: SpecialAvailability;
  selectedDate: Date;
}) => {
  const specialDatesMap = Object.fromEntries(special.map((s) => [s.date.toISOString(), s]));
  const start = startOfWeek(selectedDate, { weekStartsOn: 1 });
  const end = endOfWeek(selectedDate, { weekStartsOn: 1 });
  const daysOfWeek = eachDayOfInterval({ start, end });

  return daysOfWeek.map((date) => {
    const dayKey = format(date, DATE_FORMAT.DAY_OF_WEEK, { locale: enUS });
    const day = specialDatesMap[date.toISOString()] || regular[dayKey];

    return {
      dayOfYear: getDayOfYear(date),
      isOpen: day.is_open,
    };
  });
};

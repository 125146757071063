import React, { cloneElement, useMemo, useState } from "react";
import { mergeRefs } from "react-merge-refs";

import {
  Placement,
  offset,
  autoPlacement,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  FloatingFocusManager,
} from "@floating-ui/react-dom-interactions";

type PopoverProps = {
  render: (data: { close: () => void }) => React.ReactNode;
  placement?: Placement;
  children: JSX.Element;
  disabled?: boolean;
  takeMousePosition?: boolean;
};

export const Popover = ({
  children,
  render,
  placement,
  disabled,
  takeMousePosition,
}: PopoverProps) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context, refs } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(8), shift({ padding: 8 }), autoPlacement()],
    placement,
    whileElementsMounted: autoUpdate,
  });

  const {
    x: virtualX,
    y: virtualY,
    reference: virtualReference,
    floating: virtualFloating,
    strategy: virtualStrategy,
  } = useFloating({
    middleware: [offset(8), shift({ padding: 8 }), autoPlacement()],
    placement,
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled: !disabled }),
    useRole(context),
    useDismiss(context),
  ]);

  const virtualFloatingRef = useMemo(
    () => mergeRefs([refs.floating, virtualFloating]),
    [refs.floating, virtualFloating],
  );

  const ref = useMemo(() => mergeRefs([reference, (children as any).ref]), [reference, children]);

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({
          ref: takeMousePosition ? virtualReference : ref,
          ...children.props,
          ...(takeMousePosition
            ? {
                onClick({ clientX, clientY }) {
                  virtualReference({
                    getBoundingClientRect() {
                      return {
                        width: 0,
                        height: 0,
                        x: clientX ?? 0,
                        y: clientY ?? 0,
                        left: clientX,
                        right: clientX,
                        top: clientY,
                        bottom: clientY,
                      };
                    },
                  });
                },
              }
            : {}),
        }),
      )}
      {open && (
        <FloatingFocusManager
          context={context}
          modal={false}
          order={["reference", "content"]}
          returnFocus={false}>
          <div
            className="z-50 rounded-sm  bg-white p-4 shadow-lg ring-1 ring-gray-100"
            {...getFloatingProps({
              ref: takeMousePosition ? virtualFloatingRef : floating,
              style: {
                position: takeMousePosition ? virtualStrategy : strategy,
                top: (takeMousePosition ? virtualY : y) ?? 0,
                left: (takeMousePosition ? virtualX : x) ?? 0,
              },
            })}>
            {render({
              close: () => {
                setOpen(false);
              },
            })}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};

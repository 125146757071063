import { Spinner } from "@/components/ui/Spinner";

export const CalendarLoader = () => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded-md border bg-white">
      <div role="status">
        <Spinner className="h-12 w-12 sm:h-16 sm:w-16 lg:h-24 lg:w-24" />
      </div>
    </div>
  );
};

import React from "react";

import { format, eachDayOfInterval, startOfWeek, endOfWeek } from "date-fns";

export const CalendarMonthHeader = () => {
  const days = eachDayOfInterval({ start: startOfWeek(new Date()), end: endOfWeek(new Date()) });

  return (
    <div className="sticky top-0 z-30 grid grid-cols-7 gap-px bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 shadow lg:flex-none">
      {days.map((day, index) => (
        <div key={index} className="bg-white py-2">
          {format(day, "EEEEE")}
          <span className="sr-only sm:not-sr-only">{format(day, "EEE").substring(1)}</span>
        </div>
      ))}
    </div>
  );
};

import { useEffect } from "react";
import { SubmitHandler, DeepPartial, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InformationCircleIcon, PhoneIcon } from "@heroicons/react/24/outline";

import { setTimeToDate } from "@utils/datetime";

import { Button } from "@components/ui/Button";
import { DateField } from "@components/ui/DateField";
import { Switch } from "@components/ui/Switch";
import { TextAreaField } from "@components/ui/TextAreaField";
import { Tooltip } from "@components/ui/Tooltip";

import { useCountersStore, useSessionStore } from "@features/session/hooks";

import { ClientSelect } from "@/features/clients/components/ClientSelect";
import { useAwsStore } from "@/hooks/use-aws-store";
import {
  UpdateBulkAppointmentInput,
  UpdateBulkAppointmentTreatmentInput,
  AppointmentFragment,
  ScheduleBulkAppointmentInput,
  ScheduleBulkAppointmentTreatmentInput,
  Client,
  Appointment,
} from "@/types";
import { createAwsS3Url, uploadImageToS3 } from "@/utils/s3-utils";

import { useAppointmentForm } from "../../hooks/use-appointment-form";
import { useAppointmentStore } from "../../hooks/use-appointment-store";
import { AppointmentFormTreatmetsList } from "./AppointmentFormTreatmetsList";
import { ImagesUploader } from "./ImagesUploader";

export type AppointmentFormInput = Omit<UpdateBulkAppointmentInput, "treatments"> & {
  treatments: Array<
    UpdateBulkAppointmentTreatmentInput & {
      treatmentName?: string;
      employeeName?: AppointmentFragment["treatments"][number]["employeeName"];
    }
  >;
  date: Date;
};

type FormData = Omit<ScheduleBulkAppointmentInput, "treatments"> & {
  treatments: Array<
    ScheduleBulkAppointmentTreatmentInput & {
      treatmentName?: string;
      employeeName?: AppointmentFragment["treatments"][number]["employeeName"];
    }
  >;
  date: Date;
};

export type AppointmentFormProps = {
  defaultValues: DeepPartial<AppointmentFormInput>;
  onClose?: () => void;
  appointment?: Appointment;
};

export const AppointmentForm = ({ defaultValues, onClose }: AppointmentFormProps) => {
  const { t } = useTranslation();
  const { permissions, isSalonPlan } = useSessionStore();
  const { smsLimit } = useCountersStore();
  const { loadingSchedule, schedule } = useAppointmentStore();
  const {
    imagesLoading,
    setImagesLoading,
    images,
    addImages,
    imagesErrors,
    setImagesErrors,
    removeImage,
  } = useAwsStore();

  const {
    form,
    selectedClient,
    confirmationSmsContent,
    handleClientSelect,
    handleClientClear,
    treatmentsArray,
    equipmentAvailability,
    currentEntityIndex,
    handleAddTreatment,
    handleRemoveTreatment,
    handleTreatmentSelect,
    handleEmployeeSelect,
    setCurrentEntityIndex,
    handleTimerangeChange,
    handleFromTimeChange,
    selectedTreatments,
  } = useAppointmentForm({
    defaultValues,
  });

  const {
    register,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    handleSubmit,
  } = form;

  const appointmentFormProps = {
    treatmentsArray,
    equipmentAvailability,
    currentEntityIndex,
    handleAddTreatment,
    handleRemoveTreatment,
    handleTreatmentSelect,
    handleEmployeeSelect,
    setCurrentEntityIndex,
    handleTimerangeChange,
    handleFromTimeChange,
    selectedTreatments,
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (images.length > 0 && data.imagesUrls && images.length !== data.imagesUrls.length) return;

    setImagesLoading(true);

    try {
      await Promise.all(images.map((image) => uploadImageToS3(image.file, image.name)));
      await schedule({
        clientUuid: data.clientUuid || undefined,
        note: data.note,
        imagesUrls: data.imagesUrls,
        sendNotification: data.sendNotification,
        treatments: data.treatments.map(({ timeRange, treatmentUuid, employeeUuid }) => ({
          timeRange: {
            from: setTimeToDate(data.date, timeRange.from),
            to: setTimeToDate(data.date, timeRange.to),
          },
          treatmentUuid,
          employeeUuid,
        })),
      });

      onClose && onClose();
    } catch (error) {
      console.error("Error uploading images", error);
    } finally {
      setImagesLoading(false);
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      setValue(
        "imagesUrls",
        images.map((image) => createAwsS3Url(image.name)),
        { shouldDirty: true },
      );
    } else {
      setValue("imagesUrls", [], { shouldDirty: true });
    }
  }, [images.length]);

  return (
    <FormProvider {...form}>
      <form className="mt-2 space-y-4" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div>
          <ClientSelect
            control={control}
            name="clientUuid"
            onChange={handleClientSelect}
            onClientMutation={(client: Client) => setValue("clientUuid", client.uuid)}
            onClear={() => handleClientClear("clientUuid")}
          />
          {selectedClient?.phone && (
            <div className="mt-2 flex items-center justify-self-end rounded-md bg-gray-100 px-3 py-2">
              <PhoneIcon className="mr-1 mt-0.5 h-3 w-3 shrink-0 self-start" />
              {selectedClient?.phone && (
                <a href={`tel:${selectedClient.phone}`} className="text-xs font-normal underline">
                  {selectedClient.phone}
                </a>
              )}
            </div>
          )}
          {selectedClient?.note && (
            <div className="mt-2 flex items-center justify-self-end rounded-md bg-gray-100 px-3 py-2">
              <InformationCircleIcon className="mr-1 mt-0.5 h-3 w-3 shrink-0 self-start" />
              <p className="text-xs font-normal">{selectedClient?.note}</p>
            </div>
          )}
        </div>
        <DateField
          control={control}
          name="date"
          className="grow"
          label={t("generic.date")}
          portalId="date"
          error={errors.date}
        />
        <AppointmentFormTreatmetsList
          appointmentFormProps={appointmentFormProps}
          onClose={onClose}
        />
        <div>
          <TextAreaField
            {...register("note", { maxLength: 500 })}
            label={t("appointments.form.note")}
            placeholder={t("generic.note")}
            errorMessage={
              errors.note &&
              t(`validation.${errors.note.type}`, {
                name: t("generic.note"),
                maxLength: 500,
              })
            }
          />
          {isSalonPlan && (
            <ImagesUploader
              images={images}
              imagesErrors={imagesErrors}
              addImages={addImages}
              removeImage={removeImage}
              setImagesErrors={setImagesErrors}
            />
          )}
        </div>

        <div className="inline-block">
          <Tooltip content={confirmationSmsContent}>
            <Switch
              control={control}
              name="sendNotification"
              label={t("appointments.smsInfo.newAppointment")}
              showLabel
              disabled={smsLimit === 0 || !selectedClient?.phone}
            />
          </Tooltip>
        </div>
        <div className="align-center mt-4 flex flex-col">
          <Button
            type="submit"
            fullWidth
            loading={imagesLoading}
            className="mt-4"
            disabled={
              !isValid ||
              !isDirty ||
              loadingSchedule ||
              !permissions.add_appointment ||
              imagesLoading
            }>
            {imagesLoading ? t("appointments.weAreSavingImages") : t("appointments.addNew")}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

import { forwardRef, ForwardedRef } from "react";
import { useTranslation } from "react-i18next";

import { Bars3BottomLeftIcon, ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { Breadcrumbs } from "@components/layout/header/Breadcrumbs";
import { SalonDropdown } from "@components/layout/header/SalonDropdown";
import { UserDropdown } from "@components/layout/header/UserDropdown";
import { Button } from "@components/ui/Button";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { TopUpSmsAction } from "@features/payments/components/TopUpSmsAction";

type HeaderProps = {
  toggleSidebar: (flag: boolean) => void;
  isShrinked: boolean;
  setIsShrinked: (isShrinked: boolean) => void;
};

export const Header = forwardRef(
  (
    { toggleSidebar, isShrinked, setIsShrinked }: HeaderProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();
    const { isMd } = useBreakpoint("md");

    return (
      <header
        ref={ref}
        className="sticky top-0 z-30 flex h-full items-center justify-between border-b bg-white pl-2 pr-0 pt-0 sm:border-b-0 sm:py-4 sm:pl-4 md:p-0">
        {isMd ? (
          <div className="h-full w-full">
            <div className="flex justify-between bg-white p-2">
              <button
                className="group flex items-center rounded-md text-violet-600 hover:text-violet-700"
                onClick={() => setIsShrinked(!isShrinked)}>
                <span className="sr-only">{t("sidebar.toggle")}</span>
                <ArrowLeftOnRectangleIcon
                  className={clsx("h-6 w-6 transition-transform", { "-scale-100": isShrinked })}
                  aria-hidden="true"
                />
                <span className="ml-1 -translate-x-1/2 text-sm opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100">
                  {isShrinked ? t("generic.expand") : t("generic.collapse")}
                </span>
              </button>
              <div>
                <SalonDropdown />
                <UserDropdown />
              </div>
            </div>

            <div className="border-y bg-white">
              <div className="flex items-center justify-between p-2">
                <Breadcrumbs />
                <TopUpSmsAction />
              </div>
            </div>
          </div>
        ) : (
          <>
            <Button
              variant="neutral"
              className="pl-0 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet-500"
              onClick={() => toggleSidebar(true)}>
              <span className="sr-only">{t("sidebar.accesibility.openSidebar")}</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </Button>

            <UserDropdown />
          </>
        )}
      </header>
    );
  },
);

import { NetworkStatus } from "@apollo/client";
import { useState, useEffect } from "react";

import { useDebounce } from "@hooks/use-debounce";

import { namedOperations } from "@/types";
import type { Option } from "@/utils/select-utils";

import { useUpdateHeadCategoriesPositionsMutation } from "../mutations/UpdateHeadCategoriesPositions.generated";
import { useFetchHeadCategoriesQuery } from "../queries/FetchHeadCategories.generated";

export const useHeadCategoriesStore = () => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data, fetchMore, refetch, networkStatus } = useFetchHeadCategoriesQuery({
    variables: { first: 30 },
    notifyOnNetworkStatusChange: true,
  });

  const [updateHeadCategoriesPositions, { loading: loadingUpdate }] =
    useUpdateHeadCategoriesPositionsMutation();

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      refetch({ query: debouncedSearchQuery || undefined });
    }
  }, [debouncedSearchQuery]);

  return {
    loading: networkStatus === NetworkStatus.loading,
    loadingFetchMore: networkStatus === NetworkStatus.fetchMore,
    loadingUpdate: loadingUpdate,
    headCategories: data?.fetchHeadCategories.edges.map(({ node }) => node) || [],
    count: data?.fetchHeadCategories.count || 0,
    headCategoryOptions:
      data?.fetchHeadCategories.edges.map(({ node }) => ({
        value: node.uuid,
        label: node.name,
      })) || [],
    groupedCategoryOptions:
      data?.fetchHeadCategories.edges.reduce((groups, { node }) => {
        return groups.concat(
          node.categories?.map((category) => ({
            groupLabel: node.name,
            groupValue: node.uuid,
            label: category?.name || "",
            value: category?.uuid || "",
          })) || [],
        );
      }, [] as Option[]) || [],
    hasMore: data?.fetchHeadCategories.pageInfo.hasNextPage,
    refetchWithQuery(query?: string) {
      setSearchQuery(query);
    },
    fetchMore() {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.fetchHeadCategories.pageInfo.endCursor,
          },
        });
      }
    },
    updateHeadCategoriesPositions({ data }: { data: { sortedHeadCategoryUuids: string[] } }) {
      return updateHeadCategoriesPositions({
        variables: {
          data,
        },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
        update(cache) {
          cache.evict({ fieldName: "fetchHeadCategories" });
          cache.gc();
        },
      });
    },
  };
};

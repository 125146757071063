import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRightOnRectangleIcon, UserIcon } from "@heroicons/react/24/outline";

import { Spinner } from "@components/ui/Spinner";

import { useAccountStore } from "@features/account/hooks";
import { useSessionStore } from "@features/session/hooks";

import { Button } from "@/components/ui/Button";
import { TextField } from "@/components/ui/TextField";
import { NAME } from "@/constants/validations";
import { ROUTES } from "@/features/router/constants/routes";
import { AccountNameUpdateInput } from "@/types";

export const OnboardingPersonalInfoView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, logout, session } = useSessionStore();
  const { loadingUpdateAccountName, updateAccountName } = useAccountStore();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<AccountNameUpdateInput>({
    mode: "all",
  });

  const handleCreateSalon: SubmitHandler<AccountNameUpdateInput> = async (data) => {
    await updateAccountName(data);

    navigate(ROUTES.ONBOARDING_SALON);
  };

  useEffect(() => {
    if (session?.firstName && session?.lastName) {
      handleCreateSalon({ firstName: session.firstName, lastName: session.lastName });
    }
  }, [session]);

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className="mb-2 text-4xl font-bold text-gray-900">
          {t("onboarding.personalInfo.title")}
        </h1>
        <p className="mb-4 text-sm font-normal text-gray-400">
          {t("onboarding.personalInfo.description")}
        </p>
        <div className="fixed right-4 top-4">
          <Button
            size="small"
            variant="primary-inline"
            fullWidth
            startIcon={<ArrowRightOnRectangleIcon />}
            onClick={logout}>
            {t("header.mainMenu.logOut")}
          </Button>
        </div>
        {loading ? (
          <div className="flex w-full justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="space-y-4" onSubmit={handleSubmit(handleCreateSalon)} noValidate>
            <TextField
              {...register("firstName", { required: true, ...NAME })}
              type="text"
              label={t("generic.firstName")}
              startIcon={<UserIcon />}
              placeholder={t("generic.firstName")}
              errorMessage={
                errors.firstName &&
                t(`validation.${errors.firstName.type}`, {
                  name: t("generic.firstName"),
                  ...NAME,
                })
              }
            />
            <TextField
              {...register("lastName", { required: true, ...NAME })}
              type="text"
              label={t("generic.lastName")}
              startIcon={<UserIcon />}
              placeholder={t("generic.lastName")}
              errorMessage={
                errors.lastName &&
                t(`validation.${errors.lastName.type}`, {
                  name: t("generic.lastName"),
                  ...NAME,
                })
              }
            />
            <p className="mb-4 text-sm font-normal text-gray-400">
              {t("onboarding.personalInfo.hint")}
            </p>
            <Button type="submit" fullWidth disabled={!isValid || loadingUpdateAccountName}>
              {t("generic.continue")}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

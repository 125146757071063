import { useTranslation } from "react-i18next";
import { Outlet, useMatch } from "react-router-dom";

import { Layout } from "@components/layout/Layout";
import { Subheader } from "@components/layout/subheader/Subheader";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { HeadCategoryList } from "@/features/services/components/headCategories/HeadCategoryList";

export const HeadCategoriesView = () => {
  const { t } = useTranslation();
  const { isLg } = useBreakpoint("lg");
  const isMatch = useMatch("/services");

  return (
    <Layout title={t("sidebar.navigation.mySalon.children.servicesAndPrices")}>
      <div className="absolute inset-2 grid grid-rows-[auto_1fr] gap-4 lg:inset-4">
        <Subheader title={t("sidebar.navigation.mySalon.children.servicesAndPrices")} />
        <div className="grid h-full grid-cols-1 gap-2 overflow-hidden lg:grid-cols-3">
          {(isLg || isMatch) && <HeadCategoryList />}
          {isMatch && isLg && (
            <>
              <div className="flex w-full justify-center py-10 text-gray-500">
                {t("services.headCategories.selectCTA")}
              </div>
              <div className="flex w-full justify-center py-10 text-gray-500">
                {t("services.categories.selectCTA")}
              </div>
            </>
          )}
          <Outlet />
        </div>
      </div>
    </Layout>
  );
};

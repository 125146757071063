import { useTranslation } from "react-i18next";

import { CheckIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { useSessionStore } from "@/features/session/hooks";
import { Client } from "@/types";

import { ClientOfDirectory } from "../hooks/use-clients-store";
import { useClientsContext } from "../providers/ClientsProvider";

type ClientsDirectoryProps = {
  clientsDirectory: Record<string, ClientOfDirectory[]>;
  handleClientSelect: (client: Client) => void;
  selectedClient: Client | undefined;
  hasMore: boolean;
  loadingFetchMore: boolean;
  fetchMore: (limit?: number) => void;
};

export const ClientsDirectory = ({
  clientsDirectory,
  handleClientSelect,
  hasMore,
  loadingFetchMore,
  fetchMore,
  selectedClient,
}: ClientsDirectoryProps) => {
  const { t } = useTranslation();
  const { permissions } = useSessionStore();

  const {
    toggleClientSelection,
    selectedClients,
    count: totalClients,
    clientsStore,
  } = useClientsContext();
  const { clientsCount } = clientsStore;

  const clientsLeftCount = totalClients - clientsCount;

  return (
    <nav className="h-full overflow-y-auto bg-white" aria-label="Directory">
      {clientsDirectory && (
        <>
          {Object.keys(clientsDirectory).map((letter) => (
            <div key={letter} className="relative">
              <div className="sticky top-0 z-10 border-y bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                <h3>{letter}</h3>
              </div>
              <ul role="list" className="relative z-0 divide-y divide-gray-200">
                {clientsDirectory[letter].map((person) => {
                  const isSelectable = person.phone;
                  const isSelected =
                    selectedClients.hasOwnProperty(person.uuid) && selectedClients[person.uuid];

                  return (
                    <li
                      key={person.uuid}
                      className={clsx(
                        "relative flex items-center gap-4 px-6 py-2 py-4 hover:bg-gray-100",
                        person.uuid === selectedClient?.uuid && "bg-gray-200/50",
                      )}>
                      <div
                        onClick={() => isSelectable && toggleClientSelection(person.uuid)}
                        className={clsx(
                          isSelectable
                            ? isSelected
                              ? "cursor-pointer border-violet-600 bg-violet-500 text-white"
                              : "cursor-pointer border-gray-400 bg-white hover:border-gray-500"
                            : "cursor-not-allowed border-gray-300 bg-gray-50",
                          "z-10 h-5 w-5 rounded border p-[1px] shadow-sm transition-colors",
                        )}>
                        {isSelected && isSelectable && <CheckIcon />}
                      </div>
                      <div className="min-w-0 flex-1">
                        <a
                          href="#"
                          onClick={() => handleClientSelect(person)}
                          className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span className="absolute inset-0" aria-hidden="true" />
                          <p className="text-sm font-medium text-gray-900">{person.fullName}</p>
                          {person.phone && permissions.client_details_view && (
                            <p className="mt-1 truncate text-sm font-light text-gray-500">
                              {person.phone}
                            </p>
                          )}
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
          {hasMore && !loadingFetchMore && (
            <div className="flex flex-wrap justify-center gap-4 bg-gray-50 py-8">
              <Button
                onClick={() => fetchMore()}
                variant="primary"
                size="small"
                className="mx-6 w-full">
                {t("actions.loadMore", {
                  count: 20 > clientsLeftCount ? clientsLeftCount : 20,
                })}
              </Button>
              {clientsLeftCount > 500 ? (
                <Button
                  onClick={() => fetchMore(500)}
                  variant="primary-outline"
                  size="small"
                  className="mx-6 w-full">
                  {t("actions.loadMore", {
                    count: 500,
                  })}
                </Button>
              ) : (
                <Button
                  onClick={() => fetchMore(clientsLeftCount)}
                  variant="primary-outline"
                  size="small"
                  className="mx-6 w-full">
                  {t("actions.loadAll", {
                    count: clientsLeftCount,
                  })}
                </Button>
              )}
            </div>
          )}
          {loadingFetchMore && (
            <div className="flex justify-center py-4">
              <Spinner className="h-8 w-8" />
            </div>
          )}
        </>
      )}
    </nav>
  );
};

import { ReactNode, cloneElement, useMemo, useState, useRef } from "react";
import { mergeRefs } from "react-merge-refs";

import { Transition } from "@headlessui/react";

import {
  Placement,
  offset,
  shift,
  arrow,
  autoUpdate,
  autoPlacement,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from "@floating-ui/react-dom-interactions";

const mapPlacementSideToCSSProperty = (placement: Placement): string => {
  const staticPosition = placement.split("-")[0];

  return {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[staticPosition] as string;
};

type TooltipProps = {
  content: string | ReactNode;
  children: JSX.Element;
  disabled?: boolean;
  href?: string;
};

export const Tooltip = ({ content, children, disabled, href }: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef<HTMLDivElement>(null);

  const middleware = [
    offset(8),
    shift({ padding: 8 }),
    autoPlacement(),
    arrow({ element: arrowRef }),
  ];

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement,
  } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware,
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { enabled: !disabled }),
    useFocus(context, { enabled: !disabled }),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  const ref = useMemo(() => mergeRefs([reference, (children as any).ref]), [reference, children]);
  const staticSide = useMemo(() => mapPlacementSideToCSSProperty(placement), [placement]);

  return (
    <>
      <a {...(href && !disabled && { href, className: "cursor-pointer" })}>
        {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      </a>
      <Transition
        appear={true}
        show={open}
        as="div"
        enter="transition ease-in-out duration-200"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in-out duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="z-50 rounded-md border border-gray-200 bg-white px-2 py-1 text-xs text-gray-700 shadow-sm"
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
        {...getFloatingProps()}>
        {content}
        <div
          ref={arrowRef}
          className="absolute h-2 w-2 rotate-45 bg-gray-200"
          style={{
            left: arrowX ?? 0,
            top: staticSide === "bottom" ? "unset" : arrowY ?? 0,
            [staticSide]: "-4px",
          }}></div>
      </Transition>
    </>
  );
};

import { forwardRef, ForwardedRef, InputHTMLAttributes } from "react";

import clsx from "clsx";

type TextAreaFieldProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  name: string;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  showLabel?: boolean;
};

export const TextAreaField = forwardRef(
  (
    {
      label,
      name,
      placeholder,
      className,
      errorMessage,
      showLabel = true,
      ...delegated
    }: TextAreaFieldProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const displayName = label || name;

    return (
      <div className={clsx("relative", showLabel && "space-y-1")}>
        <label
          htmlFor={name}
          className={clsx(showLabel ? "block text-xs text-gray-500" : "sr-only")}>
          {displayName}
        </label>
        <textarea
          ref={ref}
          name={name}
          id={name}
          className={clsx(
            "block h-28 w-full resize-none appearance-none rounded-md border border-gray-300 px-3 py-3 text-sm placeholder-gray-400 placeholder:font-light focus:border-violet-600 focus:outline-none focus:ring-violet-600 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400",
            className,
            {
              "border-red-600 focus:border-red-600 focus:outline-none focus:ring-red-600":
                !!errorMessage,
            },
          )}
          placeholder={placeholder}
          {...delegated}></textarea>
        <div>
          <span className="block text-xs text-red-600">{errorMessage && errorMessage}</span>
        </div>
      </div>
    );
  },
);

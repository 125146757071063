import { Fragment } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Dialog, Transition } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";
import { TextAreaField } from "@components/ui/TextAreaField";

import { useSendSmsStore } from "@/features/messages/hooks";
import { useToasts } from "@/providers/ToastsProvider";
import { Client } from "@/types";

import { countCharacters } from "../utils/countCharacters";
import { SMSCharacterCounter } from "./SMSCharacterCounter";

type SendSMSDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  client: Client;
};

type FormData = {
  message: string;
};

export const SendSMSDialog = ({ open, setOpen, client }: SendSMSDialogProps) => {
  const { t } = useTranslation();
  const { sendSmsToClients, loading } = useSendSmsStore();
  const { showToast } = useToasts();

  const {
    register,
    reset,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      message: "",
    },
  });

  const message = watch("message");

  const messageCharacters = countCharacters(message);

  const handleSendMessage: SubmitHandler<FormData> = async (data: FormData) => {
    const { errors } = await sendSmsToClients({
      message: data.message,
      clientsUuids: [client.uuid],
    });

    if (errors) {
      showToast({
        type: "error",
        title: t("messages.sendSmsDialogErrorTitle"),
        description: t("messages.sendSmsDialogErrorMessage"),
      });
      return;
    }

    if (!loading) {
      reset();

      setOpen(false);

      showToast({
        type: "success",
        title: t("messages.sendSmsDialogSuccessTitle"),
        description: t("messages.sendSmsDialogSuccessMessage", { client: client.fullName }),
      });
    }
  };

  const handleOnClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="m-0 flex min-h-full items-center justify-center text-center sm:mx-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative flex h-screen w-full max-w-xl transform flex-col justify-center overflow-hidden rounded-none bg-white px-4 py-5 text-left shadow-xl transition-all xs:h-fit xs:rounded-lg sm:my-8 sm:p-6 sm:pb-4 sm:pt-5">
                <div className="flex justify-between">
                  <p className="mb-6 text-lg text-gray-500">
                    {t("messages.sendSmsTo")} {client.fullName}
                  </p>
                  <nav
                    className="absolute right-5 top-5 xs:relative xs:right-0 xs:top-0"
                    aria-label="Breadcrumb">
                    <button
                      type="button"
                      onClick={() => handleOnClose()}
                      className="rounded-full border border-gray-300 p-1 text-gray-400 shadow-sm transition-colors hover:border-gray-400 hover:bg-gray-50 hover:text-gray-500">
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </nav>
                </div>
                <form onSubmit={handleSubmit(handleSendMessage)} noValidate>
                  <div>
                    <TextAreaField
                      {...register("message", { required: true, minLength: 3, maxLength: 10000 })}
                      label={t("messages.messageContent")}
                      errorMessage={
                        errors.message &&
                        t(`validation.${errors.message.type}`, {
                          name: t("messages.messageContent"),
                          minLength: 3,
                          maxLength: 10000,
                        })
                      }
                    />
                    <SMSCharacterCounter messageCharacters={messageCharacters} />
                  </div>
                  {messageCharacters.encoding === "UTF16" && (
                    <div className="mt-4">
                      <Alert
                        type="warning"
                        title={t("messages.specialCharacters")}
                        description={`${t(
                          "messages.specialCharactersInfo",
                        )} ${messageCharacters.shortenByChars.map((char, index) =>
                          messageCharacters.shortenByChars.length - 1 === index
                            ? ` ${char}`
                            : ` ${char}`,
                        )}.`}
                      />
                    </div>
                  )}
                  {messageCharacters.messages > 1 && (
                    <div className="mt-4">
                      <Alert
                        type="warning"
                        title={t("messages.messagesCount")}
                        description={t("messages.messagesCountInfo", {
                          count: messageCharacters.messages,
                        })}
                      />
                    </div>
                  )}
                  <Button type="submit" fullWidth className="mt-4" disabled={!isValid || loading}>
                    {t("generic.send")}
                  </Button>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import { useTranslation } from "react-i18next";

import { LifebuoyIcon } from "@heroicons/react/24/outline";

import { Button, ButtonProps } from "@components/ui/Button";

import { useDialog, bindDialogState } from "@hooks/use-dialog";

import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@features/account/components/SendFeedbackDialog";

import { sendEvent } from "@/utils/google-analytics";

export const SupportButton = (props?: Partial<ButtonProps>) => {
  const { t } = useTranslation();
  const feedbackDialogState = useDialog<SendFeedbackProps>();

  return (
    <>
      <Button
        variant="primary-inline"
        className="text-gray-500"
        {...(props || {})}
        startIcon={<LifebuoyIcon aria-hidden="true" />}
        onClick={() => {
          sendEvent("support_button", "contact", "footer");
          feedbackDialogState.open();
        }}>
        {t("footer.iNeedHelp")}
      </Button>
      <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
    </>
  );
};

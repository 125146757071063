import { useTranslation } from "react-i18next";

import { Spinner } from "@/components/ui/Spinner";
import { SMSTemplateForm } from "@/features/messages/components/SMSTemplateForm";
import { MESSAGES_VARIABLES } from "@/features/messages/constants/index";
import { useSalonSmsTemplatesStore } from "@/features/messages/hooks";
import { SubscriptionCTA } from "@/features/payments/components/SubscriptionCTA";
import { useProductsStore } from "@/features/payments/hooks/use-products-store";
import { useSessionStore } from "@/features/session/hooks/use-session-store";
import { sendEvent } from "@/utils/google-analytics";

export const SmsTemplates = () => {
  const { t } = useTranslation();
  const { templates, loading } = useSalonSmsTemplatesStore();
  const { isFreePlan, trialUsed } = useSessionStore();
  const { orderSalonPlan } = useProductsStore();

  const { appointmentDate, clientName, cancelLink, appointmentTime } = MESSAGES_VARIABLES;

  return (
    <div>
      {isFreePlan &&
        (trialUsed ? (
          <SubscriptionCTA
            heading={t("subscriptions.cta.messages.trial.heading")}
            description={t("subscriptions.cta.messages.upgrade.description")}
            buttonText={t("subscriptions.cta.generic.seeMore")}
            to="/subscriptions"
            onClick={() => sendEvent("upgrade_see_more", "payments", "sms_templates")}
          />
        ) : (
          <SubscriptionCTA
            heading={t("subscriptions.cta.messages.trial.heading")}
            description={t("subscriptions.cta.messages.trial.description")}
            buttonText={t("subscriptions.cta.generic.startTrial")}
            onClick={() => {
              sendEvent("free_trial_click", "payments", "sms_templates");
              orderSalonPlan();
            }}
          />
        ))}
      <div>
        {loading ? (
          <Spinner className="h-[25vh]" />
        ) : (
          <ul className="grid gap-2">
            {templates?.map(
              (template) =>
                template && (
                  <li key={template.uuid} className="block">
                    <SMSTemplateForm
                      template={template}
                      variables={[appointmentDate, clientName, appointmentTime].concat(
                        template.type !== "canceled" ? [cancelLink] : [],
                      )}
                    />
                  </li>
                ),
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const MAX_IMAGES_SIZE = 10000000; // 10MB

export const isNotWhitespace = (value: string | undefined | null) =>
  value ? !!value?.trim() : true;

// validate multiple images that are uploaded and cant be more than 30 mb no matter how many images are uploaded
export const validateImages = (files: FileList, usedSpace = 0): string[] => {
  const errors = [];
  if (!files || !files.length) return [];

  const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
  const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

  Array.from(files).forEach((file) => {
    if (!allowedTypes.includes(file.type)) {
      errors.push("errors.images.invalidFormat");
    }
  });

  if (totalSize + usedSpace > MAX_IMAGES_SIZE) {
    errors.push("errors.images.invalidSize");
  }

  return errors;
};

export const validateLogoImage = (file: FileList) => {
  // After upload, we get a string which from BE instead of FileList, so we don't want to show an error
  if (!file || !file[0] || typeof file === "string") return;

  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  const maxFileSize = 2000000; // 2MB

  if (!allowedTypes.includes(file[0].type)) {
    return "Only JPG and PNG image types are allowed";
  }

  if (file[0].size > maxFileSize) {
    return "File size is too large";
  }
};

export const validateCoverImage = (file: FileList) => {
  // After upload, we get a string which from BE instead of FileList, so we don't want to show an error
  if (!file || !file[0] || typeof file === "string") return;

  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  const maxFileSize = 10000000; // 10MB

  if (!allowedTypes.includes(file[0].type)) {
    return "Only JPG and PNG image types are allowed";
  }

  if (file[0].size > maxFileSize) {
    return "File size is too large";
  }
};

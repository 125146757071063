import { useTranslation } from "react-i18next";

import { PencilIcon } from "@heroicons/react/24/outline";

import { format, parseISO } from "date-fns";

import { APPOINTMENT_STATUSES_EXTERNAL_CLIENT } from "@/features/calendar/constants";
import { useCalendarStore } from "@/features/calendar/hooks/use-calendar-store";
import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import { convertCalendarItemsToSlotsFormat } from "@/features/calendar/utils/slots";
import { useToasts } from "@/providers/ToastsProvider";
import { AppointmentSubscriptionAction } from "@/types";

import { DATE_FORMAT } from "../constants/date-formats";

type NotificationProviderProps = {
  children: React.ReactNode;
};

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const { selectAppointment } = useCalendarContext();
  const { t } = useTranslation();
  const { showToast } = useToasts();

  useCalendarStore({
    onSubscription: (options) => {
      const { action, appointment } = options.data.data?.appointmentCreatedOrUpdated ?? {};

      const isCausedByExternalClient =
        appointment?.status &&
        (APPOINTMENT_STATUSES_EXTERNAL_CLIENT.includes(appointment.status) ||
          appointment.isSelfBooked);

      showToast({
        manualClose: isCausedByExternalClient,
        title: t(
          `realtime.action.${
            action === AppointmentSubscriptionAction.Create && appointment?.isSelfBooked
              ? "SCHEDULED_BY_CLIENT"
              : action
          }`,
        ),
        description: (
          <>
            {appointment?.clientName !== ""
              ? appointment?.clientName
              : t("appointments.anonymousClient")}{" "}
            &bull;{" "}
            {format(
              parseISO(appointment?.treatments[0].timeRange.from),
              DATE_FORMAT.DAY_MONTH_TIME,
            )}
          </>
        ),
        actionIcons: [
          <PencilIcon
            aria-label={t("generic.edit")}
            className="h-4 w-4 cursor-pointer"
            onClick={() =>
              appointment && selectAppointment(convertCalendarItemsToSlotsFormat([appointment])[0])
            }
          />,
        ],
      });
    },
  });

  return <>{children}</>;
};

import React, { useLayoutEffect, useState, useCallback, Fragment } from "react";

import { Transition } from "@headlessui/react";

import {
  SCALE_STEPS_PER_DAY,
  AUTOSCROLL_OFFSET,
  MINUTES_PER_DAY,
  NUMBER_OF_DAYS_IN_WEEK,
} from "@features/calendar/constants";
import { SlotRowsByEmployee } from "@features/calendar/models";
import clsx from "clsx";
import { differenceInMinutes, startOfDay } from "date-fns";

import { useCalendarContext } from "../../providers/CalendarProvider";
import { CalendarDaysNavigation } from "../CalendarDaysNavigation";
import { CalendarEmployeeHeader } from "../CalendarEmployeeHeader";
import { CalendarScaleBar } from "../CalendarScaleBar";
import { CalendarSlotsGroup } from "../CalendarSlotsGroup";
import { CalendarEmployeeWeekAvailability } from "./CalendarEmployeeWeekAvailability";
import { CalendarEmployeeWeekHeader } from "./CalendarEmployeeWeekHeader";
import { CalendarEmployeeWeekPlaceholder } from "./CalendarEmployeeWeekPlaceholder";

type CalendarEmployeeWeekContentProps = {
  slotGroups: SlotRowsByEmployee[];
};

export const CalendarEmployeeWeekContent = ({ slotGroups }: CalendarEmployeeWeekContentProps) => {
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(null);
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
  const [scaleElement, setScaleElement] = useState<HTMLElement | null>(null);
  const [indicatorOffset, setIndicatorOffset] = useState<number>(0);

  const { filters } = useCalendarContext();

  const containerRef = useCallback(
    (element: HTMLElement | null) => setContainerElement(element),
    [],
  );
  const headerRef = useCallback((element: HTMLElement | null) => setHeaderElement(element), []);
  const scaleRef = useCallback((element: HTMLElement | null) => setScaleElement(element), []);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      const currentPeriodOffset = new Date().getHours() * 60 - AUTOSCROLL_OFFSET || 0;

      if (containerElement) {
        const offset =
          ((containerElement.scrollHeight -
            (headerElement?.offsetHeight || 0) -
            ((scaleElement?.firstChild as HTMLDivElement)?.offsetHeight || 0)) *
            currentPeriodOffset) /
          1440;
        containerElement.scrollTo({ top: offset, behavior: "smooth" });
      }
    });
    if (containerElement) observer.observe(containerElement);

    const calcIndicatorOffset = () => {
      const containerHeight = scaleElement?.offsetHeight || 0;
      const offsetHeight = (scaleElement?.firstChild as HTMLElement)?.offsetHeight || 0;
      const height = containerHeight - offsetHeight;
      const pxPerMinute = height > 0 ? height / MINUTES_PER_DAY : 0;
      const currentMinute = differenceInMinutes(new Date(), startOfDay(new Date()));

      setIndicatorOffset(currentMinute * pxPerMinute);
    };

    let interval: number;

    if (scaleElement) {
      interval = window.setInterval(calcIndicatorOffset, 1000);
    }

    return () => {
      if (scaleElement) clearInterval(interval);
      if (containerElement) observer.unobserve(containerElement);
    };
  }, [containerElement, headerElement, scaleElement]);

  const hasMoreThanOneEmployee = slotGroups.length > 1 || filters.employeeUuid !== undefined;

  return (
    <div className="flex h-full flex-col">
      <div
        ref={containerRef}
        className="isolate flex flex-auto flex-col overflow-auto overscroll-none rounded-md border bg-white">
        <div className="flex w-fit min-w-fit flex-col 2xl:!w-full 2xl:max-w-full">
          {/* A dumb element to cover a small piece of scrollable header */}
          <div
            className={clsx(
              hasMoreThanOneEmployee ? "sm:top-[43px]" : "sm:top-0",
              "hidden sm:absolute sm:left-0 sm:z-50 sm:block sm:h-[42px] sm:w-[42px] sm:border sm:bg-white",
            )}></div>
          {hasMoreThanOneEmployee && <CalendarEmployeeHeader ref={headerRef} />}
          <CalendarEmployeeWeekHeader
            ref={headerRef}
            hasMoreThanOneEmployee={hasMoreThanOneEmployee}
          />
          <CalendarDaysNavigation ref={headerRef} />

          <div
            className={clsx(
              hasMoreThanOneEmployee ? "sm:pt-6" : "sm:pt-0",
              "flex flex-auto pb-0 pt-28",
            )}>
            <div className="sticky left-0 z-30 w-10 flex-none bg-white/60 ring-1 ring-gray-100 backdrop-blur-sm"></div>
            <div className="relative grid flex-auto grid-cols-1 grid-rows-1">
              <Transition
                show={indicatorOffset > 0}
                appear
                as={Fragment}
                enter="transition-[opacity,_right] duration-500 ease-in"
                enterFrom="opacity-0 right-full"
                enterTo="opacity-80 right-0"
                leave="transition-[opacity,_right] duration-500 ease-in"
                leaveFrom="opacity-80 right-0"
                leaveTo="opacity-0 right-full">
                <div
                  className="border-1 absolute left-0 z-30 mt-7 h-[2px] border border-white border-opacity-25 bg-red-500 before:absolute before:top-1/2 before:block before:h-2 before:w-2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:bg-red-500 before:content-['']"
                  style={{ top: `${indicatorOffset}px` }}></div>
              </Transition>
              <CalendarEmployeeWeekAvailability />
              <CalendarScaleBar ref={scaleRef} />
              <CalendarEmployeeWeekPlaceholder />

              <ol
                className="col-start-1 col-end-2 row-start-1 grid sm:pr-8"
                style={{
                  gridTemplateRows: `1.75rem repeat(${SCALE_STEPS_PER_DAY}, minmax(0, 1fr)) auto`,
                  gridTemplateColumns: `repeat(${NUMBER_OF_DAYS_IN_WEEK}, minmax(250px, 1fr))`,
                }}>
                {slotGroups.map((slotGroup: SlotRowsByEmployee) =>
                  slotGroup.slotRows.map((slotRow) => (
                    <CalendarSlotsGroup key={slotRow.from} group={slotRow} />
                  )),
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

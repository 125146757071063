import { cloneElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { Icon } from "@/models";

type DefintionListProps = {
  children: ReactNode;
};

type DefinitionTitleProps = {
  icon?: Icon;
  children: ReactNode;
};

type DefinitionDescriptionProps = {
  children: ReactNode;
};

type DefinitionEntryProps = {
  children: ReactNode;
};

export const DefinitionList = ({ children }: DefintionListProps) => {
  return <dl className="w-full sm:divide-y sm:divide-gray-200">{children}</dl>;
};

export const DefinitionTitle = ({ icon, children }: DefinitionTitleProps) => {
  return (
    <dt className="text-md flex items-start gap-2 font-medium text-gray-500 sm:col-span-2 md:text-sm 2xl:col-span-1">
      {icon &&
        cloneElement(icon, {
          className: "h-5 w-5 md:h-4 md:w-4",
        })}
      {children}
    </dt>
  );
};

export const DefinitionDescription = ({ children }: DefinitionDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <dd
      className={clsx(
        !children ? "text-gray-400" : "text-gray-900",
        "mt-1 break-words sm:col-span-2 sm:mt-0 md:text-sm 2xl:col-span-3",
      )}>
      {children ?? t("generic.noData")}
    </dd>
  );
};

export const DefinitionEntry = ({ children }: DefinitionEntryProps) => {
  return <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-2 sm:py-2 lg:gap-4">{children}</div>;
};

import { useEffect, useState } from "react";

import { SlotsAssignedToDays, DisplayedMonthDay } from "@features/calendar/models";

import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import { getDisplayedMonthDays } from "@/features/calendar/utils/time";

import { CalendarMonthHeader } from "../CalendarMonth/CalendarMonthHeader";
import { CalendarMonthCell } from "./CalendarMonthCell";

type CalendarMonthContentProps = {
  slotGroupsMap: SlotsAssignedToDays;
};

export const CalendarMonthContent = ({ slotGroupsMap }: CalendarMonthContentProps) => {
  const { selectedDate } = useCalendarContext();
  const [days, setDays] = useState<DisplayedMonthDay[]>([]);

  useEffect(() => {
    setDays(getDisplayedMonthDays(selectedDate, slotGroupsMap));
  }, [selectedDate, slotGroupsMap]);

  return (
    <div className="mx-0 h-full pb-20 sm:pb-0 lg:flex lg:flex-col">
      <div className="max-h-full overflow-auto border-y sm:rounded-md sm:border lg:flex lg:flex-auto lg:flex-col">
        <CalendarMonthHeader />

        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div
            className="hidden w-full lg:grid lg:grid-cols-7 lg:gap-px"
            style={{
              gridTemplateRows: `repeat(${days.length / 7}, minmax(9rem, 1fr))`,
            }}>
            {days.map((day) => (
              <CalendarMonthCell key={day.date.getTime()} day={day} />
            ))}
          </div>
          <div
            className="isolate grid w-full grid-cols-7 gap-px lg:hidden"
            style={{
              gridTemplateRows: `repeat(${days.length / 7}, minmax(0, 1fr))`,
            }}>
            {days.map((day) => (
              <CalendarMonthCell key={day.date.getTime()} day={day} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

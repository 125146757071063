import React from "react";

import clsx from "clsx";
import { format, eachDayOfInterval, isToday } from "date-fns";

import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";

export const CalendarWeekDaysHeader = () => {
  const { timeRange } = useCalendarContext();

  const days = eachDayOfInterval({ start: timeRange.from, end: timeRange.to });

  return (
    <div
      className="sticky top-0 z-30 -mt-[1px] grid gap-px bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 shadow lg:flex-none"
      style={{ gridTemplateColumns: "repeat(7, minmax(250px, 1fr))" }}>
      {days.map((day, index) => (
        <div key={index} className="flex items-center justify-center bg-white py-2">
          <span className="flex items-baseline gap-1.5">
            {format(day, "EEE")}{" "}
            <span
              className={clsx("items-center justify-center font-semibold text-gray-900", {
                "flex h-6 w-6 rounded-md bg-violet-200 text-violet-700": isToday(day),
              })}>
              {format(day, "dd")}
            </span>
          </span>
        </div>
      ))}
    </div>
  );
};

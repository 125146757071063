import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ChevronDownIcon, InboxArrowDownIcon, PencilIcon } from "@heroicons/react/24/outline";

import { format, parseISO } from "date-fns";

import { useSalonSmsDetailsStore } from "@features/messages/hooks/use-salon-sms-details-store";

import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { DATE_FORMAT } from "@/constants/date-formats";
import { SMS_STATUS_LABELS_MAP } from "@/features/clients/constants";
import { useSentSmsStore } from "@/features/messages/hooks";
import { Sms } from "@/types";

type ClientSmsProps = {
  clientUuid: string;
};

type SmsProps = {
  sms: Sms;
};

export const ClientSms = ({ clientUuid }: ClientSmsProps) => {
  const { t } = useTranslation();

  const { sentSms, fetchMore, loading, hasMore } = useSentSmsStore(clientUuid);

  return (
    <section className="grid">
      <SmsHeader />
      <div className="py-4">
        {loading ? (
          <Spinner />
        ) : sentSms.length > 0 ? (
          <ol className="grid gap-4">
            {sentSms.map((sms) => (
              <SmsBubble key={sms.uuid} sms={sms} />
            ))}
          </ol>
        ) : (
          <div className="grid justify-items-center gap-2 text-gray-400">
            <InboxArrowDownIcon className="h-8 w-8" />
            <p>{t("sms.noSmsSent")}</p>
          </div>
        )}
      </div>
      {hasMore && (
        <Button
          onClick={fetchMore}
          className="m-auto w-full py-2 text-gray-500"
          variant="primary-inline"
          fullWidth
          startIcon={<ChevronDownIcon />}>
          {t("generic.showMore")}
        </Button>
      )}
    </section>
  );
};

const SmsBubble = ({ sms }: SmsProps) => {
  const { t } = useTranslation();

  return (
    <li className="grid gap-2 rounded-md border p-4 text-gray-600">
      <p>{sms.message}</p>

      <div className="flex justify-between">
        <b className="text-sm">{t(SMS_STATUS_LABELS_MAP[sms.status])}</b>
        {sms.sentAt && (
          <time className="text-sm text-gray-400" dateTime={sms.sentAt}>
            {format(parseISO(sms.sentAt), DATE_FORMAT.DAY_MONTH_YEAR_TIME)}
          </time>
        )}
      </div>
    </li>
  );
};

const SmsHeader = () => {
  const { t } = useTranslation();

  const { salonSmsDetails } = useSalonSmsDetailsStore();

  return (
    <Link to="/settings?tab=smsSending" className="w-full border-b py-2">
      <h3 className="group inline-flex items-center gap-1 text-lg font-medium text-gray-500">
        <span className="sr-only">{t("onboarding.salonSmsDetails.title")}</span>
        {salonSmsDetails?.senderName}
        <PencilIcon className="h-4 w-4 opacity-0 duration-300 ease-in-out group-hover:opacity-100" />
      </h3>
    </Link>
  );
};

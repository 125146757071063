import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ClockIcon,
  PlusCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  DevicePhoneMobileIcon,
  FunnelIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { UserIcon } from "@heroicons/react/24/solid";

import { Button } from "@components/ui/Button";
import { Popover } from "@components/ui/Popover";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useSessionStore, useCountersStore } from "@features/session/hooks";

import { sendEvent } from "@/utils/google-analytics";

import { EMPLOYEE_CALENDAR_VIEWS } from "../constants";
import { CalendarViews } from "../models";
import { useCalendarContext } from "../providers/CalendarProvider";
import { getRangeLabel } from "../utils/labels";

type ViewOption = {
  value: CalendarViews;
  label?: string;
  hiddenForSm?: boolean;
  visibleForSm?: boolean;
  active: CalendarViews[];
  employeeView?: CalendarViews[];
  icon?: React.ReactNode;
  id: number;
};

const viewOptions: ViewOption[] = [
  {
    value: CalendarViews.Week,
    active: [
      CalendarViews.EmployeeWeek,
      CalendarViews.Week,
      CalendarViews.Day,
      CalendarViews.EmployeeDay,
    ],
    visibleForSm: true,
    employeeView: [CalendarViews.EmployeeWeek, CalendarViews.EmployeeDay],
    icon: <DevicePhoneMobileIcon className="h-4 w-4" />,
    id: 1,
  },
  {
    value: CalendarViews.Day,
    label: "appointments.viewOptions.day",
    active: [CalendarViews.Day, CalendarViews.EmployeeDay],
    hiddenForSm: true,
    employeeView: [CalendarViews.EmployeeDay],
    id: 2,
  },
  {
    value: CalendarViews.Week,
    hiddenForSm: true,
    label: "appointments.viewOptions.week",
    active: [CalendarViews.Week, CalendarViews.EmployeeWeek],
    employeeView: [CalendarViews.EmployeeWeek],
    id: 3,
  },
  {
    value: CalendarViews.Month,
    label: "appointments.viewOptions.month",
    active: [CalendarViews.Month],
    icon: <CalendarIcon className="h-4 w-4" />,
    id: 4,
  },
];

export const CalendarSubheader = () => {
  const { isFreePlan, permissions } = useSessionStore();
  const { remainingAppointments, loadingRemainingAppointments } = useCountersStore();
  const { isXl } = useBreakpoint("xl");
  const { isSm } = useBreakpoint("sm");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    view,
    setView,
    timeRange,
    nextRange,
    prevRange,
    nextWeek,
    prevWeek,
    addNewAppointment,
    showFilters,
    setFilters,
    addNewBlockedSlot,
  } = useCalendarContext();

  const handleViewOptionChange = (option: ViewOption) => {
    const isActiveView = option.active.includes(view);
    const isEmployeeView = EMPLOYEE_CALENDAR_VIEWS.includes(view);

    if (isActiveView && option.value !== view) {
      setFilters({ employeeUuid: undefined });
    }

    if (isEmployeeView && option.employeeView && !option.employeeView.includes(view)) {
      if (option.visibleForSm) {
        return setView(CalendarViews.EmployeeWeek);
      }

      return setView(option.employeeView[0]);
    }

    if (option.value === CalendarViews.Month) {
      setFilters({ employeeUuid: undefined });
    }

    setView(option.value);
  };

  const isAbleToAddAppointment = useMemo(
    () => !isFreePlan || remainingAppointments > 0,
    [isFreePlan, remainingAppointments],
  );

  return (
    <>
      <div className="inline-flex flex-wrap items-center justify-between gap-2 sm:justify-start">
        <div className="order-2 flex items-center justify-center gap-2 sm:order-1 sm:mr-auto">
          <Button
            variant="secondary-outline"
            className="p-1.5 sm:p-2"
            onClick={isSm ? prevRange : prevWeek}>
            <ArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="secondary-outline"
            className="p-1.5 sm:p-2"
            onClick={isSm ? nextRange : nextWeek}>
            <ArrowRightIcon className="h-4 w-4" />
          </Button>
          {isSm && (
            <time
              className={
                "text-sm font-light text-gray-500 first-letter:capitalize sm:ml-2 sm:text-base"
              }>
              {getRangeLabel(timeRange, view)}
            </time>
          )}
        </div>
        {isSm && (
          <Popover
            disabled={loadingRemainingAppointments || isAbleToAddAppointment}
            render={({ close }) => (
              <div className="max-w-sm">
                <h5>{t("appointments.reachedLimit")}</h5>
                <Button
                  variant="neutral"
                  fullWidth
                  className="mt-4 inline-flex w-full items-center justify-center rounded-sm border border-transparent bg-green-500 px-3 py-2 text-sm uppercase leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 md:w-auto"
                  endIcon={<ArrowRightIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />}
                  onClick={() => {
                    sendEvent("upgrade_cta", "payments", "calendar_subheader");
                    close();
                    navigate("/subscriptions");
                  }}>
                  {t("header.salonMenu.removeLimits")}
                </Button>
              </div>
            )}>
            <div className="flex w-full justify-center gap-2 sm:w-auto">
              <Button
                variant="primary"
                size="small"
                startIcon={<PlusCircleIcon />}
                disabled={loadingRemainingAppointments || !permissions.add_appointment}
                onClick={isAbleToAddAppointment ? () => addNewAppointment() : undefined}>
                {isXl ? (
                  <span>{t("appointments.addNew")}</span>
                ) : (
                  <span>{t("generic.appointment")}</span>
                )}
              </Button>
              <Button
                variant="secondary-outline"
                size="small"
                startIcon={<ClockIcon />}
                disabled={loadingRemainingAppointments || !permissions.add_slot_block}
                onClick={addNewBlockedSlot}>
                {isXl ? (
                  <span>{t("blockedSlots.blockSlot")}</span>
                ) : (
                  <span>{t("blockedSlots.blockedShort")}</span>
                )}
              </Button>
            </div>
          </Popover>
        )}
        <div className="order-2 flex gap-2 sm:w-auto">
          {isXl || (
            <Button variant="secondary-outline" className="px-2" size="small" onClick={showFilters}>
              <FunnelIcon className="h-4 w-4" />
            </Button>
          )}
          {viewOptions
            .filter(
              ({ hiddenForSm, visibleForSm }) => (!hiddenForSm || isSm) && (!visibleForSm || !isSm),
            )
            .map((option) => (
              <div key={option.id} className="relative flex">
                <Button
                  size="small"
                  variant={option.active.includes(view) ? "secondary" : "secondary-outline"}
                  className="px-2"
                  onClick={handleViewOptionChange.bind(null, option)}>
                  {!isSm && option.icon && <span>{option.icon}</span>}
                  {isSm && option.label && t(option.label)}
                  {option.employeeView && option.employeeView.includes(view) && (
                    <div className="absolute -right-0.5 -top-1.5 flex h-4 w-4 items-center justify-center rounded-full border border-yellow-700 bg-yellow-400">
                      <UserIcon className="h-3 w-3 text-yellow-700" />
                    </div>
                  )}
                </Button>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import { BuildingStorefrontIcon, ArrowSmallRightIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";

import { useSalonStore } from "@features/salon/hooks";
import { useSessionStore } from "@features/session/hooks";

import { Badge } from "@/components/ui/Badge";
import { sendEvent } from "@/utils/google-analytics";

import { HeaderAvatar } from "./HeaderAvatar";

export const SalonDropdown = () => {
  const { t } = useTranslation();
  const { session, salonPlan, isFreePlan, isOwner } = useSessionStore();
  const { salon } = useSalonStore();

  return session ? (
    <Menu as="div" className="relative inline-block text-left">
      {isOwner && isFreePlan ? (
        <div>
          <Menu.Button
            className="inline-flex w-full items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            disabled={!isOwner}>
            <div className="flex items-center  gap-2">
              {salon?.logoImageUrl ? (
                <img
                  id="logo-preview"
                  className="h-7 w-7 rounded-full"
                  crossOrigin="anonymous"
                  alt=""
                  src={salon?.logoImageUrl}
                />
              ) : (
                <HeaderAvatar icon={<BuildingStorefrontIcon />} />
              )}
              <div className="flex gap-2">
                <span>{session.salonName}</span>
                <Badge
                  className={
                    isFreePlan ? "bg-violet-500 text-white" : "bg-yellow-500 text-yellow-100"
                  }
                  uppercase>
                  {t(`subscriptions.plan.${salonPlan?.toLowerCase()}.title`)}
                </Badge>
              </div>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-300 focus:outline-none">
              <Menu.Item>
                <div className="rounded-t-md bg-gray-100 py-2 pl-6">
                  <span className="text-sm font-medium uppercase text-gray-500">
                    {t(`header.salonMenu.${salonPlan?.toLowerCase()}Plan`)}
                  </span>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div className="mx-6 py-2">
                  <div className="flex flex-col text-sm text-gray-500">
                    <span>{t("header.salonMenu.appointmentsLimit")}:&nbsp;</span>
                    <b className="text-medium whitespace-nowrap">
                      {t("generic.somethingPerMonth", [50])}
                    </b>
                  </div>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div className="mx-6 my-4">
                  <Button
                    variant="neutral"
                    fullWidth
                    className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-500 px-3 py-2 text-sm uppercase leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    endIcon={<ArrowSmallRightIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />}
                    onClick={() => {
                      sendEvent("upgrade_cta", "payments", "salon_dropdown");
                    }}
                    to="/subscriptions">
                    {t("header.salonMenu.removeLimits")}
                  </Button>
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </div>
      ) : (
        <Button
          className="inline-flex w-full items-center justify-center gap-2 rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          to="/salon"
          variant="neutral">
          {salon?.logoImageUrl ? (
            <img
              id="logo-preview"
              className="h-7 w-7 rounded-full"
              crossOrigin="anonymous"
              alt=""
              src={salon?.logoImageUrl}
            />
          ) : (
            <HeaderAvatar icon={<BuildingStorefrontIcon />} />
          )}
          <div className="flex gap-2">
            <span>{session.salonName}</span>
            <Badge
              className={isFreePlan ? "bg-violet-500 text-white" : "bg-yellow-500 text-yellow-100"}
              uppercase>
              {t(`subscriptions.plan.${salonPlan?.toLowerCase()}.title`)}
            </Badge>
          </div>
        </Button>
      )}
    </Menu>
  ) : (
    <></>
  );
};

import {
  LinkIcon,
  EnvelopeIcon,
  BuildingStorefrontIcon,
  PhoneIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";

import clsx from "clsx";

import { Spinner } from "@/components/ui/Spinner";
import { useSalonStore } from "@/features/salon/hooks";
import { prependHttps } from "@/utils/links";

export const SalonProfile = () => {
  const { salon, loading } = useSalonStore();

  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!salon) {
    return null;
  }

  return (
    <div className="mx-auto w-full">
      <div>
        <div>
          {salon.backgroundImageUrl ? (
            <img
              crossOrigin="anonymous"
              className="aspect-[3/1] min-h-[200px] w-full rounded-xl object-cover"
              src={salon.backgroundImageUrl}
              alt=""
            />
          ) : null}
        </div>
        <div
          className={clsx("flex flex-col items-center sm:flex-row", {
            "-mt-12 sm:mt-4": salon.backgroundImageUrl,
          })}>
          <div className="mb-4 flex-shrink-0 sm:mb-0">
            {salon.logoImageUrl ? (
              <img
                crossOrigin="anonymous"
                className="h-24 w-24 rounded-full border-2 border-white sm:h-32 sm:w-32 sm:border-none"
                src={salon.logoImageUrl}
                alt=""
              />
            ) : (
              <div className="flex h-24 w-24 items-center justify-center rounded-full border-2 border-white bg-gray-100 sm:h-32 sm:w-32 sm:border-none">
                <PhotoIcon className="h-10 w-10 text-gray-300" />
              </div>
            )}
          </div>
          <div className="sm:ml-4">
            <h2 className="text-3xl font-semibold text-gray-700 sm:text-4xl">{salon.name}</h2>
            {salon.street || salon.streetNumber || salon.postalCode || salon.city ? (
              <div className="mt-2 flex items-center gap-2 sm:mt-4">
                <BuildingStorefrontIcon className="-mr-1 h-5 w-5 flex-shrink-0 text-gray-500" />
                <p className="text-sm text-gray-600">
                  {salon.street} {salon.streetNumber}, {salon.postalCode} {salon.city}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div>
        {salon.description && (
          <>
            <div className="my-4 border-t border-gray-200" />
            <p className="w-full text-sm text-gray-600 sm:w-3/4 lg:w-1/2">{salon.description}</p>
          </>
        )}
      </div>
      {(salon.phone || salon.email || salon.socials?.facebook || salon.socials?.instagram) && (
        <div className="my-4 border-t border-gray-200" />
      )}
      {salon.phone && (
        <div className="mt-2 flex items-center gap-2">
          <PhoneIcon className="h-5 w-5 text-gray-500" />
          <a href={`tel:${salon.phone}`} className="text-sm text-violet-700">
            {salon.phone}
          </a>
        </div>
      )}
      {salon.email && (
        <div className="mt-2 flex items-center gap-2">
          <EnvelopeIcon className="mt-0.5 h-5 w-5 text-gray-500" />
          <a href={`mailto:${salon.email}`} className="text-sm text-violet-700">
            {salon.email}
          </a>
        </div>
      )}
      {salon.socials?.facebook && (
        <div className="mt-2 flex items-center gap-2">
          <LinkIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
          <a
            href={prependHttps(salon.socials?.facebook)}
            target="_blank"
            rel="noreferrer"
            className="break-all text-sm text-violet-700">
            {prependHttps(salon.socials?.facebook)}
          </a>
        </div>
      )}
      {salon.socials?.instagram && (
        <div className="mt-2 flex items-center gap-2">
          <LinkIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
          <a
            href={prependHttps(salon.socials?.instagram)}
            target="_blank"
            rel="noreferrer"
            className=" break-all text-sm text-violet-700">
            {prependHttps(salon.socials?.instagram)}
          </a>
        </div>
      )}
    </div>
  );
};

import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ConfirmationDialog } from "@components/dialogs/ConfirmationDialog";

import { useConfirmationDialog } from "@hooks/use-confirmation-dialog";
import { useDialog, bindDialogState } from "@hooks/use-dialog";

import { useTreatmentsStore, useTreatmentStore } from "@features/services/hooks";

import { Treatment } from "@/types";

import { ServiceEntitiesHeader } from "../ServiceEntitiesHeader";
import { TreatmentEntity } from "./TreatmentEntity";
import { TreatmentFormDialog, TreatmentFormProps } from "./TreatmentFormDialog";
import { TreatmentListWrapper } from "./TreatmentListWrapper";

export const TreatmentList = () => {
  const { t } = useTranslation();
  const { headCategoryUuid, categoryUuid } = useParams();
  const { updateTreatmentsPositions, treatments } = useTreatmentsStore(categoryUuid);
  const { deleteTreatment } = useTreatmentStore();
  const dialogState = useDialog<TreatmentFormProps>();
  const confirmationDialogState = useConfirmationDialog();
  const [isDraggable, setIsDraggable] = useState(false);
  const [tempTreatments, setTempTreatments] = useState<Treatment[]>([]);

  const handleTreatmentAdd = () => categoryUuid && dialogState.open({ categoryUuid });

  const handleTreatmentEdit = (treatment: Treatment) =>
    categoryUuid && dialogState.open({ categoryUuid, treatment, onRemove: handleTreatmentRemove });

  const handleTreatmentRemove: (treatment: Treatment) => Promise<boolean> = ({ uuid, name }) =>
    new Promise((resolve) =>
      confirmationDialogState.open({
        title: t("services.treatments.delete"),
        message: t("services.treatments.deleteWarning", { name }),
        onConfirm: async () => {
          await deleteTreatment({ treatmentUuid: uuid });
          resolve(true);
        },
        onDeny: () => resolve(false),
      }),
    );

  const handleSaveOrder = useCallback(() => {
    if (tempTreatments.length > 0 && categoryUuid) {
      const data = {
        categoryUuid,
        sortedTreatmentUuids: tempTreatments.map((treatment) => treatment.uuid),
      };

      updateTreatmentsPositions({ data: data });
    }

    setIsDraggable(false);
  }, [tempTreatments, categoryUuid, updateTreatmentsPositions]);

  const handleOrder = useCallback(() => {
    setIsDraggable((prev) => !prev);

    if (isDraggable) {
      setTempTreatments(treatments);
    } else {
      setTempTreatments([]);
    }
  }, []);

  return (
    <div className="overflow-auto">
      <ServiceEntitiesHeader
        title={t("services.treatments.title")}
        backUrl={`/services/${headCategoryUuid}`}
        handleAdd={handleTreatmentAdd}
        handleOrder={handleOrder}
        isDraggable={isDraggable}
        isDnDVisible={treatments.length > 0}
        handleSaveOrder={handleSaveOrder}
      />
      <TreatmentListWrapper
        isDraggable={isDraggable}
        noResultText={t("services.treatments.noResults")}
        renderTreatment={(treatment: Treatment) => <TreatmentEntity entity={treatment} />}
        onEdit={handleTreatmentEdit}
        onRemove={handleTreatmentRemove}
        categoryUuid={categoryUuid}
        treatments={
          isDraggable ? (tempTreatments.length > 0 && tempTreatments) || treatments : treatments
        }
        setTempTreatments={setTempTreatments}
      />
      <TreatmentFormDialog {...bindDialogState(dialogState)} />
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </div>
  );
};

import { Fragment } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Dialog, Transition } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";
import { TextAreaField } from "@components/ui/TextAreaField";

import { SMSCharacterCounter } from "@/features/messages/components/SMSCharacterCounter";
import { useSendSmsStore } from "@/features/messages/hooks";
import { countCharacters } from "@/features/messages/utils/countCharacters";
import { useCountersStore } from "@/features/session/hooks";
import { useToasts } from "@/providers/ToastsProvider";

import { useClientsContext } from "../providers/ClientsProvider";

type ClientBulkSMSDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

type FormData = {
  message: string;
};

export const ClientBulkSMSDialog = ({ open, setOpen }: ClientBulkSMSDialogProps) => {
  const { t } = useTranslation();
  const { sendSmsToClients, loading } = useSendSmsStore();
  const { showToast } = useToasts();
  const { selectedClients, deselectAllClients } = useClientsContext();
  const { smsLimit } = useCountersStore();

  const {
    register,
    reset,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      message: "",
    },
  });

  const message = watch("message");

  const messageCharacters = countCharacters(message);

  const selectedClientsFiltered = Object.entries(selectedClients).reduce<string[]>(
    (filteredUuids, [uuid, isSelected]) => {
      if (isSelected) {
        filteredUuids.push(uuid);
      }
      return filteredUuids;
    },
    [],
  );

  const clientsCount = selectedClientsFiltered.length;

  const handleSendMessage: SubmitHandler<FormData> = async (data: FormData) => {
    const { errors } = await sendSmsToClients({
      message: data.message,
      clientsUuids: selectedClientsFiltered,
    });

    if (errors) {
      showToast({
        type: "error",
        title: t("messages.sendSmsDialogErrorTitle"),
        description: t("messages.sendSmsDialogErrorMessage"),
      });
      return;
    }

    if (!loading) {
      reset();
      deselectAllClients();
      setOpen(false);

      showToast({
        type: "success",
        title: t("messages.sendSmsDialogSuccessTitle"),
        description: t("messages.sendBulkSmsDialogSuccessMessage"),
      });
    }
  };

  const handleOnClose = () => {
    reset();
    setOpen(false);
  };

  const dialogHeader = t("messages.sendSmsToNumberOfClients", {
    count: clientsCount,
  });

  const totalMessagesCount = messageCharacters.messages * clientsCount;
  const smsLeftAfterSend = smsLimit - totalMessagesCount;

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="fixed inset-0 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center xs:p-4">
              <Dialog.Panel className="mx-auto w-full max-w-xl bg-white p-4 xs:rounded-md">
                <Dialog.Title as="div" className="mb-4 flex items-center justify-between gap-8">
                  <h2 className="text-gray-600 sm:text-lg">{dialogHeader}</h2>
                  <button
                    type="button"
                    onClick={() => handleOnClose()}
                    className="transition-color mt-1 self-start text-gray-600 hover:bg-gray-50 hover:text-gray-700">
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </Dialog.Title>
                <Dialog.Description as="div">
                  <form onSubmit={handleSubmit(handleSendMessage)} noValidate>
                    <div>
                      <TextAreaField
                        {...register("message", { required: true, minLength: 3, maxLength: 10000 })}
                        label={t("messages.messageContent")}
                        className="text-sm"
                        errorMessage={
                          errors.message &&
                          t(`validation.${errors.message.type}`, {
                            name: t("messages.messageContent"),
                            minLength: 3,
                            maxLength: 10000,
                          })
                        }
                      />
                      <SMSCharacterCounter messageCharacters={messageCharacters} />
                    </div>

                    {messageCharacters.encoding === "UTF16" && (
                      <div className="mt-4">
                        <Alert
                          type="warning"
                          title={t("messages.specialCharacters")}
                          description={`${t(
                            "messages.specialCharactersInfo",
                          )} ${messageCharacters.shortenByChars.map((char, index) =>
                            messageCharacters.shortenByChars.length - 1 === index
                              ? ` ${char}`
                              : ` ${char}`,
                          )}.`}
                        />
                      </div>
                    )}

                    {messageCharacters.messages > 1 && (
                      <div className="mt-4">
                        <Alert
                          type="warning"
                          title={t("messages.messagesCount")}
                          description={t("messages.bulkMessagesCountInfo", {
                            count: messageCharacters.messages,
                          })}
                        />
                      </div>
                    )}

                    {messageCharacters.messages > 0 && smsLeftAfterSend >= 0 && (
                      <div className="mt-4">
                        <Alert type="info" title={t("messages.bulkTotalMessagesCount")}>
                          <div className="gap-0">
                            <p>
                              {t("messages.bulkTotalClientsCountInfo", {
                                count: clientsCount,
                              })}{" "}
                              {t("messages.bulkTotalMessagesCountInfo", {
                                count: totalMessagesCount,
                              })}{" "}
                              {t("messages.bulkSmsLeftAfterSendInfo", {
                                count: smsLeftAfterSend,
                              })}
                            </p>
                          </div>
                        </Alert>
                      </div>
                    )}

                    {smsLeftAfterSend < 0 && (
                      <div className="mt-4">
                        <Alert type="error" title={t("messages.bulkSmsLimitExceededTitle")}>
                          <div className="gap-0">
                            <p>
                              {t("messages.bulkTotalClientsCountInfo", {
                                count: clientsCount,
                              })}{" "}
                              {t("messages.bulkTotalMessagesCountInfo", {
                                count: totalMessagesCount,
                              })}{" "}
                              {t("messages.bulkSmsLimitExceededInfo", {
                                count: smsLimit,
                              })}
                            </p>
                          </div>
                        </Alert>
                      </div>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      className="mt-4"
                      disabled={!isValid || loading || smsLeftAfterSend < 0}>
                      {t("generic.send")}
                    </Button>
                  </form>
                </Dialog.Description>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

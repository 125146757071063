import { namedOperations } from "@/types";

import { useUpdateCategoriesPositionsMutation } from "../mutations/UpdateCategoriesPositions.generated";

export const useCategoriesStore = () => {
  const [updateCategoriesPositions, { loading: loadingUpdate }] =
    useUpdateCategoriesPositionsMutation();

  return {
    loadingUpdate: loadingUpdate,
    updateCategoriesPositions({
      data,
    }: {
      data: { headCategoryUuid: string; sortedCategoryUuids: string[] };
    }) {
      return updateCategoriesPositions({
        variables: {
          data,
        },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
        update(cache) {
          cache.evict({ fieldName: "fetchHeadCategories" });
          cache.gc();
        },
      });
    },
  };
};

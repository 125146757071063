import { ClientTags } from "@/features/clients/components/ClientTags";
import { useSessionStore } from "@/features/session/hooks";
import { Client } from "@/types";

import { AppointmentClientMenu } from "./AppointmentClientMenu";

type AppointmentClientNameProps = {
  client: Client;
  onClose: () => void;
};

export const AppointmentClientName = ({ client, onClose }: AppointmentClientNameProps) => {
  const { permissions } = useSessionStore();

  const shouldShowMenu =
    permissions.client_details_view || permissions.send_sms || permissions.edit_client;

  return (
    <div className="text-2xl font-bold text-gray-600">
      {client?.tags && client.tags.length > 0 && <ClientTags tags={client.tags} />}
      <div className="flex items-center justify-between">
        <span className="mt-1 inline-flex">{client?.fullName}</span>
        {client && shouldShowMenu && <AppointmentClientMenu client={client} onClose={onClose} />}
      </div>
    </div>
  );
};

import useCookie from "react-use-cookie";

import { useSessionStore } from "@/features/session/hooks";

export const useAnalyticsStore = () => {
  const { session } = useSessionStore();

  /* Cookies are set to "granted" by default, but in future it might be important to adjust it to GPDR and have the analytics working after user's consent */
  const [consent, setConsent] = useCookie("consent", "granted");

  if (window?.gtag !== undefined && (consent === "granted" || consent === "denied")) {
    window.gtag("consent", "update", {
      ad_storage: consent,
      analytics_storage: consent,
    });
    window.gtag("set", {
      user_id: session?.accountUuid,
    });
  }

  const setAnalyticsConsent = (enable: boolean) => {
    if (enable) {
      setConsent("granted");
    } else {
      setConsent("denied");
    }
  };

  return {
    isAnalyticsEnabled: consent === "granted",
    setAnalyticsConsent: setAnalyticsConsent,
  };
};

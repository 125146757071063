import { useTranslation } from "react-i18next";

import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";

import { DATE_FORMAT } from "@constants/date-formats";
import { SpecialAvailabilityDay } from "@features/salon/models/specialHours";
import { format } from "date-fns";

import { ConfirmationDialog } from "@components/dialogs/ConfirmationDialog";
import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { useConfirmationDialog } from "@hooks/use-confirmation-dialog";

import {
  SpecialAvailabilityFormDialog,
  SpecialAvailabilityProps,
} from "@features/salon/components/SpecialAvailabilityFormDialog";

import { useSalonOpeningHoursStore } from "@features/salon/hooks";
import { useSessionStore } from "@features/session/hooks";

import { useDialog, bindDialogState } from "@/hooks/use-dialog";

type SpecialAvailabilityListProps = {
  canEdit?: boolean;
};

export const SpecialAvailabilityList = ({ canEdit }: SpecialAvailabilityListProps) => {
  const { t } = useTranslation();
  const { isOwner } = useSessionStore();
  const dialogState = useDialog<SpecialAvailabilityProps>();

  const { special, loading, deleteSpecial } = useSalonOpeningHoursStore();

  const confirmationDialogState = useConfirmationDialog();

  const handleDelete = async (entity: SpecialAvailabilityDay) => {
    confirmationDialogState.open({
      title: t("generic.areYouSure"),
      message: t("openingHours.deleteIrregular"),
      onConfirm: async () => deleteSpecial(entity),
    });
  };
  const handleEdit = (entity: SpecialAvailabilityDay) => dialogState.open({ entity });

  return (
    <ul className="space-y-2">
      {loading ? (
        <Spinner className="w-full items-center justify-center" />
      ) : (
        special.map((entity) => (
          <li
            className="flex items-center justify-between border-t border-gray-200 text-gray-500"
            key={entity.date.getTime()}>
            <div className="py-2">
              <h3 className="text-lg font-medium text-black">{entity.name}</h3>
              <div className="grid py-1">
                <span>{format(entity.date, DATE_FORMAT.DAY_OF_WEEK_MONTH_YEAR)}</span>
                <span>
                  {entity.is_open
                    ? t("openingHours.openInHours")
                    : t("openingHours.event.isOpen.closed")}
                  &nbsp;
                  {entity.is_open &&
                    entity.ranges.map((range, index) => (
                      <span key={index}>
                        {range.from}
                        &mdash;
                        {range.to}
                        {index !== entity.ranges.length - 1 && <>&#44;&nbsp;</>}
                      </span>
                    ))}
                </span>
              </div>
            </div>
            {isOwner && canEdit && (
              <div className="flex gap-2">
                <Button
                  variant="neutral"
                  className="flex h-6 w-6 items-center justify-center"
                  onClick={() => handleEdit(entity)}>
                  <span className="sr-only">{t("generic.edit")}</span>
                  <PencilIcon className="h-4 w-4" />
                </Button>
                <Button
                  variant="neutral"
                  className="flex h-6 w-6 items-center justify-center"
                  onClick={() => handleDelete(entity)}>
                  <span className="sr-only">{t("generic.delete")}</span>
                  <TrashIcon className="h-4 w-4 text-red-500" />
                </Button>
                <ConfirmationDialog dialogState={confirmationDialogState} />
                <SpecialAvailabilityFormDialog {...bindDialogState(dialogState)} />
              </div>
            )}
          </li>
        ))
      )}
    </ul>
  );
};

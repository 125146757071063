import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Listbox, Transition } from "@headlessui/react";

import {
  BuildingStorefrontIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ClockIcon,
  EnvelopeIcon,
  HomeIcon,
  LinkIcon,
  MapIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";

import { POSTAL_CODE } from "@constants/validations";

import { Button } from "@components/ui/Button";
import { ImageField } from "@components/ui/ImageField";
import { Label } from "@components/ui/Label";
import { PhoneField } from "@components/ui/PhoneField";
import { Spinner } from "@components/ui/Spinner";
import { Switch } from "@components/ui/Switch";
import { TextAreaField } from "@components/ui/TextAreaField";
import { TextField } from "@components/ui/TextField";
import { Tooltip } from "@components/ui/Tooltip";

import { useSalonStore } from "@features/salon/hooks";

import { FormSection } from "@/components/layout/FormSection";
import { CoverImageField } from "@/components/ui/CoverImageField";
import { useSalonProfileForm } from "@/features/salon/hooks/use-salon-profile-form";
import { SalonDeserialized } from "@/features/salon/models/salonProfile";
import { useSessionStore } from "@/features/session/hooks";
import { isNotWhitespace, validateCoverImage, validateLogoImage } from "@/utils/form";

import {
  SELF_BOOKING_MAX_LEAD_TIME_OPTIONS,
  SELF_BOOKING_MIN_LEAD_TIME_OPTIONS,
} from "../utils/salonProfile";

export type SalonProfileFormProps = {
  defaultValues: SalonDeserialized;
};

export const SalonProfileForm = ({ defaultValues }: SalonProfileFormProps) => {
  const { t } = useTranslation();
  const { salon, loading } = useSalonStore();
  const { isFreePlan } = useSessionStore();

  const { form, onSubmit, isPublic } = useSalonProfileForm({ defaultValues });

  const {
    control,
    formState: { errors, isValid, isDirty },
    register,
    handleSubmit,
    watch,
  } = form;

  const selfBookingAutomaticalSlotApproval = watch("selfBookingAutomaticalSlotApproval");

  const slotDurationOptions = [15, 30, 60].map((el) => ({
    value: el,
    label: `${el} min`,
  }));

  const selfBookingMinLeadTimeOptions = SELF_BOOKING_MIN_LEAD_TIME_OPTIONS.map((option) => {
    return {
      value: option,
      label: t(`salonProfile.selfBooking.selfBookingMinLeadTimeOptions.${option}`),
    };
  });

  const selfBookingMaxLeadTimeOptions = SELF_BOOKING_MAX_LEAD_TIME_OPTIONS.map((option) => {
    return {
      value: option,
      label: t(`salonProfile.selfBooking.selfBookingMaxLeadTimeOptions.${option}`),
    };
  });

  const initialSlotDuration = slotDurationOptions.find(
    (el) => el.value === defaultValues.selfBookingSlotDuration,
  );

  const initialSelfBookingMinLeadTime = selfBookingMinLeadTimeOptions.find(
    (el) => el.value === defaultValues.selfBookingMinLeadTime,
  );

  const initialSelfBookingMaxLeadTime = selfBookingMaxLeadTimeOptions.find(
    (el) => el.value === defaultValues.selfBookingMaxLeadTime,
  );

  const [selected, setSelected] = useState(initialSlotDuration || slotDurationOptions[0]);
  const [selectedMinLeadTime, setSelectedMinLeadTime] = useState(
    initialSelfBookingMinLeadTime || selfBookingMinLeadTimeOptions[0],
  );
  const [selectedMaxLeadTime, setSelectedMaxLeadTime] = useState(
    initialSelfBookingMaxLeadTime || selfBookingMaxLeadTimeOptions[0],
  );

  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const setAutoConfirm = (data: boolean) => {
    form.setValue("selfBookingAutomaticalSlotApproval", data, {
      shouldDirty: true,
    });

    form.setValue("selfBookingAutomaticalSlotApprovalSms", data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-2 divide-y-[1px] @container xl:gap-8">
      <FormSection
        headingText={t("salonProfile.profile.header")}
        descriptionText={t("salonProfile.profile.description")}>
        <div className="space-y-6">
          <TextField
            {...register("name", {
              required: true,
              validate: {
                isNotWhitespace,
              },
            })}
            label={t("salonProfile.salonName.label")}
            startIcon={<BuildingStorefrontIcon />}
            showLabel
            placeholder={t("salonProfile.salonName.placeholder")}
            errorMessage={
              errors.name &&
              t(`validation.${errors.name.type}`, {
                name: t("salonProfile.salonName.label"),
              })
            }
          />

          <TextAreaField
            {...register("description", { maxLength: 500 })}
            label={t("generic.description")}
            errorMessage={
              errors.description &&
              t(`validation.${errors.description.type}`, {
                name: t("generic.note"),
                maxLength: 500,
              })
            }
          />

          <ImageField
            {...register("logoImage", {
              validate: {
                maxFileSize: (value) => validateLogoImage(value),
                allowedTypes: (value) => validateLogoImage(value),
              },
            })}
            loadedLogoUrl={salon?.logoImageUrl}
            errorMessage={
              errors.logoImage &&
              t(`validation.${errors.logoImage.type}`, {
                name: "logoImageUrl",
                allowedTypes: "jpg, jpeg, png",
                maxFileSize: "10MB",
              })
            }
          />

          <CoverImageField
            {...register("backgroundImage", {
              validate: {
                maxFileSize: (value) => validateCoverImage(value),
                allowedTypes: (value) => validateCoverImage(value),
              },
            })}
            loadedCoverUrl={salon?.backgroundImageUrl}
            errorMessage={
              errors.backgroundImage &&
              t(`validation.${errors.backgroundImage.type}`, {
                name: "coverImageUrl",
                allowedTypes: "jpg, jpeg, png",
                maxFileSize: "2MB",
              })
            }
          />
        </div>
      </FormSection>

      <FormSection
        headingText={t("salonProfile.address.header")}
        descriptionText={t("salonProfile.address.description")}>
        <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
          <TextField
            {...register("street", {
              validate: {
                isNotWhitespace,
              },
            })}
            showLabel
            startIcon={<HomeIcon />}
            label={t("salonProfile.streetAddress.label")}
            placeholder={t("salonProfile.streetAddress.placeholder")}
          />
          <TextField
            showLabel
            {...register("streetNumber", {
              validate: {
                isNotWhitespace,
              },
            })}
            label={t("salonProfile.streetNumber.label")}
            startIcon={<HomeIcon />}
            placeholder={t("salonProfile.streetNumber.placeholder")}
          />
          <TextField
            showLabel
            {...register("postalCode", POSTAL_CODE)}
            label={t("salonProfile.postalCode")}
            startIcon={<TicketIcon />}
            placeholder={t("placeholders.postalCode")}
            mask="99-999"
            errorMessage={
              errors.postalCode &&
              t(`validation.${errors.postalCode.type}`, {
                name: t("salonProfile.postalCode"),
              })
            }
          />
          <TextField
            showLabel
            {...register("city", {
              validate: {
                isNotWhitespace,
              },
            })}
            label={t("generic.city")}
            startIcon={<MapIcon />}
            placeholder={t("placeholders.city")}
          />
        </div>
      </FormSection>

      <FormSection
        headingText={t("salonProfile.contactData.header")}
        descriptionText={t("salonProfile.contactData.description")}>
        <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
          <TextField
            showLabel
            {...register("email")}
            label={t("salonProfile.salonEmail.label")}
            startIcon={<EnvelopeIcon />}
            type="email"
            placeholder={t("salonProfile.salonEmail.placeholder")}
          />
          <PhoneField
            name="phone"
            control={control}
            label={t("salonProfile.salonPhoneNumber.label")}
            placeholder={t("generic.phone")}
            errorMessage={
              errors.phone &&
              t(`validation.${errors.phone.type}`, {
                name: t("generic.phone"),
              })
            }
          />
        </div>
        <TextField
          showLabel
          label={t("salonProfile.linkToFacebook.label")}
          {...register("socials.facebook", {
            validate: {
              isNotWhitespace,
            },
          })}
          startIcon={<LinkIcon />}
          placeholder={t("salonProfile.linkToFacebook.placeholder")}
        />
        <TextField
          showLabel
          {...register("socials.instagram", {
            validate: {
              isNotWhitespace,
            },
          })}
          label={t("salonProfile.linkToInstagram.label")}
          startIcon={<LinkIcon />}
          placeholder={t("salonProfile.linkToInstagram.placeholder")}
        />
      </FormSection>

      <FormSection
        headingText={t("salonProfile.selfBooking.header")}
        descriptionText={t("salonProfile.selfBooking.description")}>
        <Tooltip
          href="/subscriptions"
          content={
            <p className="max-w-sm text-center">
              {isFreePlan
                ? t("selfBooking.onlyAvailableInPaidPlan")
                : t("selfBooking.makeSalonPublicDescription")}
            </p>
          }>
          <Switch
            control={control}
            name="isPublic"
            label={t("selfBooking.makeSalonPublic")}
            showLabel
            className="inline-flex text-sm"
            disabled={isFreePlan}
          />
        </Tooltip>
        {isPublic && (
          <>
            {salon?.slug && (
              <div>
                <Label>{t("selfBooking.linkToSalon")}</Label>
                <div className="w-full rounded-md border border-gray-300 px-3 py-3 text-sm">
                  <a className="text-violet-700" target="_blank" href={salon.selfBooking.url}>
                    <span className="overflow-hidden text-ellipsis">{salon.selfBooking.url}</span>
                  </a>
                </div>
              </div>
            )}
            <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
              <TextField
                showLabel
                {...register("links.terms")}
                startIcon={<LinkIcon />}
                label={t("salonProfile.links.terms")}
                placeholder={t("salonProfile.links.termsPlaceholder")}
              />
              <TextField
                showLabel
                {...register("links.privacy")}
                label={t("salonProfile.links.privacy")}
                startIcon={<LinkIcon />}
                placeholder={t("salonProfile.links.privacyPlaceholder")}
              />
            </div>

            <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
              <Controller
                control={control}
                name="selfBookingMinLeadTime"
                render={({ field: { onChange } }) => (
                  <Listbox
                    value={selectedMinLeadTime}
                    onChange={(e) => {
                      onChange(e.value);
                      setSelectedMinLeadTime(e);
                    }}>
                    {({ open }) => (
                      <div>
                        <Listbox.Label className="block text-xs text-gray-500">
                          {t("salonProfile.selfBooking.selfBookingMinLeadTime")}
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative flex h-11 w-full cursor-pointer items-center rounded-md bg-white pl-10 pr-10 text-left ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                            <div
                              className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                              aria-hidden="true">
                              <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <span className="block truncate">{selectedMinLeadTime.label}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {selfBookingMinLeadTimeOptions.map((slot) => (
                                <Listbox.Option
                                  key={slot.value}
                                  className={
                                    "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-violet-100"
                                  }
                                  value={slot}>
                                  <span className="block truncate font-normal">{slot.label}</span>
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </div>
                    )}
                  </Listbox>
                )}
              />

              <Controller
                control={control}
                name="selfBookingMaxLeadTime"
                render={({ field: { onChange } }) => (
                  <Listbox
                    value={selectedMaxLeadTime}
                    onChange={(e) => {
                      onChange(e.value);
                      setSelectedMaxLeadTime(e);
                    }}>
                    {({ open }) => (
                      <div>
                        <Listbox.Label className="block text-xs text-gray-500">
                          {t("salonProfile.selfBooking.selfBookingMaxLeadTime")}
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative flex h-11 w-full cursor-pointer items-center rounded-md bg-white pl-10 pr-10 text-left ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                            <div
                              className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                              aria-hidden="true">
                              <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <span className="block truncate">{selectedMaxLeadTime.label}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {selfBookingMaxLeadTimeOptions.map((slot) => (
                                <Listbox.Option
                                  key={slot.value}
                                  className={
                                    "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-violet-100"
                                  }
                                  value={slot}>
                                  <span className="block truncate font-normal">{slot.label}</span>
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </div>
                    )}
                  </Listbox>
                )}
              />

              <Controller
                control={control}
                defaultValue={initialSlotDuration?.value}
                name="selfBookingSlotDuration"
                render={({ field: { onChange } }) => (
                  <Listbox
                    value={selected}
                    onChange={(e) => {
                      onChange(e.value);
                      setSelected(e);
                    }}>
                    {({ open }) => (
                      <div>
                        <Listbox.Label className="block text-xs text-gray-500">
                          {t("salonProfile.selfBooking.slotDuration")}
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative flex h-11 w-full cursor-pointer items-center rounded-md bg-white pl-10 pr-10 text-left ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                            <div
                              className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                              aria-hidden="true">
                              <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <span className="block truncate">{selected.label}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {slotDurationOptions.map((slot) => (
                                <Listbox.Option
                                  key={slot.value}
                                  className={
                                    "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-violet-100"
                                  }
                                  value={slot}>
                                  <span className="block truncate font-normal">{slot.label}</span>
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </div>
                    )}
                  </Listbox>
                )}
              />
            </div>

            <div className="grid gap-4">
              <Switch
                control={control}
                name="selfBookingAutomaticalSlotApproval"
                label={t("selfBooking.autoConfirm")}
                showLabel
                className="inline-flex text-sm"
                disabled={isFreePlan}
                onChange={(_, e) => setAutoConfirm(e)}
              />
              <Switch
                control={control}
                name="selfBookingAutomaticalSlotApprovalSms"
                label={t("selfBooking.autoConfirmSms")}
                showLabel
                className="inline-flex text-sm"
                disabled={isFreePlan || selfBookingAutomaticalSlotApproval === false}
              />
            </div>
          </>
        )}
      </FormSection>

      <Button
        type="submit"
        fullWidth
        className="mt-8 sm:ml-auto sm:max-w-xs"
        disabled={loading || !isValid || !isDirty}
        startIcon={<CheckIcon />}>
        {t("form.saveChanges")}
      </Button>
    </form>
  );
};

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRightOnRectangleIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

import { useAuth0 } from "@auth0/auth0-react";

import { useSessionStore } from "@features/session/hooks";

import { Button } from "@/components/ui/Button";
import { ROUTES } from "@/features/router/constants/routes";

export const EmailNotVerifiedView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const { logout } = useSessionStore();

  useEffect(() => {
    const refresh = async () => {
      if (!user?.email_verified) {
        await getAccessTokenSilently({
          ignoreCache: true,
        });
      } else {
        navigate(ROUTES.ONBOARDING_PERSONAL);
      }
    };
    refresh();
  }, []);

  return (
    <div className="flex min-h-full items-center bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <div className="fixed right-4 top-4">
          <Button
            size="small"
            variant="primary-inline"
            startIcon={<ArrowRightOnRectangleIcon />}
            onClick={logout}>
            {t("header.mainMenu.logOut")}
          </Button>
        </div>
        <main className="sm:flex">
          <EnvelopeIcon className="h-14 w-14 text-violet-500" />
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="mb-2 text-4xl font-bold text-gray-900">
                {t("session.isNotVerified.title")}
              </h1>
              <p className="mb-4 whitespace-pre-line text-sm font-normal text-gray-400">
                {t("session.isNotVerified.description")}
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

import React, { useLayoutEffect } from "react";

type MainWrapperProps = {
  children: React.ReactNode;
};

export const MainWrapper = ({ children }: MainWrapperProps) => {
  useLayoutEffect(() => {
    const setValue = () => {
      document.body.style.setProperty("--screenH", `${window.innerHeight}px`);
    };
    const bodyObserver = new ResizeObserver((entries) => {
      entries[0] && setValue();
    });
    bodyObserver.observe(document.body);

    return () => {
      bodyObserver.unobserve(document.body);
    };
  }, []);

  return <>{children}</>;
};

import { useTranslation } from "react-i18next";

import { BanknotesIcon } from "@heroicons/react/24/outline";

import { PaymentMethod } from "@/components/PaymentMethod";
import { formatPrice } from "@/features/payments/utils";
import { AppointmentTreatment, Appointment, AppointmentStatus } from "@/types";
import { cn } from "@/utils/utils";

import { formatDateTime } from "../../utils/time";
import { AppointmentDetailsTreatment } from "./AppointmentDetailsTreatment";
import { AppointmentPaymentMethodInfo } from "./AppointmentPaymentMethodInfo";

type AppointmentDetailsTreatmentsProps = {
  appointment: Appointment;
  lightUI?: boolean;
};

export const AppointmentDetailsTreatments = ({
  appointment,
  lightUI,
}: AppointmentDetailsTreatmentsProps) => {
  const { t } = useTranslation();

  const summarizeTreatmentsPrice = (treatments: AppointmentTreatment[]) => {
    return treatments.reduce(
      (acc, treatment) =>
        acc +
        (appointment.status === AppointmentStatus.Completed
          ? treatment.finalPrice
          : treatment.suggestedPrice),
      0,
    );
  };

  return (
    <ul
      role="list"
      className={cn(
        lightUI ? "border-gray-200" : "border-gray-300",
        "rounded-md border p-4 shadow-sm @container",
      )}>
      {appointment.treatments.map((treatment) => (
        <li key={`${treatment.treatmentUuid}-${treatment.timeRange.from}`}>
          <div className="relative">
            <span
              className="absolute left-8 top-5 -ml-4 hidden h-full w-[2px] bg-gray-300 @[400px]:flex"
              aria-hidden="true"
            />
            <div className="relative flex items-start">
              <div className="relative -ml-4 hidden @[400px]:block">
                <div
                  className={cn(
                    lightUI ? "bg-white" : "bg-gray-50",
                    "mt-2 flex items-center justify-center rounded-md px-4 py-1.5",
                  )}>
                  <div className="text-xs font-medium text-gray-500">
                    {formatDateTime(treatment.timeRange.from)}
                  </div>
                </div>
              </div>
              <AppointmentDetailsTreatment
                treatment={treatment}
                appointmentStatus={appointment.status}
                appointmentPaymentMethod={appointment.paymentMethodUuid}
              />
            </div>
          </div>
        </li>
      ))}
      <li>
        <div className="relative">
          <div className="relative flex items-start">
            <div
              className={cn(
                lightUI ? "bg-white" : "bg-gray-50",
                "-ml-4 mt-2 hidden items-center justify-center rounded-md px-4 py-2 @[400px]:flex",
              )}>
              <div className="text-xs font-medium text-gray-500">
                {formatDateTime(
                  appointment.treatments[appointment.treatments.length - 1].timeRange.to,
                )}
              </div>
            </div>
            <div className="mt-2 flex w-full justify-end rounded-md bg-gray-50 py-2 font-medium">
              <div className="flex items-center px-4 text-sm text-gray-700">
                <span className="mr-3 text-gray-700">{t("generic.total")}:</span>
                {appointment.status === AppointmentStatus.Completed &&
                appointment.paymentMethodUuid ? (
                  <PaymentMethod
                    paymentMethodUuid={appointment.paymentMethodUuid}
                    showLabel={false}
                    className="mr-1 h-4 w-4 text-gray-700 sm:h-3.5 sm:w-3.5"
                  />
                ) : (
                  <BanknotesIcon
                    className={`mr-1 h-4 w-4 text-gray-700 sm:h-3.5 sm:w-3.5`}
                    aria-hidden="true"
                  />
                )}
                <span>
                  {formatPrice({
                    price: summarizeTreatmentsPrice(appointment.treatments),
                    currency: "PLN",
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
        {appointment.status === "COMPLETED" && appointment.paymentMethodUuid !== null && (
          <AppointmentPaymentMethodInfo appointment={appointment} />
        )}
      </li>
    </ul>
  );
};

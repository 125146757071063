import { useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useMatch } from "react-router-dom";

import { Disclosure, Transition } from "@headlessui/react";

import {
  CalendarDaysIcon,
  ChartBarIcon,
  ClockIcon,
  Cog6ToothIcon,
  UsersIcon,
  UserGroupIcon,
  LifebuoyIcon,
  BuildingStorefrontIcon,
  ListBulletIcon,
  RectangleStackIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";

import clsx from "clsx";

import { AnimatedExpandIcon } from "@icons/AnimatedExpandIcon";

import { useBreakpoint } from "@hooks/use-breakpoint";
import { useDialog, bindDialogState } from "@hooks/use-dialog";

import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@features/account/components/SendFeedbackDialog";

import { useSessionStore } from "@features/session/hooks";

import { Role } from "@/types";
import { sendEvent } from "@/utils/google-analytics";

import { NavigationCategory } from "./types/sidebar";

export const NavigationItems = ({ isSidebarShrinked }: { isSidebarShrinked: boolean }) => {
  const { t } = useTranslation();
  const { role, permissions } = useSessionStore();
  const feedbackDialogState = useDialog<SendFeedbackProps>();
  const { isMd } = useBreakpoint("md");

  const navigation: NavigationCategory[] = useMemo(
    () => [
      {
        label: t("sidebar.navigation.appointments.label"),
        children: [
          {
            name: t("sidebar.navigation.appointments.children.calendar"),
            icon: CalendarDaysIcon,
            href: "/calendar",
          },
          {
            name: t("sidebar.navigation.appointments.children.clients"),
            icon: UserGroupIcon,
            href: "/clients",
          },
          {
            name: t("sidebar.navigation.appointments.children.statistics"),
            icon: ChartBarIcon,
            href: "/statistics",
            hasPermission: permissions.statistics_view,
          },
        ],
      },
      {
        label: t("sidebar.navigation.mySalon.label"),
        children: [
          {
            name: t("sidebar.navigation.mySalon.children.salonProfile"),
            icon: BuildingStorefrontIcon,
            href: "/salon",
          },
          {
            name: t("sidebar.navigation.mySalon.children.employees"),
            icon: UsersIcon,
            href: "/employees",
          },
          {
            name: t("sidebar.navigation.mySalon.children.openingHours"),
            icon: ClockIcon,
            href: "/opening-hours",
          },
          {
            name: t("sidebar.navigation.mySalon.children.servicesAndPrices"),
            icon: ListBulletIcon,
            href: "/services",
          },
          {
            name: t("equipment.name"),
            icon: RectangleStackIcon,
            href: "/equipment",
          },
          {
            name: t("settings.title"),
            icon: Cog6ToothIcon,
            href: "/settings",
          },
          {
            name: t("subscriptions.title"),
            icon: ArrowPathIcon,
            href: "/subscriptions",
            roles: [Role.Owner],
          },
        ],
      },
      {
        label: t("generic.support"),
        children: [
          {
            name: t("footer.iNeedHelp"),
            icon: LifebuoyIcon,
            action: () => {
              sendEvent("support_button", "contact", "footer");
              feedbackDialogState.open();
            },
          },
        ],
      },
    ],
    [],
  );

  const navigationCategories = useMemo<NavigationCategory[]>(
    () =>
      role
        ? navigation.map((category) => ({
            ...category,
            children: category.children.filter(
              (item) =>
                (!item.roles || item.roles.includes(role)) &&
                (item.hasPermission === undefined || item.hasPermission === true),
            ),
          }))
        : [],
    [role, navigation],
  );

  return (
    <nav className="flex-1 space-y-1 bg-white px-3 pb-10" aria-label="Sidebar">
      {navigationCategories.map((category) => (
        <div key={category.label}>
          <Transition
            show={!isSidebarShrinked || !isMd}
            as="div"
            enter="transition-all ease-in-out duration-100"
            enterFrom="opacity-0 -translate-y-5"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all ease-in-out duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-5">
            <span className="mb-2 mt-3 block text-xs font-bold uppercase text-gray-500">
              {category.label}
            </span>
          </Transition>
          {category.children.map(
            (item) =>
              !item.disabled && (
                <Fragment key={item.name}>
                  {!item.children ? (
                    <>
                      {item.action ? (
                        <button
                          onClick={item.action}
                          className="rounded-smpy-2 group flex w-full items-center px-2 py-3 text-sm text-gray-400 hover:text-gray-500">
                          <item.icon className="mr-3 h-5 w-5 flex-shrink-0" />
                          <Transition
                            show={!isSidebarShrinked || !isMd}
                            as="span"
                            enter={`transition-all duration-150 transform delay-200 ease-out`}
                            enterFrom="opacity-0 -translate-x-1/2"
                            enterTo="opacity-100 translate-x-0"
                            leave="transition-all ease-in-out duration-150 transform"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            className="truncate">
                            {item.name}
                          </Transition>
                        </button>
                      ) : (
                        <NavLink
                          to={item.href ?? "#"}
                          className={({ isActive }) =>
                            clsx(
                              "rounded-smpy-2 group flex w-full items-center px-2 py-3 text-sm transition-colors hover:text-gray-500",
                              isActive ? "text-violet-500" : "text-gray-400",
                            )
                          }>
                          <item.icon className="mr-3 h-5 w-5 flex-shrink-0" />
                          <Transition
                            show={!isSidebarShrinked || !isMd}
                            as="span"
                            enter={`transition-all duration-150 transform delay-200 ease-out`}
                            enterFrom="opacity-0 -translate-x-1/2"
                            enterTo="opacity-100 translate-x-0"
                            leave="transition-all ease-in-out duration-150 transform"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            className="truncate">
                            {item.name}
                          </Transition>
                        </NavLink>
                      )}
                    </>
                  ) : (
                    <Disclosure
                      as="div"
                      className="space-y-1"
                      defaultOpen={item.children.some((item) => useMatch(item.href))}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={clsx(
                              "group flex w-full items-center rounded-sm pb-2 pl-2 pr-1 pt-3 text-left text-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
                              open ? "text-violet-500" : "text-gray-400",
                            )}>
                            <item.icon
                              className="mr-3 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <span className="flex-1">{item.name}</span>
                            <AnimatedExpandIcon open={open} />
                          </Disclosure.Button>

                          <Disclosure.Panel>
                            {item.children?.map((subItem) => (
                              <NavLink
                                key={subItem.name}
                                to={subItem.href}
                                className={({ isActive }) =>
                                  clsx(
                                    "group flex w-full items-center rounded-sm py-3 pl-11 pr-2 text-sm",
                                    isActive ? "text-violet-500" : "text-gray-400",
                                  )
                                }>
                                {subItem.name}
                              </NavLink>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                </Fragment>
              ),
          )}
        </div>
      ))}
      <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
    </nav>
  );
};

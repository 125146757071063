import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Spinner } from "@/components/ui/Spinner";
import { useSalonOpeningHoursStore } from "@/features/salon/hooks";

import { useEmployeeStore } from "../hooks";
import { EmployeeSchedule, EmployeeScheduleDay } from "../models";
import { decodeEmployeeWorkSchedule, weekdays } from "../utils";
import { EmployeeWorkScheduleItem } from "./EmployeeWorkSchedule/EmployeeWorkScheduleItem";

type EmployeeFormProps = {
  employeeUuid: string;
};

export const EmployeeWorkSchedule = ({ employeeUuid }: EmployeeFormProps) => {
  const { regular, loading: openingHoursLoading } = useSalonOpeningHoursStore();
  const { employeeWorkSchedule: initialEmployeeWorkSchedule, loading: employeeLoading } =
    useEmployeeStore(employeeUuid);

  const hasWorkSchadule =
    initialEmployeeWorkSchedule && initialEmployeeWorkSchedule[weekdays[0]].ranges.length > 0;

  const [employeeWorkSchedule, setEmployeeWorkSchedule] = useState<EmployeeSchedule>(
    initialEmployeeWorkSchedule,
  );

  const loading = openingHoursLoading || employeeLoading;

  useEffect(() => {
    const schedule = hasWorkSchadule ? initialEmployeeWorkSchedule : regular;

    setEmployeeWorkSchedule(schedule);
  }, [loading, hasWorkSchadule]);

  const { setValue } = useFormContext();

  const updateWorkSchedule = (weekday: string, item: EmployeeScheduleDay) => {
    const updatedWorkSchedule = { ...employeeWorkSchedule, [weekday]: item };
    setEmployeeWorkSchedule(updatedWorkSchedule);
    const updateDecoded = JSON.stringify(decodeEmployeeWorkSchedule(updatedWorkSchedule));

    setValue("workSchedule", updateDecoded, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div className="w-full">
      {loading ? (
        <div className="flex h-80 w-full items-center justify-center">
          <Spinner className="h-12 w-12 sm:h-12 sm:w-12" />
        </div>
      ) : (
        <div className="mx-auto w-full space-y-4 rounded-md bg-gray-50">
          {weekdays.map((weekday) => (
            <EmployeeWorkScheduleItem
              key={weekday}
              weekday={weekday}
              item={employeeWorkSchedule[weekday]}
              onUpdate={updateWorkSchedule}
            />
          ))}
        </div>
      )}
    </div>
  );
};

import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ConfirmationDialog } from "@components/dialogs/ConfirmationDialog";

import { useConfirmationDialog } from "@hooks/use-confirmation-dialog";
import { useDialog, bindDialogState } from "@hooks/use-dialog";

import {
  useCategoryStore,
  useCategoriesStore,
  useHeadCategoryStore,
} from "@features/services/hooks";

import { Category } from "@/types";

import { ServiceEntitiesHeader } from "../ServiceEntitiesHeader";
import { CategoryEntity } from "./CategoryEntity";
import { CategoryFormDialog, CategoryFormProps } from "./CategoryFormDialog";
import { CategoryListWrapper } from "./CategoryListWrapper";

export const CategoryList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { headCategoryUuid, categoryUuid } = useParams();
  const { updateCategoriesPositions } = useCategoriesStore();
  const { deleteCategory } = useCategoryStore();
  const dialogState = useDialog<CategoryFormProps>();
  const confirmationDialogState = useConfirmationDialog();
  const { categories } = useHeadCategoryStore(headCategoryUuid);

  const [isDraggable, setIsDraggable] = useState(false);
  const [tempCategories, setTempCategories] = useState<Category[]>([]);

  const handleCategoryAdd = () => headCategoryUuid && dialogState.open({ headCategoryUuid });

  const handleCategoryEdit = ({ uuid }: Category) =>
    headCategoryUuid &&
    dialogState.open({ categoryUuid: uuid, headCategoryUuid, onRemove: handleCategoryRemove });

  const handleCategoryRemove: (category: Category) => Promise<boolean> = ({ uuid, name }) =>
    new Promise((resolve) =>
      confirmationDialogState.open({
        title: t("services.categories.delete"),
        message: t("services.categories.deleteWarning", { name }),
        onConfirm: async () => {
          await deleteCategory({ categoryUuid: uuid });
          if (uuid === categoryUuid) {
            navigate(`/services/${headCategoryUuid}`);
          }
          resolve(true);
        },
        onDeny: () => resolve(false),
      }),
    );

  const handleCategorySelect = ({ uuid }: Category) => {
    navigate(`/services/${headCategoryUuid}/${uuid}`);
  };

  const handleSaveOrder = useCallback(() => {
    if (tempCategories.length > 0 && headCategoryUuid) {
      const data = {
        headCategoryUuid,
        sortedCategoryUuids: tempCategories.map((category) => category.uuid),
      };

      updateCategoriesPositions({ data: data });
    }

    setIsDraggable(false);
  }, [tempCategories, headCategoryUuid, updateCategoriesPositions]);

  const handleOrder = useCallback(() => {
    setIsDraggable((prev) => !prev);

    if (isDraggable) {
      setTempCategories(categories as Category[]);
    }

    if (!isDraggable) {
      setTempCategories([]);
    }
  }, []);

  return (
    <div className="overflow-auto">
      <ServiceEntitiesHeader
        title={t("services.categories.title")}
        backUrl="/services"
        handleAdd={handleCategoryAdd}
        handleOrder={handleOrder}
        isDraggable={isDraggable}
        handleSaveOrder={handleSaveOrder}
        isDnDVisible={categories.length > 0}
      />
      <CategoryListWrapper
        isDraggable={isDraggable}
        noResultText={t("services.categories.noResults")}
        renderCategory={(category: Category) => <CategoryEntity entity={category} />}
        onSelect={handleCategorySelect}
        onEdit={handleCategoryEdit}
        onRemove={handleCategoryRemove}
        categories={
          isDraggable
            ? tempCategories.length > 0 && tempCategories
              ? tempCategories
              : (categories.filter((category) => category !== null) as Category[])
            : (categories.filter((category) => category !== null) as Category[])
        }
        setTempCategories={setTempCategories}
      />
      <CategoryFormDialog {...bindDialogState(dialogState)} />
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </div>
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Switch } from "@headlessui/react";

import { HomeIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { EmployeeScheduleDay, Range } from "../../models";
import { EmployeeWorkScheduleItemRow } from "./EmployeeWorkScheduleItemRow";

type EmployeeWorkScheduleItemProps = {
  weekday: string;
  item: EmployeeScheduleDay;
  onUpdate: (weekday: string, item: EmployeeScheduleDay) => void;
};

export const EmployeeWorkScheduleItem = ({
  weekday,
  item,
  onUpdate,
}: EmployeeWorkScheduleItemProps) => {
  const { t } = useTranslation();

  const [enabled, setEnabled] = useState(item.is_open);

  useEffect(() => {
    setEnabled(item.is_open);
  }, [item]);

  const handleUpdate = () => {
    const updatedItem = {
      ...item,
      is_open: !enabled,
    };

    setEnabled(!enabled);
    onUpdate(weekday, updatedItem);
  };

  const handleUpdateRange = (rangeIndex: number, newRange: Range) => {
    const updatedRanges = item.ranges.map((r, index) => (index === rangeIndex ? newRange : r));

    onUpdate(weekday, {
      ...item,
      ranges: updatedRanges,
    });
  };

  const handleAddRange = () => {
    onUpdate(weekday, {
      ...item,
      ranges: [...item.ranges, { from: "09:00", to: "10:00" }],
    });
  };

  const handleRemoveRange = (rangeIndex: number) => {
    const updatedRanges = item.ranges.filter((_, index) => index !== rangeIndex);
    onUpdate(weekday, {
      ...item,
      ranges: updatedRanges,
    });
  };

  return (
    <div
      className={clsx(
        "grid w-full items-center justify-between rounded-md bg-gray-100 p-2 text-left text-sm text-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 sm:grid-cols-3 sm:bg-gray-50 sm:p-0",
        {
          "grid-cols-2 items-center": !enabled,
        },
      )}>
      <div className={clsx(enabled && "mb-4", "mt-2.5 flex items-center self-start sm:mb-0")}>
        <Switch
          name={`workSchedule.${weekday}`}
          checked={enabled}
          onChange={handleUpdate}
          className={`${enabled ? "bg-violet-500" : "bg-gray-400"}
            relative mr-4 inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-violet-600 focus:ring-offset-2`}>
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform cursor-pointer rounded-full bg-white transition-transform`}
          />
        </Switch>
        <div>{t(`weekDays.${weekday}`)}</div>
      </div>
      {enabled ? (
        <>
          {item.ranges.map((range, index) => (
            <EmployeeWorkScheduleItemRow
              key={index}
              range={range}
              onUpdate={(newRange: Range) => handleUpdateRange(index, newRange)}
              onAddRange={handleAddRange}
              onRemoveRange={() => handleRemoveRange(index)}
              totalRanges={item.ranges.length}
              index={index}
            />
          ))}
        </>
      ) : (
        <div className="flex items-center justify-end rounded-md bg-gray-100 px-4 py-3 text-gray-400 sm:col-span-2 sm:justify-start">
          <HomeIcon className="mr-2 h-4 w-4" />
          <span>{t("generic.holiday")}</span>
        </div>
      )}
    </div>
  );
};

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Label } from "@components/ui/Label";
import { Spinner } from "@components/ui/Spinner";
import { Switch } from "@components/ui/Switch";

import { useSalonSmsDetailsStore } from "@features/salon/hooks";

import { UpdateSalonSmsDetailsInput } from "@/types";

export const SmsDetails = () => {
  const { t } = useTranslation();
  const { salonSmsDetails, updateSalonSmsDetails, fetchLoading } = useSalonSmsDetailsStore();

  const { control, reset, handleSubmit, watch } = useForm({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      sendReminders: salonSmsDetails?.sendReminders,
    },
  });

  const handleSalonSmsDetailsUpdate = (data: UpdateSalonSmsDetailsInput) => {
    updateSalonSmsDetails(data);
  };

  useEffect(() => {
    reset({
      sendReminders: salonSmsDetails?.sendReminders,
    });
  }, [salonSmsDetails]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(handleSalonSmsDetailsUpdate)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="max-w-sm">
      {fetchLoading ? (
        <Spinner />
      ) : (
        <form className="flex gap-2">
          <Label htmlFor="sendReminders" className="text-sm text-gray-500">
            {t("smsSettings.sendReminders")}
          </Label>
          <Switch
            name="sendReminders"
            control={control}
            label={t("smsSettings.sendReminders")}
            className="flex justify-between text-sm text-gray-500"
          />
        </form>
      )}
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Transition } from "@headlessui/react";

import {
  KeyIcon,
  EnvelopeIcon,
  PencilIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import type { BreadcrumbComponentProps } from "use-react-router-breadcrumbs";

import { Subheader } from "@components/layout/subheader/Subheader";
import { Meta } from "@components/Meta";
import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useEmployeeStore } from "@features/employees/hooks";
import { useSessionStore } from "@features/session/hooks";

import {
  DefinitionDescription,
  DefinitionEntry,
  DefinitionList,
  DefinitionTitle,
} from "@/components/ui/DefinitionList";
import { ROLE_LABELS_MAP } from "@/features/employees/constants";
import { Role } from "@/types";

export const EmployeeDetailsBreadcrumb = ({ match }: BreadcrumbComponentProps) => {
  const { employee, loading } = useEmployeeStore(match.params.id);

  return <span>{loading ? "..." : employee?.fullName}</span>;
};

export const EmployeeDetails = () => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint("md");
  const { id } = useParams();
  const { isOwner } = useSessionStore();
  const { employee, loading } = useEmployeeStore(id);

  return (
    <>
      <Subheader
        title={employee?.fullName || "..."}
        backUrl={isMd ? undefined : "/employees"}
        leftSlot={
          isOwner && employee ? (
            <Button
              to={employee.role === Role.Owner ? "/settings?tab=account" : `/employees/${id}/edit`}
              size="small"
              startIcon={<PencilIcon />}>
              {t("generic.edit")}
            </Button>
          ) : (
            <></>
          )
        }
      />
      <Meta title={employee?.fullName || "..."} />
      <Transition
        show={!!employee}
        appear
        as="div"
        className="mx-auto mt-4 max-w-2xl shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
        {employee && (
          <div className="p-4">
            <DefinitionList>
              <DefinitionEntry>
                <DefinitionTitle icon={<UserIcon />}>{t("generic.firstName")}</DefinitionTitle>
                <DefinitionDescription>{employee.firstName}</DefinitionDescription>
              </DefinitionEntry>
            </DefinitionList>
            <DefinitionList>
              <DefinitionEntry>
                <DefinitionTitle icon={<UserIcon />}>{t("generic.lastName")}</DefinitionTitle>
                <DefinitionDescription>{employee.lastName}</DefinitionDescription>
              </DefinitionEntry>
              <DefinitionEntry>
                <DefinitionTitle icon={<KeyIcon />}>{t("generic.role")}</DefinitionTitle>
                <DefinitionDescription>
                  {employee.role && t(ROLE_LABELS_MAP[employee.role])}
                </DefinitionDescription>
              </DefinitionEntry>
              <DefinitionEntry>
                <DefinitionTitle icon={<PhoneIcon />}>{t("generic.phone")}</DefinitionTitle>
                <DefinitionDescription>{employee.phone}</DefinitionDescription>
              </DefinitionEntry>
              <DefinitionEntry>
                <DefinitionTitle icon={<EnvelopeIcon />}>{t("generic.email")}</DefinitionTitle>
                <DefinitionDescription>{employee.email}</DefinitionDescription>
              </DefinitionEntry>
            </DefinitionList>
          </div>
        )}
      </Transition>
      <Transition
        show={loading}
        as="div"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </Transition>
    </>
  );
};

import { PostHogProvider } from "posthog-js/react";

const { MODE, VITE_POSTHOG_HOST, VITE_POSTHOG_KEY } = import.meta.env;

const POSTHOG_OPTIONS = {
  api_host: VITE_POSTHOG_HOST,
};

export const PostHogProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  // VITE_POSTHOG_KEY !== "#" is a workaround for Amplify environment variables, where we have to specify an override value for staging
  if (MODE === "development" || VITE_POSTHOG_KEY !== "#") return <>{children}</>;
  return (
    <PostHogProvider apiKey={VITE_POSTHOG_KEY} options={POSTHOG_OPTIONS}>
      {children}
    </PostHogProvider>
  );
};

import type { ServiceCategoryColor } from "../../features/services/models";

export const MIN_OPTIONS_FOR_SEARCH = 5;

export type ColorsVariations = {
  primary: string;
  secondary: string;
  tertiary: string;
  border: string;
  primaryText: string;
  secondaryText?: string;
};

export type Colors =
  | "orange"
  | "amber"
  | "lime"
  | "teal"
  | "green"
  | "cyan"
  | "sky"
  | "blue"
  | "indigo"
  | "violet"
  | "purple"
  | "fuchsia"
  | "gray"
  | "red";

export const SERVICE_CATEGORY_COLORS: Record<Colors | string, ServiceCategoryColor> = {
  orange: {
    primary: "bg-orange-200",
    secondary: "bg-orange-50",
    tertiary: "bg-orange-100",
    border: "border-orange-200",
    primaryText: "text-orange-900",
  },
  amber: {
    primary: "bg-amber-200",
    secondary: "bg-amber-50",
    tertiary: "bg-amber-100",
    border: "border-amber-200",
    primaryText: "text-amber-900",
  },
  lime: {
    primary: "bg-lime-200",
    secondary: "bg-lime-50",
    tertiary: "bg-lime-100",
    border: "border-lime-200",
    primaryText: "text-lime-900",
  },
  teal: {
    primary: "bg-teal-200",
    secondary: "bg-teal-50",
    tertiary: "bg-teal-100",
    border: "border-teal-200",
    primaryText: "text-teal-900",
  },
  green: {
    primary: "bg-green-200",
    secondary: "bg-green-50",
    tertiary: "bg-green-100",
    border: "border-green-200",
    primaryText: "text-green-900",
  },
  cyan: {
    primary: "bg-cyan-200",
    secondary: "bg-cyan-50",
    tertiary: "bg-cyan-100",
    border: "border-cyan-200",
    primaryText: "text-cyan-900",
  },
  sky: {
    primary: "bg-sky-200",
    secondary: "bg-sky-50",
    tertiary: "bg-sky-100",
    border: "border-sky-200",
    primaryText: "text-sky-900",
  },
  blue: {
    primary: "bg-blue-200",
    secondary: "bg-blue-50",
    tertiary: "bg-blue-100",
    border: "border-blue-200",
    primaryText: "text-blue-900",
  },
  indigo: {
    primary: "bg-indigo-200",
    secondary: "bg-indigo-50",
    tertiary: "bg-indigo-100",
    border: "border-indigo-200",
    primaryText: "text-indigo-900",
  },
  violet: {
    primary: "bg-violet-200",
    secondary: "bg-violet-50",
    tertiary: "bg-violet-100",
    border: "border-violet-200",
    primaryText: "text-violet-900",
  },
  purple: {
    primary: "bg-purple-200",
    secondary: "bg-purple-50",
    tertiary: "bg-purple-100",
    border: "border-purple-200",
    primaryText: "text-purple-900",
  },
  fuchsia: {
    primary: "bg-fuchsia-200",
    secondary: "bg-fuchsia-50",
    tertiary: "bg-fuchsia-100",
    border: "border-fuchsia-200",
    primaryText: "text-fuchsia-900",
  },
};

type BadgeColors = {
  background: string;
  border: string;
  text: string;
};

export const BADGE_COLORS: Record<Colors | string, BadgeColors> = {
  orange: {
    background: "bg-orange-50",
    border: "border-orange-200",
    text: "text-orange-900",
  },
  amber: {
    background: "bg-amber-50",
    border: "border-amber-200",
    text: "text-amber-900",
  },
  lime: {
    background: "bg-lime-50",
    border: "border-lime-200",
    text: "text-lime-900",
  },
  teal: {
    background: "bg-teal-50",
    border: "border-teal-200",
    text: "text-teal-900",
  },
  green: {
    background: "bg-green-50",
    border: "border-green-200",
    text: "text-green-900",
  },
  cyan: {
    background: "bg-cyan-50",
    border: "border-cyan-200",
    text: "text-cyan-900",
  },
  sky: {
    background: "bg-sky-50",
    border: "border-sky-200",
    text: "text-sky-900",
  },
  blue: {
    background: "bg-blue-50",
    border: "border-blue-200",
    text: "text-blue-900",
  },
  indigo: {
    background: "bg-indigo-50",
    border: "border-indigo-200",
    text: "text-indigo-900",
  },
  violet: {
    background: "bg-violet-50",
    border: "border-violet-200",
    text: "text-violet-900",
  },
  purple: {
    background: "bg-purple-50",
    border: "border-purple-200",
    text: "text-purple-900",
  },
  fuchsia: {
    background: "bg-fuchsia-50",
    border: "border-fuchsia-200",
    text: "text-fuchsia-900",
  },
  gray: {
    background: "bg-gray-50",
    border: "border-gray-200",
    text: "text-gray-900",
  },
  red: {
    background: "bg-red-50",
    border: "border-red-200",
    text: "text-red-900",
  },
};

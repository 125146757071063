import { Fragment } from "react";

import { Listbox } from "@headlessui/react";

import clsx from "clsx";

import { Option } from "@/utils/select-utils";

type SelectOptionsProps = {
  options: Option[];
  selected: string | string[];
  multiple?: boolean;
  selectBy?: "value" | undefined;
};

export const SelectOptions = ({
  options,
  selected,
  multiple = false,
  selectBy = undefined,
}: SelectOptionsProps) => {
  const isOptionSelected = (option: Option) => {
    if (multiple && Array.isArray(selected)) {
      return selected?.some((selectedOption) => selectedOption === option.value);
    }
    return selected === option.value;
  };

  return (
    <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-gray-300 focus:outline-none">
      {options.map((option) => (
        <Listbox.Option
          key={option.value}
          value={selectBy ? option.value : multiple ? option.value : option}
          as={Fragment}>
          {({ active }) => (
            <li
              className={clsx("relative cursor-pointer select-none px-4 py-2 text-gray-600", {
                "bg-gray-50 text-gray-700": active,
                "bg-gray-100": isOptionSelected(option),
              })}>
              {option.label}
            </li>
          )}
        </Listbox.Option>
      ))}
    </Listbox.Options>
  );
};

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PlusCircleIcon } from "@heroicons/react/24/outline";

import { Layout } from "@components/layout/Layout";
import { Subheader } from "@components/layout/subheader/Subheader";
import { Button } from "@components/ui/Button";

import { SalonOpeningHoursForm } from "@features/salon/components/SalonOpeningHoursForm";
import {
  SpecialAvailabilityFormDialog,
  SpecialAvailabilityProps,
} from "@features/salon/components/SpecialAvailabilityFormDialog";

import { SpecialAvailabilityList } from "@/features/salon/components/SpecialAvailabilityList";
import { useDialog, bindDialogState } from "@/hooks/use-dialog";
export const OpeningHoursEditView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dialogState = useDialog<SpecialAvailabilityProps>();
  const handleFormSubmit = () => navigate("/opening-hours");

  return (
    <Layout title={t("sidebar.navigation.mySalon.children.openingHours")} className="bg-gray-100">
      <div className="-ml-4 -mr-4 mb-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mb-8 sm:mt-4 sm:px-12 md:mx-auto">
        <Subheader title={t("sidebar.navigation.mySalon.children.openingHours")} />
      </div>
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <div className="grid gap-8 py-4 lg:grid-cols-[3fr,_2fr]">
          <div>
            <h2 className="text-lg font-medium text-gray-900">{t("openingHours.regular")}</h2>
            <SalonOpeningHoursForm onSubmit={handleFormSubmit} />
          </div>
          <div>
            <h2 className="text-lg font-medium text-gray-900">{t("openingHours.irregular")}</h2>
            <div className="py-4">
              <Button
                onClick={() => dialogState.open()}
                startIcon={<PlusCircleIcon />}
                variant="primary-outline"
                fullWidth>
                {t("openingHours.addIrregular")}
              </Button>
              <SpecialAvailabilityFormDialog {...bindDialogState(dialogState)} />
              <SpecialAvailabilityList canEdit />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

import React, { forwardRef, ForwardedRef } from "react";

import clsx from "clsx";
import { format, eachDayOfInterval, isToday } from "date-fns";

import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";

import { NUMBER_OF_DAYS_IN_WEEK } from "../../constants";

type CalendarEmployeeWeekHeaderProps = {
  hasMoreThanOneEmployee: boolean;
};

export const CalendarEmployeeWeekHeader = forwardRef(
  (
    { hasMoreThanOneEmployee }: CalendarEmployeeWeekHeaderProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { timeRange } = useCalendarContext();
    const days = eachDayOfInterval({ start: timeRange.from, end: timeRange.to });

    return (
      <div
        ref={ref}
        className={clsx(
          hasMoreThanOneEmployee ? "top-[42px]" : "top-0 border-t-0",
          "sticky left-0 right-0 z-[41] min-w-full border border-x-0 bg-white pr-8",
        )}>
        <div
          className="-mr-px grid min-w-max divide-x divide-gray-200 border-r border-gray-100 text-xs leading-6 text-gray-500"
          style={{ gridTemplateColumns: `repeat(${NUMBER_OF_DAYS_IN_WEEK}, minmax(250px, 1fr))` }}>
          <div className="col-end-1 w-10" />
          {days.map((day, index) => (
            <div key={index} className="flex items-center justify-center py-2">
              <span className="flex items-baseline gap-1.5">
                {format(day, "EEE")}{" "}
                <span
                  className={clsx("items-center justify-center font-semibold text-gray-900", {
                    "flex h-6 w-6 rounded-md bg-violet-100 text-violet-700": isToday(day),
                  })}>
                  {format(day, "dd")}
                </span>
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  },
);

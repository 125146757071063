import React, { forwardRef, ForwardedRef, useMemo } from "react";

import clsx from "clsx";
import {
  format,
  eachDayOfInterval,
  isSameDay,
  isSameMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";

import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import { useBreakpoint } from "@/hooks/use-breakpoint";

export const CalendarDaysNavigation = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { isSm } = useBreakpoint("sm");
  const { selectedDate, timeRange, setSelectedDate } = useCalendarContext();
  const days = eachDayOfInterval({
    start: startOfWeek(timeRange.from),
    end: endOfWeek(timeRange.from),
  });

  const month = useMemo(() => {
    const start = format(timeRange.from, "LLLL");
    const end = isSameMonth(timeRange.from, timeRange.to) ? "" : format(timeRange.to, "- LLLL");

    return `${start} ${end}`;
  }, [timeRange.from, timeRange.to]);

  return (
    <div
      ref={ref}
      className="absolute left-0 right-0 top-0 z-40 flex-none border bg-white sm:hidden">
      {!isSm && (
        <time className="flex justify-center border-b border-gray-100 py-2 text-xs uppercase tracking-wide text-gray-500 first-letter:capitalize">
          {month}
        </time>
      )}
      <div className="grid grid-cols-7 px-2 text-xs leading-6 text-gray-500 ">
        {days.map((day, index) => (
          <button
            key={index}
            type="button"
            className="flex flex-col items-center pb-2"
            onClick={() => setSelectedDate(day)}>
            {format(day, "EEEEEE")}{" "}
            <span
              className={clsx(
                "flex h-6 w-6 items-center justify-center font-semibold text-gray-900",
                { "rounded-md bg-violet-200 text-violet-700": isSameDay(day, selectedDate) },
              )}>
              {format(day, "dd")}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
});

import { useMemo, useCallback, useState, Fragment, useLayoutEffect } from "react";

import { Transition } from "@headlessui/react";

import {
  SCALE_STEPS_PER_DAY,
  AUTOSCROLL_OFFSET,
  MINUTES_PER_DAY,
} from "@features/calendar/constants";
import { SlotRowsByEmployee } from "@features/calendar/models";
import clsx from "clsx";
import { differenceInMinutes, startOfDay } from "date-fns";

import { useBreakpoint } from "@/hooks/use-breakpoint";

import { useCalendarContext } from "../../providers/CalendarProvider";
import { CalendarDaysNavigation } from "../CalendarDaysNavigation";
import { CalendarScaleBar } from "../CalendarScaleBar";
import { CalendarSlotsGroup } from "../CalendarSlotsGroup";
import { CalendarDayAvailability } from "./CalendarDayAvailability";
import { CalendarDayEmployees } from "./CalendarDayEmployees";
import { CalendarDayPlaceholder } from "./CalendarDayPlaceholder";

type CalendarDayContentProps = {
  slotGroups: SlotRowsByEmployee[];
};

export const CalendarDayContent = ({ slotGroups }: CalendarDayContentProps) => {
  const { filters } = useCalendarContext();

  const [containerElement, setContainerElement] = useState<HTMLElement | null>(null);
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
  const [scaleElement, setScaleElement] = useState<HTMLElement | null>(null);
  const [indicatorOffset, setIndicatorOffset] = useState<number>(0);

  const { isSm } = useBreakpoint("sm");

  const containerRef = useCallback(
    (element: HTMLElement | null) => setContainerElement(element),
    [],
  );
  const headerRef = useCallback((element: HTMLElement | null) => setHeaderElement(element), []);
  const scaleRef = useCallback((element: HTMLElement | null) => setScaleElement(element), []);

  const employeeFilter = useMemo(() => filters.employeeUuid, [filters]);
  const filteredSlotGroups = useMemo(() => {
    if (!employeeFilter) {
      return slotGroups;
    }

    return slotGroups.filter((slotGroup) => slotGroup.employee.accountUuid === employeeFilter);
  }, [slotGroups, employeeFilter]);
  const employees = useMemo(
    () => filteredSlotGroups.map((entry) => entry.employee),
    [slotGroups, employeeFilter],
  );
  const slotGroupsEmployeesCount = useMemo(() => employees.length, [employees]);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      const currentPeriodOffset = new Date().getHours() * 60 - AUTOSCROLL_OFFSET || 0;

      if (containerElement) {
        const offset =
          ((containerElement.scrollHeight -
            (headerElement?.offsetHeight || 0) -
            ((scaleElement?.firstChild as HTMLDivElement)?.offsetHeight || 0)) *
            currentPeriodOffset) /
          1440;
        containerElement.scrollTo({ top: offset, behavior: "smooth" });
      }
    });
    if (containerElement) observer.observe(containerElement);

    const calcIndicatorOffset = () => {
      const containerHeight = scaleElement?.offsetHeight || 0;
      const offsetHeight = (scaleElement?.firstChild as HTMLElement)?.offsetHeight || 0;
      const height = containerHeight - offsetHeight;
      const pxPerMinute = height > 0 ? height / MINUTES_PER_DAY : 0;
      const currentMinute = differenceInMinutes(new Date(), startOfDay(new Date()));

      setIndicatorOffset(currentMinute * pxPerMinute);
    };

    let interval: number;

    if (scaleElement) {
      interval = window.setInterval(calcIndicatorOffset, 1000);
    }

    return () => {
      if (scaleElement) clearInterval(interval);
      if (containerElement) observer.unobserve(containerElement);
    };
  }, [containerElement, headerElement, scaleElement]);

  const isSalonOwnerHasEmployees = slotGroups.length > 1 || filters.employeeUuid !== undefined;

  return (
    <div className="relative flex h-full flex-col">
      <div
        ref={containerRef}
        className="isolate flex flex-auto flex-col overflow-auto overscroll-none border-t bg-white sm:rounded-md sm:border">
        <div className="flex w-fit min-w-full flex-col 2xl:!w-full 2xl:max-w-full">
          {!isSm && <CalendarDaysNavigation ref={headerRef} />}

          {isSalonOwnerHasEmployees && (
            <>
              {/* A dumb element to cover a small piece of scrollable header */}
              <div className="absolute left-0 top-[90px] z-50 h-[42px] w-[42px] border bg-white sm:top-0 sm:h-[42px] sm:rounded-tl-md"></div>
              <CalendarDayEmployees ref={headerRef} employees={employees} />
            </>
          )}

          <div className="flex w-full min-w-max flex-auto pb-0 pt-[76px] sm:-mt-4 sm:pt-0">
            <div className="sticky left-0 z-30 w-10 flex-none bg-white/60 ring-1 ring-gray-100 backdrop-blur-sm"></div>
            <div className="relative grid flex-auto grid-cols-1 grid-rows-1">
              <Transition
                show={indicatorOffset > 0}
                appear
                as={Fragment}
                enter="transition-[opacity,_right] duration-500 ease-in"
                enterFrom="opacity-0 right-full"
                enterTo="opacity-80 right-0"
                leave="transition-[opacity,_right] duration-500 ease-in"
                leaveFrom="opacity-80 right-0"
                leaveTo="opacity-0 right-full">
                <div
                  className="border-1 absolute left-0 z-30 mt-7 h-[2px] border border-white border-opacity-25 bg-red-500 before:absolute before:top-1/2 before:block before:h-2 before:w-2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:bg-red-500 before:content-['']"
                  style={{ top: `${indicatorOffset}px` }}></div>
              </Transition>
              <CalendarScaleBar ref={scaleRef} />
              <CalendarDayAvailability selectedEmployeeUuid={employeeFilter} />
              <CalendarDayPlaceholder
                employees={employees}
                hasMoreThanOneEmployee={isSalonOwnerHasEmployees}
              />

              <ol
                className={clsx(
                  isSalonOwnerHasEmployees ? "sm:pr-8" : "sm:pr-0.5",
                  "col-start-1 col-end-2 row-start-1 grid",
                )}
                style={{
                  gridTemplateRows: `1.75rem repeat(${SCALE_STEPS_PER_DAY}, minmax(0, 1fr)) auto`,
                  gridTemplateColumns: `repeat(${slotGroupsEmployeesCount}, minmax(250px, 1fr))`,
                }}>
                {slotGroups.map((slotGroup: SlotRowsByEmployee, index) =>
                  slotGroup.slotRows.map((slotRow) => (
                    <CalendarSlotsGroup
                      key={slotRow.from}
                      group={slotRow}
                      employeeCount={!employeeFilter ? index : 0}
                    />
                  )),
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

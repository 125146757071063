import React, { ReactNode, ButtonHTMLAttributes } from "react";
import { NavLink, To } from "react-router-dom";

import clsx from "clsx";

import { Spinner } from "@/components/ui/Spinner";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  to?: To;
  variant?:
    | "primary"
    | "secondary"
    | "warning"
    | "danger"
    | "success"
    | "info"
    | "white"
    | "primary-inline"
    | "secondary-inline"
    | "warning-inline"
    | "danger-inline"
    | "success-inline"
    | "info-inline"
    | "primary-outline"
    | "secondary-outline"
    | "warning-outline"
    | "danger-outline"
    | "success-outline"
    | "info-outline"
    | "neutral";
  size?: "tiny" | "small" | "medium" | "large";
  fullWidth?: boolean;
  loading?: boolean;
};

const ButtonInner = ({
  children,
  startIcon,
  endIcon,
  size,
  variant,
}: Pick<ButtonProps, "children" | "startIcon" | "endIcon" | "size" | "variant">) => (
  <>
    {startIcon && (
      <span
        className={clsx("inline-flex", {
          "mr-1 h-3 w-3": size === "tiny",
          "mr-1.5 h-4 w-4": size === "small",
          "mr-2 h-5 w-5": size === "medium",
          "mr-2.5 h-6 w-6": size === "large",
          "-ml-0.5": !variant?.includes("inline-"),
        })}
        aria-hidden="true">
        {startIcon}
      </span>
    )}
    {children}
    {endIcon && (
      <span
        className={clsx("mr-1.5 inline-flex", {
          "mr-1 h-3 w-3": size === "tiny",
          "mb-0.5 mr-1.5 h-4 w-4": size === "small",
          "mr-2 h-5 w-5": size === "medium",
          "mr-2.5 h-6 w-6": size === "large",
          "ml-1": !variant?.includes("inline-"),
        })}
        aria-hidden="true">
        {endIcon}
      </span>
    )}
  </>
);

export const Button = ({
  children,
  startIcon,
  endIcon,
  variant = "primary",
  size = "medium",
  className,
  to,
  fullWidth = false,
  loading = false,
  ...delegated
}: ButtonProps) => {
  const isLink = !!to;
  const nonInlineVariants = [
    "primary",
    "secondary",
    "warning",
    "white",
    "danger",
    "success",
    "info",
    "primary-outline",
    "secondary-outline",
    "warning-outline",
    "danger-outline",
    "success-outline",
    "info-outline",
  ];
  const classes = clsx(
    variant === "neutral" ? "" : "button",
    {
      "button-primary": variant === "primary",
      "button-secondary": variant === "secondary",
      "button-warning": variant === "warning",
      "button-danger": variant === "danger",
      "button-success": variant === "success",
      "button-info": variant === "info",
      "button-white": variant === "white",
      "button-primary-inline": variant === "primary-inline",
      "button-secondary-inline": variant === "secondary-inline",
      "button-warning-inline": variant === "warning-inline",
      "button-danger-inline": variant === "danger-inline",
      "button-success-inline": variant === "success-inline",
      "button-info-inline": variant === "info-inline",
      "button-primary-outline": variant === "primary-outline",
      "button-secondary-outline": variant === "secondary-outline",
      "button-warning-outline": variant === "warning-outline",
      "button-danger-outline": variant === "danger-outline",
      "button-success-outline": variant === "success-outline",
      "button-info-outline": variant === "info-outline",
      "button-xs": size === "tiny",
      "button-sm": size === "small",
      "button-md": size === "medium",
      "button-lg": size === "large",
      "w-full": fullWidth && nonInlineVariants.includes(variant),
    },
    className,
  );

  return isLink ? (
    <NavLink to={to} className={classes} {...delegated}>
      <ButtonInner
        size={size}
        variant={variant}
        {...(loading ? { startIcon: <Spinner />, disabled: true } : { startIcon, endIcon })}>
        {children}
      </ButtonInner>
    </NavLink>
  ) : (
    <button className={classes} type="button" {...delegated}>
      <ButtonInner
        size={size}
        variant={variant}
        {...(loading ? { startIcon: <Spinner />, disabled: true } : { startIcon, endIcon })}>
        {children}
      </ButtonInner>
    </button>
  );
};

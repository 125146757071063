import { useTranslation } from "react-i18next";

import { EnvelopeIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { Button } from "@components/ui/Button";

import { useDialog, bindDialogState } from "@hooks/use-dialog";

import { GetSmsPackageDialog } from "@features/payments/components/GetSmsPackageDialog";

import { useCountersStore, useSessionStore } from "@features/session/hooks";

import { Spinner } from "@/components/ui/Spinner";

export const TopUpSmsAction = () => {
  const { t } = useTranslation();
  const { isOwner } = useSessionStore();
  const { smsLimit, loadingSmsLimit } = useCountersStore();
  const dialogState = useDialog();

  return (
    <>
      <Button
        variant="neutral"
        className={clsx(
          "relative inline-flex w-full items-center justify-center gap-1 rounded-sm bg-white px-4 py-2 text-sm hover:bg-gray-50 focus:outline-none md:w-auto",
          smsLimit > 0 ? "text-gray-500" : "text-red-500",
        )}
        disabled={!isOwner}
        onClick={() => dialogState.open()}>
        {loadingSmsLimit ? (
          <div className="px-16">
            <Spinner />
          </div>
        ) : (
          <>
            <EnvelopeIcon className="h-4 w-4" />
            <span>{t("header.smsMenu.quantityLeft", [smsLimit])}</span>
          </>
        )}
      </Button>
      <GetSmsPackageDialog {...bindDialogState(dialogState)} />
    </>
  );
};

import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import { Dialog, Transition } from "@headlessui/react";

import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useEmployeesStore } from "@features/employees/hooks";

import { Spinner } from "@/components/ui/Spinner";
import { useSessionStore } from "@/features/session/hooks";
import { Employee } from "@/types";

import { EmployeeListActions } from "./EmployeeListActions";
import { EmployeeProfile } from "./EmployeeProfile";
import { EmployeesDirectory } from "./EmployeesDirectory";

export const EmployeeList = () => {
  const { ref, height } = useResizeDetector();

  const { loading, employees, updateEmployeesPositions } = useEmployeesStore();
  const { isOwner } = useSessionStore();

  const { t } = useTranslation();

  const [asideHeight, setAsideHeight] = useState(height);
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
  const [isDraggable, setIsDraggable] = useState(false);
  const [tempEmployees, setTempEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    setAsideHeight(height);
  }, [height]);

  useEffect(() => {
    if (selectedEmployee) {
      return;
    }

    const firstEmployee = employees?.[0];
    if (firstEmployee) {
      setSelectedEmployee(firstEmployee);
    }
  }, [employees.length, selectedEmployee]);

  const handleEmployeeSelect = (employee: Employee) => {
    setSelectedEmployee(employee);
    setOpen(true);
  };

  const handleOrder = useCallback(() => {
    if (!isOwner) return;

    setIsDraggable((prev) => !prev);

    if (isDraggable) {
      setTempEmployees(employees);
    } else {
      setTempEmployees([]);
    }
  }, []);

  const handleSaveOrder = useCallback(() => {
    if (tempEmployees.length > 0) {
      const data = {
        sortedEmployeesUuids: tempEmployees.map((employee) => employee.accountUuid),
      };

      updateEmployeesPositions({ data: data });
    }

    setIsDraggable(false);
  }, [tempEmployees, updateEmployeesPositions]);

  if (loading || !selectedEmployee) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  return (
    <div className="@container">
      <div className="md:min-h-auto relative z-0 -mx-4 mt-6 flex min-h-[60vh] flex-1 overflow-hidden rounded-lg bg-white px-4 pt-1 @[670px]:m-0 @[670px]:p-0 sm:mt-0 sm:pt-0">
        <main
          ref={ref}
          className="custom-client-profile-height relative z-0 order-last hidden h-full overflow-y-auto border-l border-gray-200 focus:outline-none @[670px]:flex @[670px]:flex-1">
          <EmployeeProfile selectedEmployee={selectedEmployee} />
        </main>
        <aside
          style={{ maxHeight: asideHeight ? `${asideHeight}px` : "100%" }}
          className="order-first flex w-full flex-col @[670px]:w-72 lg:w-80 xl:w-96">
          <div className="mb-1 @[670px]:pb-28">
            {isOwner && employees.length > 1 && (
              <EmployeeListActions
                isDraggable={isDraggable}
                handleOrder={handleOrder}
                handleSaveOrder={handleSaveOrder}
              />
            )}
            <EmployeesDirectory
              employees={
                isDraggable ? (tempEmployees.length > 0 && tempEmployees) || employees : employees
              }
              selectedEmployee={selectedEmployee}
              handleSelect={handleEmployeeSelect}
              isDraggable={isDraggable}
              setTempEmployees={setTempEmployees}
            />
          </div>
        </aside>

        {!asideHeight && (
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="m-0 flex min-h-full items-center justify-center text-center sm:mx-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden bg-white text-left shadow-xl transition-all xs:rounded-lg xs:px-4 sm:my-8 sm:p-6 sm:pb-4 sm:pt-5">
                      <nav
                        className="-ml-2 mt-4 flex items-start px-4 sm:hidden"
                        aria-label="Breadcrumb">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="inline-flex items-center space-x-2 px-2.5 py-1.5 text-xs font-medium text-gray-500 transition-colors hover:text-gray-700">
                          <ChevronLeftIcon
                            className="-ml-2 h-4 w-4 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="underline">{t("clients.clientsDirectory")}</span>
                        </button>
                      </nav>
                      <nav
                        className="absolute right-5 top-4 z-10 hidden sm:flex"
                        aria-label="Breadcrumb">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="rounded-full border border-gray-300 p-1 text-gray-400 shadow-sm transition-colors hover:border-gray-400 hover:bg-gray-50 hover:text-gray-500">
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </nav>
                      <EmployeeProfile selectedEmployee={selectedEmployee} />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </div>
    </div>
  );
};

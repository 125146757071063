import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { AppNestedProviders } from "@providers/AppNestedProviders";
import { PostHogProviderWrapper } from "@providers/PostHogProviderWrapper";

import { ErrorFallback } from "@/components/layout/ErrorFallback";
import { Meta } from "@/components/Meta";
import { FlagsProvider } from "@/features/feature-flags/providers/FlagsContext";
import { ApolloClientProvider } from "@/providers/ApolloClientProvider";

import App from "./App";

import "./main.css";
import "./lib/i18n";

const { VITE_SENTRY_DSN, MODE, VITE_BRANCH } = import.meta.env;

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  environment: VITE_BRANCH ?? MODE,
  sampleRate: 1,
  enabled: !!VITE_SENTRY_DSN && MODE !== "development",
});

const rootElement = document.getElementById("root");

const root = createRoot(rootElement as Element);

root.render(
  <AppNestedProviders>
    <StrictMode>
      <PostHogProviderWrapper>
        <Sentry.ErrorBoundary
          fallback={<ErrorFallback />}
          beforeCapture={(scope) => {
            scope.setTag("location", "main");
          }}>
          <HelmetProvider>
            <Meta />
            <BrowserRouter>
              <ApolloClientProvider>
                <FlagsProvider>
                  <App />
                </FlagsProvider>
              </ApolloClientProvider>
            </BrowserRouter>
          </HelmetProvider>
        </Sentry.ErrorBoundary>
      </PostHogProviderWrapper>
    </StrictMode>
  </AppNestedProviders>,
);

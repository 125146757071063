import { NetworkStatus } from "@apollo/client";
import { useState, useEffect } from "react";

import { useDebounce } from "@/hooks/use-debounce";
import { namedOperations } from "@/types";

import { useUpdateTreatmentsPositionsMutation } from "../mutations/UpdateTreatmentsPositions.generated";
import { useFetchTreatmentsQuery } from "../queries/FetchTreatments.generated";

export const useTreatmentsStore = (categoryUuid?: string) => {
  const { data, networkStatus, fetchMore, refetch, error } = useFetchTreatmentsQuery({
    variables: { first: 10, categoryUuid },
    skip: !categoryUuid,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    console.error("There was an error fetching treatments:", error);
  }

  const [updateTreatmentsPositions, { loading: loadingUpdate }] =
    useUpdateTreatmentsPositionsMutation();

  const [searchQuery, setSearchQuery] = useState<string>();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      refetch({ query: debouncedSearchQuery || undefined });
    }
  }, [debouncedSearchQuery]);

  return {
    loading: [NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus),
    loadingFetchMore: networkStatus === NetworkStatus.fetchMore,
    loadingUpdate: loadingUpdate,
    treatments: data?.fetchTreatments.edges.map(({ node }) => node) || [],
    count: data?.fetchTreatments.count || 0,
    hasMore: data?.fetchTreatments.pageInfo.hasNextPage,
    refetchWithQuery(query: string) {
      setSearchQuery(query);
      if (query !== undefined) {
        refetch({ query: query });
      }
    },
    fetchMore() {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.fetchTreatments.pageInfo.endCursor,
          },
        });
      }
    },
    updateTreatmentsPositions({
      data,
    }: {
      data: { categoryUuid: string; sortedTreatmentUuids: string[] };
    }) {
      return updateTreatmentsPositions({
        variables: {
          data,
        },
        refetchQueries: [namedOperations.Query.FetchTreatments],
        update(cache) {
          cache.evict({ fieldName: "fetchAllTreatments" });
          cache.gc();
        },
      });
    },
  };
};

import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SalonSmsDetailsFragment = { __typename?: 'SalonSmsDetails', senderName: string, senderNameApproved: boolean, sendReminders: boolean };

export const SalonSmsDetailsFragmentDoc = gql`
    fragment SalonSmsDetails on SalonSmsDetails {
  senderName
  senderNameApproved
  sendReminders
}
    `;
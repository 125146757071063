import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Transition } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { Logo } from "@components/branding/Logo";
import { LogoSignet } from "@components/branding/LogoSignet";
import { NavigationItems } from "@components/layout/sidebar/NavigationItems";
import { PricingCTA } from "@components/layout/sidebar/PricingCTA";
import { Button } from "@components/ui/Button";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { AppointmentsLimitBar } from "@features/calendar/components/AppointmentsLimitBar";
import { TopUpSmsAction } from "@features/payments/components/TopUpSmsAction";

import { useSessionStore } from "@features/session/hooks";

type SidebarProps = {
  isOpen: boolean;
  isShrinked: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const Sidebar = ({ isOpen, setIsOpen, isShrinked }: SidebarProps) => {
  const { t } = useTranslation();
  const { isFreePlan } = useSessionStore();
  const { isMd } = useBreakpoint("md");

  return isMd ? (
    <div
      className={clsx(
        isShrinked ? "w-16" : "w-48 xl:w-56",
        "fixed inset-y-0 flex flex-col transition-all duration-300 ease-in-out",
      )}>
      <div className="flex h-screen flex-grow flex-col border-r border-gray-200 bg-white pt-3">
        <div className="shrink-0 px-4">
          <Button variant="neutral" to="/calendar" className="flex p-0">
            {isShrinked ? <LogoSignet className="h-9" /> : <Logo className="mt-3 h-10" />}
          </Button>
        </div>
        <div className="mt-5 flex flex-grow flex-col overflow-y-auto">
          <NavigationItems isSidebarShrinked={isShrinked} />
        </div>
        {isFreePlan && (
          <Transition
            show={!isShrinked}
            as="div"
            enter="transition-all ease-linear duration-100 delay-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-all ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 -translate-x-full scale-0">
            <div className="p-2">
              <AppointmentsLimitBar />
            </div>
          </Transition>
        )}
      </div>
    </div>
  ) : (
    <Transition.Root show={isOpen} as={Fragment} unmount>
      <div className="relative z-[50]">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div
            className="fixed inset-0 bg-gray-600 bg-opacity-75"
            onClick={() => setIsOpen(false)}
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full">
          <div className="fixed inset-0 z-40 grid h-screen w-full max-w-xs grid-rows-[auto_1fr_auto] bg-white">
            <div className="flex flex-col items-center px-4 pt-5">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="absolute right-0 top-2 sm:-right-12">
                  <Button
                    variant="neutral"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setIsOpen(false)}>
                    <span className="sr-only">{t("sidebar.accesibility.closeSidebar")}</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white mix-blend-difference"
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              </Transition.Child>
              <Button variant="neutral" to="/calendar">
                <Logo className="h-9" />
              </Button>
              <TopUpSmsAction />
            </div>
            <div className="overflow-y-auto">
              <NavigationItems isSidebarShrinked={isShrinked} />
            </div>
            <div>
              <PricingCTA />
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";

import {
  ArrowTopRightOnSquareIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  PencilIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

import clsx from "clsx";

import { Button } from "@/components/ui/Button";
import { SendSMSDialog } from "@/features/messages/components/SendSMSDialog";
import { useCountersStore, useSessionStore } from "@/features/session/hooks";
import { Client } from "@/types";

type AppointmentClientMenuProps = {
  client: Client;
  onClose: () => void;
};

export const AppointmentClientMenu = ({ client, onClose }: AppointmentClientMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { smsLimit } = useCountersStore();
  const { permissions } = useSessionStore();

  const [open, setOpen] = useState(false);

  const MENU_ITEMS = [
    {
      label: t("generic.call"),
      to: client.phone && permissions.client_details_view ? `tel:${client.phone}` : undefined,
      icon: (active: boolean) => (
        <PhoneIcon
          className={clsx({
            "text-gray-700": active && client.phone && permissions.client_details_view,
            "cursor-not-allowed text-gray-400": !client.phone || !permissions.client_details_view,
          })}
          aria-hidden="true"
        />
      ),
      isAvailable: !!client.phone && permissions.client_details_view,
    },
    {
      label: t("actions.sendSms"),
      action: () => setOpen(true),
      icon: (active: boolean) => (
        <EnvelopeIcon
          className={clsx({
            "text-gray-700": active && client.phone && smsLimit > 0 && permissions.send_sms,
            "cursor-not-allowed text-gray-400":
              !client.phone || smsLimit === 0 || !permissions.send_sms,
          })}
          aria-hidden="true"
        />
      ),
      isAvailable: !!client.phone && smsLimit > 0 && permissions.send_sms,
    },
    {
      label: t("clients.edit"),
      action: () => {
        onClose();
        navigate(`/clients/${client.uuid}/edit`);
      },
      icon: (active: boolean) => (
        <PencilIcon
          className={clsx({
            "text-gray-700": active && permissions.edit_client,
            "cursor-not-allowed text-gray-400": !permissions.edit_client,
          })}
          aria-hidden="true"
        />
      ),
      isAvailable: permissions.edit_client,
    },
    {
      label: t("actions.goToProfile"),
      action: () => {
        onClose();
        navigate(`/clients/${client.uuid}`);
      },
      icon: (active: boolean) => (
        <ArrowTopRightOnSquareIcon
          className={clsx({
            "text-gray-700": active && permissions.client_details_view,
            "cursor-not-allowed text-gray-400": !permissions.client_details_view,
          })}
          aria-hidden="true"
        />
      ),
      isAvailable: permissions.client_details_view,
    },
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className="mt-1 inline-flex w-full items-center justify-center rounded-md"
        as="div">
        <Button variant="neutral" className="-mx-2 w-fit p-2 focus:outline-none focus:ring-0">
          <EllipsisHorizontalIcon className="h-6 w-6" />
        </Button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items
          as="ul"
          className="absolute right-1 z-10 -mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-gray-300 focus:outline-none">
          {MENU_ITEMS.map((item, index) => (
            <Menu.Item key={index} as="li">
              {({ active }) => (
                <Button
                  startIcon={item.icon && item.icon(active)}
                  variant="neutral"
                  className={clsx(
                    item.isAvailable ? "text-gray-700" : "text-gray-400",
                    "flex w-full whitespace-nowrap px-4 py-2.5 text-xs font-normal first-of-type:rounded-t-md last-of-type:rounded-b-md",
                    {
                      "cursor-not-allowed": !item.isAvailable,
                      "bg-gray-50 text-gray-700": active && item.isAvailable,
                    },
                  )}
                  disabled={!item.isAvailable}
                  size="small"
                  to={item.to ? item.to : undefined}
                  onClick={item.action}>
                  {item.label}
                </Button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
      <SendSMSDialog open={open} setOpen={setOpen} client={client} />
    </Menu>
  );
};

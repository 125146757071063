import React, { useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRightIcon } from "@heroicons/react/24/outline";

import {
  SCALE_STEPS_PER_DAY,
  SCALE_MINUTES_PER_STEP,
  DEFAULT_APPOINTMENT_DURATION_STEPS,
  NUMBER_OF_DAYS_IN_WEEK,
} from "@features/calendar/constants";
import clsx from "clsx";

import { Button } from "@components/ui/Button";
import { Popover } from "@components/ui/Popover";

import { useSessionStore, useCountersStore } from "@features/session/hooks";

import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import { getDateWithOffset, getTimeWithOffset } from "@/features/calendar/utils/time";
import { Timerange } from "@/types";
import { sendEvent } from "@/utils/google-analytics";

const calculateRange = (element: HTMLDivElement, clientY: number): Timerange => {
  const { top, height } = element.getBoundingClientRect();
  const dy = Math.floor(((clientY - top) * SCALE_STEPS_PER_DAY) / height);
  const from = getTimeWithOffset(dy, SCALE_MINUTES_PER_STEP);
  const to = getTimeWithOffset(dy + DEFAULT_APPOINTMENT_DURATION_STEPS, SCALE_MINUTES_PER_STEP);

  return { from, to };
};

export const CalendarEmployeeWeekPlaceholder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isFreePlan, permissions } = useSessionStore();
  const { remainingAppointments, loadingRemainingAppointments } = useCountersStore();
  const { timeRange, addSlot, filters } = useCalendarContext();

  const isAbleToAddAppointment = useMemo(
    () => !isFreePlan || remainingAppointments > 0,
    [isFreePlan, remainingAppointments],
  );

  const handleColClick = (
    event: MouseEvent<HTMLDivElement>,
    employeeUuid: string,
    index: number,
  ) => {
    if (!isAbleToAddAppointment || (!permissions.add_appointment && !permissions.add_slot_block))
      return;

    const { from, to } = calculateRange(event.target as HTMLDivElement, event.clientY);

    addSlot({
      date: getDateWithOffset(timeRange.from, index),
      from,
      to,
      employeeUuid,
    });
  };

  const employeeUuid = useMemo(() => filters.employeeUuid, [filters]);

  return (
    <Popover
      disabled={loadingRemainingAppointments || isAbleToAddAppointment}
      takeMousePosition
      render={({ close }) => (
        <div className="max-w-sm">
          <h5>{t("appointments.reachedLimit")}</h5>
          <Button
            variant="neutral"
            fullWidth
            className="mt-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-500 px-3 py-2 text-sm uppercase leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            onClick={() => {
              sendEvent("upgrade_cta", "payments", "calendar_no_appointments_left");
              close();
              navigate("/subscriptions");
            }}>
            {t("header.salonMenu.removeLimits")}
            <ArrowRightIcon className="-mr-0.5 ml-2 h-4 w-4" aria-hidden="true" />
          </Button>
        </div>
      )}>
      <div
        className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-gray-200"
        style={{ gridTemplateColumns: `repeat(${NUMBER_OF_DAYS_IN_WEEK}, minmax(250px, 1fr))` }}>
        {new Array(NUMBER_OF_DAYS_IN_WEEK).fill({}).map((_, index) => (
          <div
            key={index}
            className={clsx(
              "z-10 row-span-full mt-7 opacity-30 hover:bg-violet-50",
              `col-start-${index + 1}`,
            )}
            onClick={(event) => handleColClick(event, employeeUuid, index)}></div>
        ))}
        <div className="col-start-8 row-span-full w-8"></div>
      </div>
    </Popover>
  );
};

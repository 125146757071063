import React, { useState, useMemo, MouseEvent, TouchEvent, Touch } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRightIcon } from "@heroicons/react/24/outline";

import {
  SCALE_STEPS_PER_DAY,
  SCALE_MINUTES_PER_STEP,
  DEFAULT_APPOINTMENT_DURATION_STEPS,
  TOUCH_EVENT_TIME_LIMIT,
  TOUCH_EVENT_DISTANCE_LIMIT,
} from "@features/calendar/constants";
import clsx from "clsx";

import { Button } from "@components/ui/Button";
import { Popover } from "@components/ui/Popover";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useSessionStore, useCountersStore } from "@features/session/hooks";

import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import { getTimeWithOffset } from "@/features/calendar/utils/time";
import { Timerange, CalendarEmployee } from "@/types";
import { sendEvent } from "@/utils/google-analytics";

const calculateRange = (element: HTMLDivElement, clientY: number): Timerange => {
  const { top, height } = element.getBoundingClientRect();
  const dy = Math.floor(((clientY - top) * SCALE_STEPS_PER_DAY) / height);
  const from = getTimeWithOffset(dy, SCALE_MINUTES_PER_STEP);
  const to = getTimeWithOffset(dy + DEFAULT_APPOINTMENT_DURATION_STEPS, SCALE_MINUTES_PER_STEP);

  return { from, to };
};

type CalendarDayPlaceholderProps = {
  employees: Array<CalendarEmployee>;
  hasMoreThanOneEmployee: boolean;
};

export const CalendarDayPlaceholder = ({
  employees,
  hasMoreThanOneEmployee,
}: CalendarDayPlaceholderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSm } = useBreakpoint("sm");
  const [startTouch, setStartTouch] = useState<Touch | null>(null);

  const { isFreePlan, permissions } = useSessionStore();
  const { remainingAppointments, loadingRemainingAppointments } = useCountersStore();
  const { selectedDate, addNewAppointment, addSlot } = useCalendarContext();

  const isAbleToAddAppointment = useMemo(
    () => !isFreePlan || remainingAppointments > 0,
    [isFreePlan, remainingAppointments],
  );

  const handleColClick = (event: MouseEvent<HTMLDivElement>, employeeUuid: string) => {
    if (!isAbleToAddAppointment || (!permissions.add_appointment && !permissions.add_slot_block))
      return;

    const { from, to } = calculateRange(event.target as HTMLDivElement, event.clientY);

    addSlot({
      date: selectedDate,
      from,
      to,
      employeeUuid,
    });
  };

  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    if (!isAbleToAddAppointment || (!permissions.add_appointment && !permissions.add_slot_block))
      return;

    setStartTouch(event.touches[0]);

    setTimeout(() => setStartTouch(null), TOUCH_EVENT_TIME_LIMIT);
  };

  const handleTouchEnd = (event: TouchEvent<HTMLDivElement>, employeeUuid: string) => {
    if (startTouch) {
      const endTouch = event.changedTouches[0];
      const dx = Math.pow(startTouch.pageX - endTouch.pageX, 2);
      const dy = Math.pow(startTouch.pageY - endTouch.pageY, 2);
      const distance = Math.round(Math.sqrt(dx + dy));

      if (distance <= TOUCH_EVENT_DISTANCE_LIMIT) {
        const { from, to } = calculateRange(event.target as HTMLDivElement, endTouch.clientY);

        addNewAppointment({
          date: selectedDate,
          from,
          to,
          employeeUuid,
        });
      }
    }
  };

  return (
    <Popover
      disabled={loadingRemainingAppointments || isAbleToAddAppointment}
      takeMousePosition
      render={({ close }) => (
        <div className="max-w-sm">
          <h5>{t("appointments.reachedLimit")}</h5>
          <Button
            variant="neutral"
            fullWidth
            className="mt-4 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-500 px-3 py-2 text-sm uppercase leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            onClick={() => {
              sendEvent("upgrade_cta", "payments", "calendar_no_appointments_left");
              close();
              navigate("/subscriptions");
            }}>
            {t("header.salonMenu.removeLimits")}
            <ArrowRightIcon className="-mr-0.5 ml-2 h-4 w-4" aria-hidden="true" />
          </Button>
        </div>
      )}>
      {isSm ? (
        <div
          className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-gray-200"
          style={{ gridTemplateColumns: `repeat(${employees.length}, minmax(250px, 1fr))` }}>
          {employees.map((employee, index) => (
            <div
              key={employee.accountUuid}
              className={clsx(
                "z-10 row-span-full mt-7 opacity-30 hover:bg-violet-50",
                `col-start-${index + 1}`,
              )}
              onClick={(event) => handleColClick(event, employee.accountUuid)}></div>
          ))}
          {hasMoreThanOneEmployee && (
            <div className="col-start-8 row-span-full hidden w-8 sm:block"></div>
          )}
        </div>
      ) : (
        <>
          <div
            className="col-start-1 col-end-2 row-start-1 grid grid-rows-1 divide-x divide-gray-200"
            style={{ gridTemplateColumns: `repeat(${employees.length}, minmax(0, 1fr))` }}>
            {employees.map((employee, index) => (
              <div
                className={clsx("z-10 row-span-full mt-7", `col-start-${index + 1}`)}
                key={employee.accountUuid}
                onTouchStart={handleTouchStart}
                onTouchEnd={(event) => handleTouchEnd(event, employee.accountUuid)}></div>
            ))}
            {hasMoreThanOneEmployee && (
              <div className="col-start-8 row-span-full hidden w-8 sm:block"></div>
            )}
          </div>
        </>
      )}
    </Popover>
  );
};

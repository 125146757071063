import { useEffect, useState, Fragment } from "react";
import { useForm, useFieldArray, SubmitHandler, UseFieldArrayReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

import { timerangeIsValid } from "@utils/datetime";

import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";
import { Switch } from "@components/ui/Switch";
import { TimeField } from "@components/ui/TimeField";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useSalonOpeningHoursStore } from "../hooks";
import { RegularAvailability } from "../models/specialHours";
import { decodeRegularAvailability, weekdays } from "../utils";

type SalonOpeningHoursFormProps = {
  onSubmit: () => void;
};

export const SalonOpeningHoursForm = ({ onSubmit }: SalonOpeningHoursFormProps) => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint("md");
  const [enabledWeekdays, setEnabledWeekdays] = useState<Record<string, boolean>>({});
  const { regular, loading, update } = useSalonOpeningHoursStore();

  const {
    control,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    handleSubmit,
  } = useForm<RegularAvailability>({
    mode: "all",
    defaultValues: weekdays.reduce((data: RegularAvailability, weekday: string) => {
      return {
        ...data,
        [weekday]: {
          is_open: true,
          ranges: [{ from: "", to: "" }],
        },
      };
    }, {}),
  });

  const fields = weekdays.reduce(
    (fields: Record<string, UseFieldArrayReturn<RegularAvailability>>, weekday) => {
      fields[weekday] = useFieldArray({
        control,
        name: `${weekday}.ranges`,
      });
      return fields;
    },
    {},
  );

  const handleFieldAppend = (weekday: string) => {
    fields[weekday].append({ from: "", to: "" });
  };

  const handleFieldRemove = (weekday: string, index: number) => {
    fields[weekday].remove(index);
  };

  const checkAndRemoveEmptyRanges = (weekday: string) => {
    for (let index = fields[weekday].fields.length - 1; index >= 0; index--) {
      if (
        !(
          getValues(`${weekday}.ranges.${index}.from`) && getValues(`${weekday}.ranges.${index}.to`)
        )
      ) {
        handleFieldRemove(weekday, index);
      }
    }
  };

  const handleWeekdayToggle = (weekday: string, is_open: boolean) => {
    setValue(`${weekday}.is_open`, is_open);
    setEnabledWeekdays((map) => ({
      ...map,
      [weekday]: is_open,
    }));
    if (!is_open) {
      checkAndRemoveEmptyRanges(weekday);
    }
  };

  const handleUpdateOpeningHours: SubmitHandler<RegularAvailability> = async (data) => {
    await update({ regular: JSON.stringify(decodeRegularAvailability(data)) });
    onSubmit();
  };

  useEffect(() => {
    if (regular) {
      reset(regular);
      setEnabledWeekdays(
        weekdays.reduce((disabledMap: Record<string, boolean>, weekday) => {
          disabledMap[weekday] = regular[weekday].is_open;
          return disabledMap;
        }, {}),
      );
    }
  }, [loading]);

  return loading ? (
    <div className="flex h-[50vh] items-center">
      <Spinner />
    </div>
  ) : (
    <form className="space-y-4" onSubmit={handleSubmit(handleUpdateOpeningHours)} noValidate>
      <div className="mt-8 flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {isMd && (
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                        {t("openingHours.weekDay")}
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold  text-gray-900">
                      {t("openingHours.open")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 ">
                      {t("openingHours.event.openFromTo")}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {weekdays.map((weekday) => (
                    <Fragment key={weekday}>
                      {isMd || (
                        <tr>
                          <td
                            colSpan={3}
                            className="whitespace-nowrap bg-gray-100 px-1 py-2 text-center text-sm font-medium text-gray-900 sm:px-3 sm:pl-6">
                            {t(`weekDays.${weekday}`)}
                          </td>
                        </tr>
                      )}

                      <tr>
                        {isMd && (
                          <td className="whitespace-nowrap px-1 py-4 align-top text-sm font-medium text-gray-900 sm:pl-6 sm:pr-3">
                            {t(`weekDays.${weekday}`)}
                          </td>
                        )}
                        <td className="whitespace-nowrap px-1 py-4 align-top text-sm text-gray-500 sm:px-3">
                          <div className="flex h-full w-full justify-center">
                            <Switch
                              control={control}
                              name={`${weekday}.is_open`}
                              onChange={(_, is_open) => handleWeekdayToggle(weekday, is_open)}
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-1 py-1 text-center text-sm text-gray-500 sm:px-3 sm:py-3">
                          {enabledWeekdays[weekday] ? (
                            <>
                              {fields[weekday].fields.map((field, index) => (
                                <div
                                  className="flex h-full w-full items-center justify-center gap-2"
                                  key={field.id}>
                                  <TimeField
                                    control={control}
                                    name={`${weekday}.ranges.${index}.from`}
                                    rules={{
                                      required: enabledWeekdays[weekday],
                                      deps: [`${weekday}.ranges.${index}.to`],
                                      validate: (value) =>
                                        timerangeIsValid(
                                          value,
                                          getValues(`${weekday}.ranges.${index}.to`),
                                        ),
                                    }}
                                    disabled={!enabledWeekdays[weekday]}
                                    isError={!!(errors[weekday]?.ranges || [])[index]?.from}
                                    className="!py-1"
                                  />
                                  &mdash;
                                  <TimeField
                                    control={control}
                                    name={`${weekday}.ranges.${index}.to`}
                                    rules={{
                                      required: enabledWeekdays[weekday],
                                      deps: [`${weekday}.ranges.${index}.from`],
                                      validate: (value) =>
                                        timerangeIsValid(
                                          getValues(`${weekday}.ranges.${index}.from`),
                                          value,
                                        ),
                                    }}
                                    disabled={!enabledWeekdays[weekday]}
                                    isError={!!(errors[weekday]?.ranges || [])[index]?.to}
                                    className="!py-1"
                                  />
                                  <div className="flex h-6 w-6 items-center justify-center gap-2">
                                    {enabledWeekdays[weekday] &&
                                      fields[weekday].fields.length !== 1 && (
                                        <Button
                                          variant="neutral"
                                          onClick={() => handleFieldRemove(weekday, index)}>
                                          <TrashIcon className="h-4 w-4 text-red-600" />
                                        </Button>
                                      )}
                                  </div>
                                </div>
                              ))}
                              <div className="flex justify-center pt-1">
                                <Button
                                  variant="primary-inline"
                                  startIcon={<PlusIcon />}
                                  onClick={() => handleFieldAppend(weekday)}
                                  className="mr-8">
                                  {t("openingHours.add")}
                                </Button>
                              </div>
                            </>
                          ) : (
                            t("openingHours.event.isOpen.closed")
                          )}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Button type="submit" fullWidth disabled={!isValid} className="max-w-xs">
          {t("generic.save")}
        </Button>
      </div>
    </form>
  );
};

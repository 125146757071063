import { useMemo } from "react";

import { useBlockSlotMutation } from "@/features/calendar/mutations/BlockSlot.generated";
import { useUpdateBlockedSlotMutation } from "@/features/calendar/mutations/UpdateBlockedSlot.generated";
import { BlockSlotInput, namedOperations, UpdateBlockedSlotInput } from "@/types";

import { CalendarViews } from "../models";
import { useDeleteBlockedSlotMutation } from "../mutations/DeleteBlockedSlot.generated";
import { useCalendarContext } from "../providers/CalendarProvider";

export const useBlockedSlotStore = () => {
  const [block] = useBlockSlotMutation();
  const [update] = useUpdateBlockedSlotMutation();
  const [deleteSlot] = useDeleteBlockedSlotMutation();

  const { view } = useCalendarContext();

  const isMonthView = view === CalendarViews.Month;

  const fetchCalendarQuery = useMemo(() => {
    if (isMonthView) {
      return namedOperations.Query.FetchCalendar;
    }
    return namedOperations.Query.FetchCalendarByEmployee;
  }, [view]);

  return {
    block(data: BlockSlotInput) {
      return block({
        variables: {
          data,
        },
        refetchQueries: [fetchCalendarQuery],
      });
    },
    update(data: UpdateBlockedSlotInput) {
      return update({
        variables: {
          data,
        },
        refetchQueries: [fetchCalendarQuery],
      });
    },
    deleteSlot(uuid: string) {
      return deleteSlot({
        variables: {
          uuid,
        },
        refetchQueries: [fetchCalendarQuery],
        update: (cache) => {
          cache.evict({ id: `BlockedSlot:${uuid}` });
          cache.gc();
        },
      });
    },
  };
};

import { createContext, useState, ReactNode, useContext, useMemo } from "react";

import { useBreakpoint } from "@hooks/use-breakpoint";
import { useDialog, bindDialogState } from "@hooks/use-dialog";

import {
  AppointmentCancelDialog,
  AppointmentCancelProps,
} from "@features/calendar/components/Appointment/AppointmentCancelDialog";

import {
  AppointmentDetailsDialog,
  AppointmentDetailsProps,
} from "@/features/calendar/components/Appointment/AppointmentDetailsDialog";
import { BlockedSlotDialog } from "@/features/calendar/components/BlockedSlot/BlockedSlotDialog";
import { CalendarFiltersDialog } from "@/features/calendar/components/CalendarFiltersDialog";
import { SlotAddDialog, ColumnClickSlotProps } from "@/features/calendar/components/SlotAddDialog";
import { dateAdd, dateSub, getPeriodTimeRange } from "@/features/calendar/utils/time";
import {
  isCalendarBlockedSlot,
  isAppointmentTreatmentSlot,
} from "@/features/calendar/utils/type-guards";
import { Timerange, AppointmentFilters, AppointmentStatus } from "@/types";

import { BlockedSlotFormProps } from "../components/BlockedSlot/BlockedSlotForm";
import { GENERAL_CALENDAR_VIEWS as allowedViewsToLocalSave } from "../constants";
import { CalendarViews, UnknownCalendarSlot } from "../models";

type CalendarContextProps = {
  view: CalendarViews;
  setView: (data: CalendarViews) => void;
  timeRange: Timerange;
  setTimeRange: (data: Timerange) => void;
  filters: Omit<AppointmentFilters, "timeRange">;
  setFilters: (data: Omit<AppointmentFilters, "timeRange">) => void;
  selectedDate: Date;
  setSelectedDate: (data: Date) => void;
  nextRange: () => void;
  prevRange: () => void;
  addNewBlockedSlot: () => void;
  addNewAppointment: (data?: ColumnClickSlotProps) => void;
  showAppointment: (data?: AppointmentDetailsProps) => void;
  cancelAppointment: (data?: AppointmentCancelProps) => void;
  addSlot: (data?: ColumnClickSlotProps) => void;
  showFilters: () => void;
  selectAppointment: (data: UnknownCalendarSlot) => void;
  goToDay: (date: Date, view: CalendarViews) => void;
  nextWeek: () => void;
  prevWeek: () => void;
};

export const CalendarContext = createContext<CalendarContextProps>({} as CalendarContextProps);

export const CalendarProvider = ({ children }: { children: ReactNode }) => {
  const { isSm } = useBreakpoint("sm");
  const savedCalendarView = localStorage.getItem("defaultCalendarView");
  const defaultView =
    savedCalendarView && Object.values(CalendarViews).includes(savedCalendarView)
      ? Object.values(CalendarViews).indexOf(savedCalendarView)
      : isSm
      ? CalendarViews.Day
      : CalendarViews.Week;
  const [view, setView] = useState<CalendarViews>(defaultView);
  const [timeRange, setTimeRange] = useState<Timerange>(() =>
    getPeriodTimeRange(new Date(), defaultView),
  );
  const [filters, setFilters] = useState<Omit<AppointmentFilters, "timeRange">>({});
  const [selectedDate, setSelectedDate] = useState<Date>(() => new Date());
  const addBulkDialogState = useDialog<ColumnClickSlotProps>();
  const detailsDialogState = useDialog<AppointmentDetailsProps>();
  const cancelDialogState = useDialog<AppointmentCancelProps>();
  const filtersDialogState = useDialog();
  const blockedSlotDialogState = useDialog<BlockedSlotFormProps>();
  const addSlotDialogState = useDialog<ColumnClickSlotProps>();

  const handleSetView: CalendarContextProps["setView"] = (data) => {
    setView(data);
    setTimeRange(getPeriodTimeRange(selectedDate, data));

    if (allowedViewsToLocalSave.includes(data)) {
      localStorage.setItem(
        "defaultCalendarView",
        Object.keys(CalendarViews)[Object.values(CalendarViews).indexOf(data)],
      );
    }
  };

  const handleGoToDay: CalendarContextProps["goToDay"] = (date: Date, view: CalendarViews) => {
    setSelectedDate(date);
    setTimeRange(getPeriodTimeRange(date, view));
    setView(view);
  };

  const handleSetFilters: CalendarContextProps["setFilters"] = (data) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...data }));
  };

  const handleNextRange: CalendarContextProps["nextRange"] = () => {
    const date = dateAdd(selectedDate, view);
    setSelectedDate(date);
    setTimeRange(getPeriodTimeRange(date, view));
  };

  const handlePrevRange: CalendarContextProps["prevRange"] = () => {
    const date = dateSub(selectedDate, view);
    setSelectedDate(date);
    setTimeRange(getPeriodTimeRange(date, view));
  };

  const handleNextWeek: CalendarContextProps["nextWeek"] = () => {
    const date = dateAdd(selectedDate, CalendarViews.Week);
    setSelectedDate(date);
    setTimeRange(getPeriodTimeRange(date, view));
  };

  const handlePrevWeek: CalendarContextProps["prevWeek"] = () => {
    const date = dateSub(selectedDate, CalendarViews.Week);
    setSelectedDate(date);
    setTimeRange(getPeriodTimeRange(date, view));
  };

  const handleSetSelectedDate: CalendarContextProps["setSelectedDate"] = (date) => {
    setSelectedDate(date);
    setTimeRange(getPeriodTimeRange(date, view));
  };

  const handleAddNewBulkAppointment: CalendarContextProps["addNewAppointment"] = (data) => {
    addBulkDialogState.open(data);
  };

  const handleShowAppointment: CalendarContextProps["showAppointment"] = (data) => {
    detailsDialogState.open(data);
  };

  const handleCancelAppointment: CalendarContextProps["cancelAppointment"] = (data) => {
    cancelDialogState.open(data);
  };

  const handleAddSlot: CalendarContextProps["addSlot"] = (data) => {
    addSlotDialogState.open(data);
  };

  const handleAddNewBlockedSlot: CalendarContextProps["addNewBlockedSlot"] = () =>
    blockedSlotDialogState.open();

  const handleShowFilters: CalendarContextProps["showFilters"] = () => filtersDialogState.open();

  const handleAppointmentSelect: CalendarContextProps["selectAppointment"] = (slot) => {
    if (isCalendarBlockedSlot(slot)) {
      blockedSlotDialogState.open({
        defaultValues: {
          ...slot,
        },
        action: "update",
      });
    } else if (isAppointmentTreatmentSlot(slot)) {
      switch (slot.status) {
        case AppointmentStatus.CanceledByClient:
          handleCancelAppointment({ appointmentUuid: slot.uuid });
          break;
        default:
          handleShowAppointment({
            appointmentUuid: slot.uuid,
          });
          break;
      }
    }
  };

  const value = useMemo(() => {
    return {
      view,
      setView: handleSetView,
      timeRange,
      setTimeRange,
      filters,
      setFilters: handleSetFilters,
      selectedDate,
      nextRange: handleNextRange,
      prevRange: handlePrevRange,
      nextWeek: handleNextWeek,
      prevWeek: handlePrevWeek,
      setSelectedDate: handleSetSelectedDate,
      addNewAppointment: handleAddNewBulkAppointment,
      showAppointment: handleShowAppointment,
      cancelAppointment: handleCancelAppointment,
      showFilters: handleShowFilters,
      selectAppointment: handleAppointmentSelect,
      addNewBlockedSlot: handleAddNewBlockedSlot,
      goToDay: handleGoToDay,
      addSlot: handleAddSlot,
    };
  }, [
    handleSetView,
    handleSetFilters,
    handleNextRange,
    handlePrevRange,
    handleSetSelectedDate,
    handleAddNewBulkAppointment,
    handleShowAppointment,
    handleCancelAppointment,
    handleShowFilters,
    handleAppointmentSelect,
    handleAddNewBlockedSlot,
    handleAddSlot,
    view,
    timeRange,
    filters,
    selectedDate,
  ]);

  return (
    <CalendarContext.Provider value={value}>
      {children}
      <SlotAddDialog {...bindDialogState(addBulkDialogState)} />
      <AppointmentDetailsDialog {...bindDialogState(detailsDialogState)} />
      <AppointmentCancelDialog {...bindDialogState(cancelDialogState)} />
      <CalendarFiltersDialog {...bindDialogState(filtersDialogState)} />
      <BlockedSlotDialog {...bindDialogState(blockedSlotDialogState)} />
      <SlotAddDialog {...bindDialogState(addSlotDialogState)} />
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = () => useContext(CalendarContext);

import { useMemo } from "react";

import {
  CancelAppointmentMutationVariables,
  FinalizeBulkAppointmentInput,
  ScheduleBulkAppointmentInput,
  UpdateBulkAppointmentInput,
  namedOperations,
} from "@/types";

import { CalendarViews } from "../models";
import { useApproveAppointmentRequestMutation } from "../mutations/ApproveAppointmentRequest.generated";
import { useCancelAppointmentMutation } from "../mutations/CancelAppointment.generated";
import { useFinalizeBulkAppointmentMutation } from "../mutations/FinalizeBulkAppointment.generated";
import { useScheduleBulkAppointmentMutation } from "../mutations/ScheduleBulkAppointment.generated";
import { useUndoAppointmentFinalizationMutation } from "../mutations/UndoAppointmentFinalization.generated";
import { useUpdateBulkAppointmentMutation } from "../mutations/UpdateBulkAppointment.generated";
import { useCalendarContext } from "../providers/CalendarProvider";
import { useFetchAppointmentQuery } from "../queries/FetchAppointment.generated";
import { useFetchClientAppointmentQuery } from "../queries/FetchClientAppointment.generated";
import { useFetchSuggestedAppointmentTreatmentsPricesQuery } from "../queries/FetchSuggestedAppointmentTreatmentsPrices.generated";

type UseAppointmentStoreProps = {
  id?: string;
  withSuggestedPrice?: boolean;
  token?: string;
};

export const useAppointmentStore = (params?: UseAppointmentStoreProps) => {
  const { data, loading: loadingFetch } = useFetchAppointmentQuery({
    variables: { id: params?.id },
    skip: !params?.id,
  });
  const { data: suggestedPriceData, loading: loadingSuggestedPrice } =
    useFetchSuggestedAppointmentTreatmentsPricesQuery({
      variables: { id: params?.id },
      skip: !(params?.id && params?.withSuggestedPrice),
    });
  const { data: clientAppointment, loading: loadingClientAppointment } =
    useFetchClientAppointmentQuery(
      params?.token
        ? {
            variables: { token: params?.token },
          }
        : { skip: true },
    );

  const { view } = useCalendarContext();
  const isMonthView = view === CalendarViews.Month;

  const fetchCalendarQuery = useMemo(() => {
    if (isMonthView) {
      return namedOperations.Query.FetchCalendar;
    }
    return namedOperations.Query.FetchCalendarByEmployee;
  }, [view]);

  const [schedule, { loading: loadingSchedule }] = useScheduleBulkAppointmentMutation();
  const [update, { loading: loadingUpdate }] = useUpdateBulkAppointmentMutation();
  const [cancel, { loading: loadingCancel }] = useCancelAppointmentMutation();
  const [finalize, { loading: loadingFinalize }] = useFinalizeBulkAppointmentMutation();
  const [undo, { loading: loadingUndo }] = useUndoAppointmentFinalizationMutation();
  const [approve, { loading: loadingApprove }] = useApproveAppointmentRequestMutation();

  return {
    loading:
      loadingFetch ||
      loadingSchedule ||
      loadingUpdate ||
      loadingCancel ||
      loadingFinalize ||
      loadingClientAppointment ||
      loadingUndo,
    loadingFetch,
    loadingSchedule,
    loadingUpdate,
    loadingCancel,
    loadingFinalize,
    loadingSuggestedPrice,
    loadingClientAppointment,
    loadingUndo,
    loadingApprove,
    clientAppointment: clientAppointment?.fetchClientAppointment,
    appointment: data?.fetchAppointment,
    suggestedPrices: suggestedPriceData?.fetchSuggestedAppointmentTreatmentsPrices || [],
    schedule(data: ScheduleBulkAppointmentInput) {
      return schedule({
        variables: { data },
        refetchQueries: [
          namedOperations.Query.RemainingAppointments,
          namedOperations.Query.FetchSmsLimit,
          fetchCalendarQuery,
        ],
      });
    },
    update(data: UpdateBulkAppointmentInput) {
      return update({
        variables: { data },
        refetchQueries: [
          namedOperations.Query.FetchSmsLimit,
          namedOperations.Query.FetchSuggestedAppointmentTreatmentsPrices,
          fetchCalendarQuery,
        ],
      });
    },
    cancelAppointment(data: CancelAppointmentMutationVariables) {
      return cancel({
        variables: data,
        refetchQueries: [
          namedOperations.Query.FetchAppointments,
          namedOperations.Query.FetchSmsLimit,
          fetchCalendarQuery,
        ],
      });
    },
    finalize(data: FinalizeBulkAppointmentInput) {
      return finalize({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchIncomeNumbers, fetchCalendarQuery],
      });
    },
    undo() {
      return undo({
        variables: {
          appointmentUuid: params?.id,
        },
        refetchQueries: [fetchCalendarQuery],
      });
    },
    approve(data: UpdateBulkAppointmentInput) {
      return approve({
        variables: { data },
        refetchQueries: [
          namedOperations.Query.RemainingAppointments,
          namedOperations.Query.FetchSmsLimit,
          namedOperations.Query.FetchSuggestedAppointmentTreatmentsPrices,
          fetchCalendarQuery,
        ],
      });
    },
  };
};

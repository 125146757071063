import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SalonFragment = { __typename?: 'Salon', salonUuid: any, name: string, phoneType?: Types.PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: any | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBookingSlotDuration: number, selfBookingAutomaticalSlotApproval: boolean, selfBookingAutomaticalSlotApprovalSms: boolean, selfBookingMinLeadTime: Types.MinLeadTimeOptionsInMinutes, selfBookingMaxLeadTime: Types.MaxLeadTimeOptionsInDays, selfBooking: { __typename?: 'SalonSelfBooking', url: any } };

export const SalonFragmentDoc = gql`
    fragment Salon on Salon {
  salonUuid
  name
  phoneType
  phone
  mobile
  email
  city
  links
  logoImageUrl
  backgroundImageUrl
  postalCode
  street
  streetNumber
  socials
  description
  isPublic
  slug
  selfBookingSlotDuration
  selfBookingAutomaticalSlotApproval
  selfBookingAutomaticalSlotApprovalSms
  selfBookingMinLeadTime
  selfBookingMaxLeadTime
  selfBooking {
    url
  }
}
    `;
import { useTranslation } from "react-i18next";

import { Spinner } from "@/components/ui/Spinner";

import { useSalonOpeningHoursStore } from "../hooks";
import { weekdays } from "../utils";

export const SalonOpeningHours = () => {
  const { t } = useTranslation();
  const { regular, loading } = useSalonOpeningHoursStore();

  return loading ? (
    <div className="flex h-[50vh] items-center">
      <Spinner />
    </div>
  ) : (
    <div className="mt-8 flex flex-col">
      <div className="overflow-x-auto">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  {t("openingHours.weekDay")}
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {t("openingHours.open")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {weekdays.map((weekday) => (
                <tr key={weekday}>
                  <td className="whitespace-nowrap px-1 py-2 text-sm font-medium text-gray-900 sm:px-3 sm:pl-6">
                    {t(`weekDays.${weekday}`)}
                  </td>
                  <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 sm:px-3">
                    {regular[weekday].is_open
                      ? regular[weekday].ranges.map((range, index) => (
                          <span key={index}>
                            {range.from}
                            &mdash;
                            {range.to}
                            {index !== regular[weekday].ranges.length - 1 && <>&#44;&nbsp;</>}
                          </span>
                        ))
                      : t("openingHours.event.isOpen.closed")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

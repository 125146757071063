import { Layout } from "@components/layout/Layout";

import { EmployeeDetails } from "@features/employees/components/EmployeeDetails";

export const EmployeeDetailsView = () => {
  return (
    <Layout>
      <EmployeeDetails />
    </Layout>
  );
};

import { useMemo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { TextAreaField } from "@components/ui/TextAreaField";

import { useToasts } from "@providers/ToastsProvider";

import { useAccountStore } from "@features/account/hooks";
import { useSessionStore } from "@features/session/hooks";

import { Select } from "@/components/ui/Select";
import { ESTETIFY_CONTACT_EMAIL } from "@/constants";
import { FeedbackInput } from "@/types";
import { sendEvent } from "@/utils/google-analytics";

import { FEEDBACK_SUBJECT_OPTIONS } from "../constants";

export type SendFeedbackProps = {
  subject?: (typeof FEEDBACK_SUBJECT_OPTIONS)[number];
};

type SendFeedbackDialogProps = {
  open: boolean;
  onClose: () => void;
  props?: SendFeedbackProps;
};

export const SendFeedbackDialog = ({ open, onClose, props }: SendFeedbackDialogProps) => {
  const { t } = useTranslation();
  const { session } = useSessionStore();
  const {
    register,
    formState: { errors, isValid },
    control,
    reset,
    handleSubmit,
    setValue,
  } = useForm<FeedbackInput>({
    mode: "all",
  });
  const { sendFeedback, loadingSendFeedback } = useAccountStore();
  const { showToast } = useToasts();

  const subjectOptions = useMemo(
    () =>
      FEEDBACK_SUBJECT_OPTIONS.map((option) => ({
        value: option,
        label: t(`feedback.subjectOptions.${option}`),
      })),
    [FEEDBACK_SUBJECT_OPTIONS, t],
  );

  const handleSendFeedback = async (data: FeedbackInput) => {
    const message = [
      data.message,
      "----------------",
      `${t("feedback.sender")}: ${session?.fullName}`,
      `${t("generic.salon")}: ${session?.salonName}`,
      `${t("generic.email")}: ${session?.email}`,
      `${t("generic.phone")}: ${session?.phone}`,
      `Plan: ${session?.salonPlan}`,
      `${t("feedback.device")}: ${navigator.userAgent}`,
    ].join("\n");

    const { errors } = await sendFeedback({
      subject: t(`feedback.subjectOptions.${data.subject}`),
      message,
    });
    if (!errors) {
      sendEvent("send_feedback", "contact", "footer");
      showToast({
        title: t("feedback.toast.title"),
        description: t("feedback.toast.message"),
      });
      reset();
      onClose();
    }
  };

  useEffect(() => {
    if (props?.subject) {
      setValue("subject", props?.subject);
    }
  }, [props?.subject]);

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("feedback.title")}>
      <p className="text-sm text-gray-500">{t("feedback.description")}</p>
      <p className="my-4 text-sm text-gray-500">
        <span>{t("feedback.hint")}</span>:&nbsp;
        <a className="text-violet-500" href={`mailto:${ESTETIFY_CONTACT_EMAIL}`}>
          {ESTETIFY_CONTACT_EMAIL}
        </a>
      </p>
      <form className="space-y-4" onSubmit={handleSubmit(handleSendFeedback)} noValidate>
        <Controller
          control={control}
          rules={{ required: true }}
          name="subject"
          render={({ field: { onChange, value = "" } }) => (
            <Select
              name="subject"
              label={t("feedback.subject")}
              placeholder={t("feedback.chooseSubject")}
              value={value}
              onChange={onChange}
              options={subjectOptions}
              selectBy="value"
            />
          )}
        />
        <TextAreaField
          {...register("message", { required: true, minLength: 3, maxLength: 10000 })}
          label={t("feedback.content")}
          errorMessage={
            errors.message &&
            t(`validation.${errors.message.type}`, {
              name: t("feedback.content"),
              minLength: 3,
              maxLength: 10000,
            })
          }
        />
        <div className="align-center mt-4 flex flex-col">
          <Button
            variant="primary-inline"
            fullWidth
            disabled={loadingSendFeedback}
            onClick={onClose}>
            {t("generic.cancel")}
          </Button>
          <Button
            type="submit"
            fullWidth
            className="mt-4"
            disabled={!isValid || loadingSendFeedback}>
            {t("generic.send")}
          </Button>
        </div>
      </form>
    </DefaultDialog>
  );
};

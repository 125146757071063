import { useUpdateSalonSenderNameMutation } from "@/features/salon/mutations/UpdateSalonSenderName.generated";
import { useUpdateSalonSmsDetailsMutation } from "@/features/salon/mutations/UpdateSalonSMSDetails.generated";
import { useFetchSalonSmsDetailsQuery } from "@/features/salon/queries/FetchSalonSmsDetails.generated";
import { useSessionStore } from "@/features/session/hooks";
import { namedOperations, UpdateSalonSmsDetailsInput } from "@/types";

export const useSalonSmsDetailsStore = () => {
  const { isOnboarded } = useSessionStore();

  const { data, loading: fetchLoading } = useFetchSalonSmsDetailsQuery({
    skip: !isOnboarded,
  });
  const [updateSalonSmsDetails, { loading: updateSmsDetailsLoading }] =
    useUpdateSalonSmsDetailsMutation();
  const [updateSalonSenderName, { loading: updateSenderNameLoading }] =
    useUpdateSalonSenderNameMutation();

  return {
    loading: fetchLoading || updateSmsDetailsLoading || updateSenderNameLoading,
    salonSmsDetails: data?.fetchSalonSmsDetails,
    updateSalonSenderName(senderName: string) {
      return updateSalonSenderName({
        variables: {
          senderName,
        },
        refetchQueries: [namedOperations.Query.FetchSalonSmsDetails],
      });
    },
    updateSalonSmsDetails(data: UpdateSalonSmsDetailsInput) {
      return updateSalonSmsDetails({
        variables: {
          data,
        },
      });
    },
  };
};

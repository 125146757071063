import React, { forwardRef, ForwardedRef, useMemo } from "react";

import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { CalendarViews } from "@/features/calendar/models";
import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import { useEmployeesStore } from "@/features/employees/hooks/use-employees-store";

export const CalendarEmployeeHeader = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { setView, setFilters, filters, view } = useCalendarContext();
  const { employees } = useEmployeesStore();

  const handleButtonClick = () => {
    setFilters({ employeeUuid: undefined });
    setView(view === CalendarViews.EmployeeDay ? CalendarViews.Day : CalendarViews.Week);
  };

  const employeeUuid = useMemo(() => filters.employeeUuid, [filters]);
  const employee = useMemo(
    () => employees.find((employee) => employee.accountUuid === employeeUuid),
    [employeeUuid, employees],
  );

  return (
    <div
      ref={ref}
      className="absolute left-0 right-0 top-[90px] z-40 flex-none border-x border-b bg-white sm:top-0 sm:rounded-t-md sm:border sm:pr-8">
      <div
        className={clsx(
          "flex text-sm leading-6 text-gray-500 sm:-mr-px",
          `grid-cols-${employees.length}`,
        )}>
        <div className="col-end-1 w-10" />
        <div className="-ml-10 flex flex-auto items-center justify-center">
          <button
            className="my-2 mr-3 rounded-md border border-gray-300 px-2 py-1 text-sm text-gray-500 shadow-sm transition-colors hover:border-gray-400 hover:text-gray-600 sm:mr-4 sm:px-3"
            onClick={() => handleButtonClick()}>
            <ArrowUturnLeftIcon className="h-3 w-3 sm:h-4 sm:w-4" aria-hidden="true" />
          </button>
          <span className="text-xs font-semibold text-gray-900">
            {employee && employee.fullName}
          </span>
        </div>
      </div>
    </div>
  );
});

import { useEffect } from "react";

import * as Sentry from "@sentry/react";

import { useCountersStore, useSessionStore } from "@/features/session/hooks";

export const useSentry = () => {
  const { session, isEmailVerified, loading: loadingSession } = useSessionStore();
  const { smsLimit, remainingAppointments, loading: loadingCounters } = useCountersStore();

  useEffect(() => {
    if (!loadingSession) {
      Sentry.setUser({
        id: session?.accountUuid,
        email: session?.email,
      });

      Sentry.setContext("session", {
        ...session,
        isEmailVerified,
      });
    }
  }, [loadingSession, session]);

  useEffect(() => {
    if (!loadingCounters) {
      Sentry.setContext("counters", {
        smsLimit,
        remainingAppointments,
      });
    }
  }, [smsLimit, remainingAppointments, loadingCounters]);
};

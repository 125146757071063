export type Option<Props = any> = {
  value: string;
  label: string;
  props?: Props;
  groupValue?: string;
  groupLabel?: string;
};

export type GroupedOption = {
  options: Option[];
  groupValue: string;
  groupLabel: string;
};

export const getOptionByValue = (value: string | string[], options: Option[]) => {
  if (Array.isArray(value)) {
    return value.map((v) => options.find((o) => o.value === v));
  }
  return options.find((o) => o.value === value);
};

export const getGroupedOptions = (options: Option[]): GroupedOption[] => {
  return options.reduce((groups, option) => {
    if (typeof option === "string") return groups;
    const index = groups.findIndex(
      ({ groupValue }) => groupValue === getOptionProperty(option, "groupValue"),
    );
    if (~index) {
      groups[index].options.push(option);
    } else {
      option.groupValue &&
        option.groupLabel &&
        groups.push({
          groupValue: option.groupValue,
          groupLabel: option.groupLabel,
          options: [option],
        });
    }
    return groups;
  }, [] as GroupedOption[]);
};

const getOptionProperty = (option: Option, property: keyof Omit<Option, "props">) =>
  option.hasOwnProperty(property) ? option[property] : undefined;

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { Subheader } from "@/components/layout/subheader/Subheader";
import { Button } from "@/components/ui/Button";
import { StatisticsCards } from "@/features/statistics/components/StatisticsCards";
import { StatisticsChart } from "@/features/statistics/components/StatisticsChart";
import { TIME_RANGES } from "@/features/statistics/constants";
import { timeRange } from "@/features/statistics/models/index";

export const StatisticsView = () => {
  const { t } = useTranslation();

  const [period, setPeriod] = useState<timeRange>("MONTH");

  return (
    <Layout
      title={t("sidebar.navigation.appointments.children.statistics")}
      className="bg-gray-100">
      <div className="-ml-4 -mr-4 mb-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mb-8 sm:mt-4 sm:px-12 md:mx-auto">
        <Subheader title={t("sidebar.navigation.appointments.children.statistics")}></Subheader>
      </div>
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <div className="grid gap-8">
          <div className="flex w-full md:w-auto">
            {TIME_RANGES.map((option) => (
              <Button
                key={option}
                size="small"
                variant={period === option ? "primary" : "primary-outline"}
                className="mr-2"
                onClick={() => setPeriod(option)}>
                {t(`statistics.${option}`)}
              </Button>
            ))}
          </div>
          <section>
            <StatisticsCards timeRange={period} />
          </section>
          <section>
            <StatisticsChart timeRange={period} />
          </section>
        </div>
      </div>
    </Layout>
  );
};

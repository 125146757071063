import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Transition } from "@headlessui/react";

import { useAppointmentStore } from "@/features/calendar/hooks/use-appointment-store";
import { useCalendarStore } from "@/features/calendar/hooks/use-calendar-store";
import { convertCalendarItemsToSlotsFormat } from "@/features/calendar/utils/slots";
import { useEmployeesStore } from "@/features/employees/hooks";
import { useSalonOpeningHoursStore } from "@/features/salon/hooks";
import { useBreakpoint } from "@/hooks/use-breakpoint";

import { CalendarViews } from "../models";
import { useCalendarContext } from "../providers/CalendarProvider";
import { CalendarDayContent } from "./CalendarDay/CalendarDayContent";
import { CalendarEmployeeWeekContent } from "./CalendarEmployeeWeek/CalendarEmployeeWeekContent";
import { CalendarLoader } from "./CalendarLoader";
import { CalendarMonthContent } from "./CalendarMonth/CalendarMonthContent";
import { CalendarWeekContent } from "./CalendarWeek/CalendarWeekContent";

const useHandleAppointmentSelection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointmentUuid, setAppointmentUuid] = useState<string>();
  const { appointment } = useAppointmentStore({ id: appointmentUuid });
  const { selectAppointment } = useCalendarContext();

  useEffect(() => {
    const appointmentUuid = searchParams.get("appointmentUuid");

    if (appointmentUuid) {
      setAppointmentUuid(appointmentUuid);
      setSearchParams({});
    }
  }, [searchParams]);

  useEffect(() => {
    if (appointment) {
      const slot = convertCalendarItemsToSlotsFormat([appointment])[0];

      if (slot) {
        selectAppointment(slot);
      }
    }
  }, [appointment]);
};

export const CalendarContent = () => {
  const { isSm } = useBreakpoint("sm");
  const { view, timeRange, filters } = useCalendarContext();
  const { loading: openingHoursLoading } = useSalonOpeningHoursStore();
  const { loading: employeesLoading } = useEmployeesStore();
  const {
    slotsAssignedToDays,
    slotRowsByEmployee,
    slotsAssignedToDaysByEmployee,
    loading: calendarLoading,
  } = useCalendarStore({
    filters: { timeRange, ...filters },
    calendarView: view,
  });
  useHandleAppointmentSelection();

  const loading = openingHoursLoading || employeesLoading || calendarLoading;

  const renderContent = () => {
    if (loading) {
      return <CalendarLoader />;
    }

    if ([CalendarViews.Day, CalendarViews.EmployeeDay].includes(view)) {
      return <CalendarDayContent slotGroups={slotRowsByEmployee} />;
    }

    if (CalendarViews.Week === view) {
      if (isSm) {
        return slotRowsByEmployee.length > 1 ? (
          <CalendarWeekContent slotGroupsMap={slotsAssignedToDaysByEmployee ?? []} />
        ) : (
          <CalendarEmployeeWeekContent slotGroups={slotRowsByEmployee} />
        );
      }
      return <CalendarDayContent slotGroups={slotRowsByEmployee} />;
    }

    if (CalendarViews.EmployeeWeek === view) {
      return isSm ? (
        <CalendarEmployeeWeekContent slotGroups={slotRowsByEmployee} />
      ) : (
        <CalendarDayContent slotGroups={slotRowsByEmployee} />
      );
    }

    if (CalendarViews.Month === view) {
      return <CalendarMonthContent slotGroupsMap={slotsAssignedToDays} />;
    }
  };

  return (
    <div className="relative -mx-2 -mb-4 xs:-mx-4 sm:mx-0 sm:mb-0">
      <Transition
        show={true}
        appear
        as="div"
        enter="transition-opacity duration-300 delay-100 absolute"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="h-full w-full">
        {renderContent()}
      </Transition>
    </div>
  );
};

import { cloneElement } from "react";

import { Icon } from "@/models";

type HeaderAvatarProps = {
  icon: Icon;
};

export const HeaderAvatar = ({ icon }: HeaderAvatarProps) => (
  <div className="flex h-6 w-6 rounded-full bg-violet-500 sm:h-7 sm:w-7">
    {cloneElement(icon, {
      className: "p-1 text-violet-100",
    })}
  </div>
);

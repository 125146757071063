import { ReactNode, useCallback } from "react";

import { DndContext, closestCorners, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToWindowEdges,
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { CategoryListItem } from "@/features/services/components/categories/CategoryListItem";
import { Category } from "@/types";

type CategoryListWrapperProps = {
  noResultText: string;
  renderCategory: (category: Category) => ReactNode;
  onEdit?: (category: Category) => void;
  onRemove?: (category: Category) => void;
  onSelect?: (category: Category) => void;
  isDraggable?: boolean;
  categories: Category[];
  setTempCategories: (categories: Category[]) => void;
};

export const CategoryListWrapper = ({
  noResultText,
  renderCategory,
  onEdit,
  onRemove,
  onSelect,
  isDraggable = false,
  categories,
  setTempCategories,
}: CategoryListWrapperProps) => {
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const activeIndex = categories.findIndex(
          (category: Category) => category.uuid === active.id,
        );
        const overIndex = categories.findIndex((category: Category) => category.uuid === over.id);
        const newEntities = arrayMove(categories, activeIndex, overIndex);

        setTempCategories(newEntities);
      }
    },
    [categories, setTempCategories],
  );

  if (categories.length === 0) {
    return (
      <span className="flex w-full items-center justify-center py-10 text-gray-500">
        {noResultText}
      </span>
    );
  }

  return (
    <DndContext
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement]}>
      <ul className="divide-y">
        <SortableContext
          items={categories.map((category: Category) => category.uuid)}
          strategy={verticalListSortingStrategy}>
          {categories.map((category: Category) => (
            <CategoryListItem
              key={category.uuid}
              category={category as Category}
              renderCategory={renderCategory as (category: Category) => ReactNode}
              onEdit={onEdit as (category: Category) => void}
              onRemove={onRemove as (category: Category) => void}
              onSelect={onSelect as (category: Category) => void}
              isDraggable={isDraggable}
            />
          ))}
        </SortableContext>
      </ul>
    </DndContext>
  );
};

import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

import { useSessionStore } from "@features/session/hooks";

import { Button } from "@/components/ui/Button";
import { TextField } from "@/components/ui/TextField";
import { GENERIC_NAME } from "@/constants/validations";
import { ROUTES } from "@/features/router/constants/routes";
import { useSalonStore } from "@/features/salon/hooks";
import { CreateSalonInput } from "@/types";

export const OnboardingSalonProfileView = () => {
  const { t } = useTranslation();
  const { session, logout } = useSessionStore();
  const { loadingCreateOrUpdate, createSalon, updateSalon } = useSalonStore({ skipQuery: true });

  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<CreateSalonInput>({
    ...(session?.salonName && { defaultValues: { name: session.salonName } }),
    mode: "all",
  });

  const handleCreateSalon: SubmitHandler<CreateSalonInput> = async (data) => {
    session?.salonName ? await updateSalon(data) : await createSalon(data);

    navigate(ROUTES.ONBOARDING_SMS);
  };

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className="mb-2 text-4xl font-bold text-gray-900">
          {t("onboarding.salonProfile.title", { name: session?.firstName })}
        </h1>
        <p className="mb-4 text-sm font-normal text-gray-400">
          {t("onboarding.salonProfile.description")}
        </p>
        <div className="fixed right-4 top-4">
          <Button
            size="small"
            variant="primary-inline"
            startIcon={<ArrowRightOnRectangleIcon />}
            onClick={logout}>
            {t("header.mainMenu.logOut")}
          </Button>
        </div>
        <form className="space-y-4" onSubmit={handleSubmit(handleCreateSalon)} noValidate>
          <TextField
            {...register("name", GENERIC_NAME)}
            label={t("salonProfile.salonName.label")}
            placeholder={t("salonProfile.salonName.placeholder")}
            errorMessage={
              errors.name &&
              t(`validation.${errors.name.type}`, {
                name: t("salonProfile.salonName.label"),
              })
            }
          />
          <Button type="submit" fullWidth disabled={!isValid || loadingCreateOrUpdate}>
            {t("generic.continue")}
          </Button>
        </form>
      </div>
    </div>
  );
};

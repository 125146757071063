import { useTranslation } from "react-i18next";

import { ArrowPathIcon, LifebuoyIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@/features/account/components/SendFeedbackDialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";

export const ErrorFallback = () => {
  const { t } = useTranslation();
  const feedbackDialogState = useDialog<SendFeedbackProps>();

  return (
    <>
      <div role="alert" className="flex h-[80vh] items-center justify-center">
        <div className="min-h-full bg-white px-8 py-16 sm:px-12 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main>
              <div className="grid max-w-xl gap-8 px-4">
                <div>
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    {t("errorBoundary.title")}
                  </h1>
                  <p className="mt-3 whitespace-pre-line text-base text-gray-500">
                    {t("errorBoundary.description")}
                  </p>
                </div>
                <div className="flex gap-4">
                  <Button
                    variant="primary"
                    startIcon={<ArrowPathIcon />}
                    onClick={() => window.location.assign(window.location.origin)}>
                    {t("errorBoundary.refreshPage")}
                  </Button>
                  <Button
                    variant="primary-outline"
                    startIcon={<LifebuoyIcon />}
                    onClick={() => feedbackDialogState.open()}>
                    {t("footer.iNeedHelp")}
                  </Button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
    </>
  );
};

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import {
  UserCircleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  WalletIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";

import clsx from "clsx";

import { HeaderAvatar } from "@components/layout/header/HeaderAvatar";
import { Button } from "@components/ui/Button";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useToasts } from "@providers/ToastsProvider";

import { usePaymentsStore } from "@features/payments/hooks";
import { useSessionStore } from "@features/session/hooks";

export const UserDropdown = () => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint("md");
  const { showToast } = useToasts();

  const { session, logout } = useSessionStore();
  const { loading, createBillingPortalSession } = usePaymentsStore();

  const handleManagePayments = async () => {
    if (!session?.stripeCustomerId) return;

    const { data, errors } = await createBillingPortalSession(session.stripeCustomerId);

    if (errors) {
      showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });
    } else if (data?.createBillingPortalSession?.url) {
      window.location.href = data.createBillingPortalSession.url;
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex w-full items-center justify-center rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:px-4">
        <div className="flex items-center gap-2">
          <HeaderAvatar icon={<UserCircleIcon />} />
          {isMd && <span>{session?.fullName}</span>}
        </div>
        {isMd && <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-gray-300 focus:outline-none"
          as="ul">
          {session?.stripeCustomerId && (
            <li className="py-1 text-gray-500 group-hover:text-gray-600">
              <Menu.Item>
                {({ active }) => (
                  <div>
                    <Button
                      variant="neutral"
                      fullWidth
                      disabled={loading.loadingBillingPortalSession}
                      onClick={handleManagePayments}
                      className={clsx(
                        "group flex w-full items-center px-4 py-2 text-xs",
                        active && "bg-gray-100",
                      )}>
                      <WalletIcon className="mr-3 h-4 w-4" aria-hidden="true" />
                      <span>{t("header.mainMenu.payments")}</span>
                    </Button>
                  </div>
                )}
              </Menu.Item>
            </li>
          )}
          <li className="py-1 text-gray-500 group-hover:text-gray-600">
            <Menu.Item>
              {({ active }) => (
                <div>
                  <Button
                    variant="neutral"
                    fullWidth
                    to="/settings"
                    className={clsx(
                      "group flex w-full items-center px-4 py-2 text-xs",
                      active && "bg-gray-100",
                    )}>
                    <Cog8ToothIcon className="mr-3 h-4 w-4" aria-hidden="true" />
                    <span>{t("settings.title")}</span>
                  </Button>
                </div>
              )}
            </Menu.Item>
          </li>
          <li className="py-1 text-red-500 group-hover:text-gray-600">
            <Menu.Item>
              {({ active }) => (
                <div>
                  <Button
                    variant="neutral"
                    fullWidth
                    onClick={logout}
                    className={clsx(
                      "group flex w-full items-center px-4 py-2 text-xs",
                      active && "bg-gray-100",
                    )}>
                    <ArrowRightOnRectangleIcon className="mr-3 h-4 w-4" aria-hidden="true" />
                    <span>{t("header.mainMenu.logOut")}</span>
                  </Button>
                </div>
              )}
            </Menu.Item>
          </li>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

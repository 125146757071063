import { cloneElement } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { Control, Controller, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { DATE_FORMAT } from "@/constants/date-formats";
import { Icon } from "@/models";

import { DatePicker } from "./DatePicker";

type DateFieldProps = Omit<ReactDatePickerProps, "onChange"> & {
  label?: string;
  name: string;
  control: Control<any>;
  required?: boolean;
  placeholder?: string;
  showLabel?: boolean;
  className?: string;
  startIcon?: Icon;
  error?: FieldError;
};

export const DateField = ({
  label,
  name,
  control,
  required,
  placeholder,
  showLabel = true,
  className,
  startIcon,
  error,
  ...delegated
}: DateFieldProps) => {
  const { t } = useTranslation();

  return (
    <div className={clsx("relative", className)}>
      <label htmlFor={name} className={showLabel ? "block text-xs text-gray-500" : "sr-only"}>
        {label || name}
      </label>
      {startIcon && (
        <div
          className={clsx("pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3", {
            "mb-4": !!error,
          })}
          aria-hidden="true">
          {cloneElement(startIcon, {
            className: clsx("h-5 w-5 text-gray-400", startIcon.props.className, {
              "text-red-600": !!error,
            }),
          })}
        </div>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value = "" } }) => (
          <DatePicker
            selected={value}
            onChange={onChange}
            placeholderText={placeholder}
            dateFormat={delegated.dateFormat || DATE_FORMAT.DEFAULT}
            className={label ? "mt-1" : ""}
            customInput={
              <input
                className={clsx(
                  "flex h-11 w-full appearance-none items-center rounded-md border border-gray-300 px-3 text-sm placeholder-gray-400 placeholder:font-light focus:border-violet-600 focus:outline-none focus:ring-violet-600",
                  {
                    "border-red-600 focus:border-red-600 focus:outline-none focus:ring-red-600":
                      !!error,
                    "pl-10": !!startIcon,
                  },
                )}
              />
            }
            {...delegated}
          />
        )}
      />
      {error && (
        <span className="block text-xs text-red-600">
          {t(`validation.${error.type}`, { name })}
        </span>
      )}
    </div>
  );
};

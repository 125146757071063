import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BanknotesIcon,
  ClockIcon,
  InformationCircleIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";

import {
  SCALE_MINUTES_PER_STEP,
  FINALIZED_COLOR,
  ATTENTION_COLOR,
} from "@features/calendar/constants";
import type { ServiceCategoryColor } from "@features/services/models";
import clsx from "clsx";

import { PaymentMethod } from "@/components/PaymentMethod";
import { SERVICE_CATEGORY_COLORS } from "@/constants/colors";
import { useCalendarContext } from "@/features/calendar/providers/CalendarProvider";
import {
  getDivisionOffset,
  getDivisionsDistance,
  isoTimeRangeToTime,
} from "@/features/calendar/utils/time";
import {
  isAppointmentTreatmentSlot,
  isCalendarBlockedSlot,
} from "@/features/calendar/utils/type-guards";
import { formatPrice } from "@/features/payments/utils";
import { AppointmentStatus } from "@/types";

import { UnknownCalendarSlot } from "../models";

type CalendarDaysSlotProps = {
  slot: UnknownCalendarSlot;
  index: number;
  groupStartsAt: string;
  isLast: boolean;
};

export const CalendarSlot = ({ slot, index, groupStartsAt, isLast }: CalendarDaysSlotProps) => {
  const { t } = useTranslation();
  const { selectAppointment } = useCalendarContext();
  const [offset, setOffset] = useState<number>(0);
  const [span, setSpan] = useState<number>(0);
  const [color, setColor] = useState<ServiceCategoryColor>(FINALIZED_COLOR);

  const isBlockedSlot = useMemo(() => isCalendarBlockedSlot(slot), [slot]);

  const handleSlotClick = () => selectAppointment(slot);

  useEffect(() => {
    setOffset(getDivisionOffset(slot.timeRange.from, 1, SCALE_MINUTES_PER_STEP, groupStartsAt));
    setSpan(getDivisionsDistance(slot.timeRange.from, slot.timeRange.to, SCALE_MINUTES_PER_STEP));

    if (isAppointmentTreatmentSlot(slot)) {
      switch (slot.status) {
        case AppointmentStatus.Scheduled:
        case AppointmentStatus.Requested:
          setColor(
            slot.categoryColor
              ? SERVICE_CATEGORY_COLORS[slot.categoryColor] || FINALIZED_COLOR
              : FINALIZED_COLOR,
          );
          break;
        case AppointmentStatus.CanceledByClient:
          setColor(ATTENTION_COLOR);
          break;
        default:
          setColor(FINALIZED_COLOR);
          break;
      }
    }
  }, [slot]);

  const { from, to } = useMemo(() => isoTimeRangeToTime(slot.timeRange), [slot.timeRange]);
  const slotInformation = useMemo(() => slot.note || slot.description, [slot]);

  return (
    <li
      className="relative z-20 mt-px flex h-full transition-[z-index] duration-500 ease-step-start hover:!z-30 hover:ease-step-end"
      style={{
        gridColumnStart: index + 1,
        gridRow: `${offset} / span ${span}`,
        zIndex: 20 + index,
      }}>
      <div
        className={clsx(
          slotInformation ? "hover:min-h-[124px]" : "hover:min-h-[80px]",
          "group absolute inset-px flex min-h-[1rem] cursor-pointer flex-col overflow-hidden rounded-md border outline outline-1 outline-white transition-[left,_min-height] delay-500",
          {
            pattern: isBlockedSlot,
            "right-[calc(-100%_+_1px)]": !isLast,
            "hover:!left-[calc(-100%_+_1px)]": isLast && index !== 0,
          },
          color.border,
          color.secondary,
        )}
        onClick={handleSlotClick}>
        <div
          className={clsx(
            "flex min-w-full items-center justify-between whitespace-nowrap px-2 py-0.5 text-[8px] font-bold uppercase leading-4",
            color.primary,
            color.primaryText,
          )}>
          <div className="shrink-1 mr-1 flex items-center gap-3">
            <div className="flex items-center">
              <ClockIcon className="mr-0.5 h-3 w-3 shrink-0" />
              <time dateTime={slot.timeRange.from}>{from}</time>
              &ndash;
              <time dateTime={slot.timeRange.to}>{to}</time>
            </div>
            {slot.suggestedPrice && (
              <div className="flex flex-1 items-center">
                {slot.status === AppointmentStatus.Completed && slot.paymentMethodUuid ? (
                  <PaymentMethod
                    paymentMethodUuid={slot.paymentMethodUuid}
                    showLabel={false}
                    className="mr-0.5 h-3 w-3"
                  />
                ) : (
                  <BanknotesIcon className="mr-0.5 h-3 w-3 shrink-0" />
                )}
                <span>
                  {formatPrice({
                    price:
                      slot.status === AppointmentStatus.Completed
                        ? slot.finalPrice || slot.suggestedPrice
                        : slot.suggestedPrice,
                    currency: "PLN",
                  })}
                </span>
                {slot.isBulk && slot.bulkPrice && (
                  <span className="ml-0.5 flex-1 truncate">
                    ({formatPrice({ price: slot.bulkPrice, currency: "PLN" })})
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center gap-1">
            {slotInformation && <InformationCircleIcon className="h-3 w-3 shrink-0" />}
            {(slot.isBulk || slot.isSelfBooked) && (
              <div className="flex items-center gap-1">
                {slot.isSelfBooked && <CursorArrowRaysIcon className="h-3 w-3 shrink-0" />}
                {slot.isBulk && <Square3Stack3DIcon className="h-3 w-3 shrink-0" />}
              </div>
            )}
          </div>
        </div>
        <div
          className={clsx(
            "flex h-full flex-col content-between justify-between px-2 py-1",
            color.secondaryText || color.primaryText,
          )}>
          <div className="flex flex-col">
            {slot.clientDisplayName && (
              <span className="whitespace-nowrap text-[8px] font-bold uppercase leading-4">
                {slot.clientDisplayName}
              </span>
            )}
            {slot.treatmentName && (
              <p className="line-clamp-2 text-xs font-normal">{slot.treatmentName}</p>
            )}
            {isBlockedSlot && (
              <span className="line-clamp-2 text-xs font-normal">{slot.title}</span>
            )}
          </div>
          {slotInformation && (
            <div
              className={clsx(
                "-mx-1 mt-2 flex items-center justify-self-end rounded-md px-1 py-1",
                color.tertiary,
              )}>
              <InformationCircleIcon className="mr-1 mt-0.5 h-3 w-3 shrink-0 self-start" />
              <p className="line-clamp-2 text-[10px] font-normal">{slotInformation}</p>
            </div>
          )}
        </div>
        {slot.status === AppointmentStatus.Requested && (
          <div className="absolute inset-0 h-full bg-gray-300/90 p-1">
            <ClockIcon className="mx-auto h-6 w-6 shrink-0 text-black" />
            <span className="whitespace-nowrap text-center text-xs font-bold text-black">
              {t("appointments.requestedAppointmentCaption")}
            </span>
          </div>
        )}
      </div>
    </li>
  );
};

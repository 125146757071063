import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { EmployeeEdit } from "@features/employees/components/EmployeeEdit";

export const EmployeeEditView = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("employees.edit")} className="bg-gray-100">
      <div className="-ml-6 -mr-6 mt-4 max-w-6xl rounded-xl bg-gray-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto">
        <EmployeeEdit />
      </div>
    </Layout>
  );
};

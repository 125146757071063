import { forwardRef, ForwardedRef, LabelHTMLAttributes } from "react";

import clsx from "clsx";

type LabelProps = {
  screenreaderOnly?: boolean;
};

export const Label = forwardRef(
  (
    { screenreaderOnly, ...delegated }: LabelProps & LabelHTMLAttributes<HTMLLabelElement>,
    ref: ForwardedRef<HTMLLabelElement>,
  ) => {
    return (
      <label
        ref={ref}
        className={clsx("block pb-1 text-xs text-gray-500", { "sr-only": screenreaderOnly })}
        {...delegated}
      />
    );
  },
);

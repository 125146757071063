import { ReactNode } from "react";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Button } from "@components/ui/Button";

import { useSessionStore } from "@/features/session/hooks";
import { Category } from "@/types";

type CategoryListItemProps = {
  category: Category;
  selectedUuid?: string;
  renderCategory: (category: Category) => ReactNode;
  onEdit?: (category: Category) => void;
  onRemove?: (category: Category) => void;
  onSelect?: (category: Category) => void;
  isDraggable?: boolean;
};

export const CategoryListItem = ({
  category,
  renderCategory,
  onEdit,
  onRemove,
  onSelect,
  isDraggable,
}: CategoryListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: category.uuid,
    disabled: !isDraggable,
  });
  const { permissions } = useSessionStore();

  return (
    <li
      key={category.uuid}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
      }}
      {...attributes}
      {...listeners}
      className={
        "group grid grid-cols-[1fr_2.5rem_2.5rem] gap-1 bg-transparent bg-white text-gray-500 transition-colors hover:bg-gray-100"
      }>
      <Button
        variant="neutral"
        className="w-full truncate p-2 text-left"
        onClick={() => onSelect && !isDraggable && onSelect(category)}>
        {renderCategory(category)}
      </Button>
      <>
        {onRemove && !isDraggable && permissions.manage_treatments && (
          <Button
            variant="danger-inline"
            className="h-full w-10 transition-opacity hover:bg-gray-300 group-hover:opacity-100 lg:opacity-0"
            disabled={!permissions.manage_treatments}
            onClick={() => onRemove(category)}>
            <TrashIcon className="h-5 w-5" />
          </Button>
        )}
        {onEdit && !isDraggable && permissions.manage_treatments && (
          <Button
            variant="primary-inline"
            className="h-full w-10 transition-opacity hover:bg-gray-300 group-hover:opacity-100 lg:opacity-0"
            disabled={!permissions.manage_treatments}
            onClick={() => onEdit(category)}>
            <PencilIcon className="h-5 w-5" />
          </Button>
        )}
      </>
    </li>
  );
};
